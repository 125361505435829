.test-view-page-wrapper {
  display: flex;
  flex-direction: column;
  gap: 24px;
  height: calc(100% - 10px);
  td:not(:first-child) {
    padding: 16px;
  }
  .test-view-header-container {
    .header-first-section {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    .header-second-section {
      display: flex;
      justify-content: space-between;
    }
  }
}
