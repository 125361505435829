.job-tabs-container {
  margin-top: 21px;
  display: flex;

  .item {
    margin: -10px 0px 0px 4px;
    height: 35px;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: -0.01em;
    color: var(--theme-color-dcdfe5);
    background-color: var(--theme-color-1c222a);
  }

  .border {
    border-bottom: var(--theme-color-4367fd) solid 3px;
  }

  .tests {
    margin-left: 16px;
  }
}

.trace-chart-container {
  .detailed-trace-summary {
    .timeline-header__column-name {
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
      text-align: left;
      padding: 10px 0 10px 20px;
      color: var(--theme-color-ebebeb);
    }
  }

  .timeline-header {
    height: 40px !important;
  }

  .timeline-header__draggable-splitter {
    height: 40px;
    display: contents;
    font-size: 20px !important;
  }

  .timeline-header__resizable-column {
    height: 40px;
  }

  .timeline-header__time-markers {
    span {
      margin: 12px;
      font-size: 12px;
      font-weight: 400;
      line-height: 16px;
      text-align: left;
      color: var(--theme-color-ebebeb);
    }
  }

  .timeline {
    border: 1px solid var(--theme-color-2f353d) !important;
  }

  .timeline-header {
    border: 1px solid var(--theme-color-2f353d) !important;
  }

  .timeline-span__service-name-column-wrapper {
    border-right: none;
  }

  .timeline-span__span-name-column-wrapper {
    border-right: none;
  }

  .timeline-span__time-marker {
    border-right: none;
  }
}
