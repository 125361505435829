.repository-workflow-page-container {
  .workflow-runs {
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: calc(100vh - 290px);

    .left-part {
      width: 280px;
    }

    .right-part {
      overflow-y: auto;
      width: calc(100% - 280px);
    }
  }
  .repository-title-container {
    display: flex;
    align-items: center;
    min-height: 32px;

    div {
      color: var(--theme-color-dcdfe5);
      font-style: normal;
      font-weight: 500;
      font-size: 20px;
      line-height: 24px;
      letter-spacing: -0.04em;

      display: flex;
      align-items: center;
      height: 100%;
    }

    .title {
      margin-right: 24px;
    }
  }
}

.workflowrun-list-header {
  .list-cards-header-items {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    align-items: center;
    letter-spacing: -0.01em;
    color: #788496;
  }
}

@media (max-width: 480px) {
  .repository-workflow-page-container {
    .workflow-runs {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      width: 100%;
      height: calc(100% - 50px);

      .left-part {
        width: 100%;
      }

      .right-part {
        overflow-y: auto;
        width: 100%;

        .workflow-runs-list-container {
          width: 100%;
          margin-top: 24px;
          padding-right: 12px;
        }
      }
    }
  }
}
