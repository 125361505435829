.info-panel {
  border: 1px solid;
  border-radius: 5px;
  padding: 15px;
  display: flex;
  flex-direction: column;
  background-color: var(--theme-color-1c222a);
  margin-bottom: 24px;
  width: 100%;

  .info-panel-title-container {
    display: flex;
    align-items: center;
    gap: 10px;

    i.warning {
      font-size: 20px;
      color: #ff9214;
    }

    i.info:before {
      font-size: 20px;
      color: #5174ff;
    }

    .info-panel-title {
      font-family: 'Inter';
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 20px;
      color: #dcdfe5;
    }
  }

  .info-panel-text {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    margin-left: 30px;
    color: #788496;
    .break-line {
      margin-top: 8px;
    }
  }
}
