.test-view-summary-container {
  display: flex;
  gap: 24px;

  .meta-summary-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    padding: 24px;
    gap: 4px;
    min-width: 424px;
    width: 33.3%;
    height: 148px;
    background: #1c222a;
    border-radius: 6px;

    .title {
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      line-height: 16px;
      letter-spacing: -0.01em;
      color: #788496;
      height: 24px;
    }

    .test-suite-name {
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 20px;
      display: flex;
      align-items: center;
      color: #dcdfe5;
    }

    .info-container {
      display: flex;
      align-items: center;
      height: 24px;
      gap: 8px;
      width: 100%;

      .test-information-cont {
        display: flex;
        gap: 4px;
        width: 33.3%;
        position: relative;

        .test-suites-info-title {
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
          max-width: 100px;
          color: var(--theme-color-5c697c);
          font-style: normal;
          font-weight: 600;
          font-size: 12px;
          line-height: 15px;
          letter-spacing: -0.005em;
        }
        .icon-workflows {
          font-size: 16px;
        }
        .icon-icons-action-job {
          font-size: 16px;
        }
        .icon-bookmark {
          font-size: 16px;
        }
      }
    }
  }

  .execution-summary-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    padding: 24px;
    gap: 4px;
    width: 33.3%;
    min-width: 424px;
    height: 148px;
    background: #1c222a;
    border-radius: 6px;

    .title {
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      line-height: 16px;
      letter-spacing: -0.01em;
      color: #788496;
      height: 24px;
    }

    .info-container {
      display: flex;
      align-items: center;
      gap: 16px;
      width: 100%;

      .info-part {
        &:last-child {
          border-right: 1px solid transparent;
        }
        width: 33.3%;
        height: 36px;
        border-right: 1px solid rgba(92, 105, 124, 0.4);

        .data-part {
          font-style: normal;
          font-weight: 600;
          font-size: 16px;
          line-height: 20px;
          color: #dcdfe5;
        }

        .title-part {
          font-style: normal;
          font-weight: 600;
          font-size: 12px;
          line-height: 16px;
          letter-spacing: -0.01em;
          color: #5c697c;
        }
      }
    }
  }

  .success_fail_summary-container {
    display: flex;
    flex-direction: row;
    padding: 24px;
    min-width: 424px;
    width: 33.3%;
    height: 148px;
    background: #1c222a;
    border-radius: 6px;
    justify-content: space-between;
    gap: 24px;

    .test-view-status-column {
      display: flex;
      flex-direction: column;
      padding: 0px;
      gap: 8px;
      text-align: right;
      width: 50%;

      .title {
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        line-height: 16px;
        letter-spacing: -0.01em;
        color: #788496;
      }

      .info {
        display: flex;
        gap: 12px;
        justify-content: flex-end;
        .list-sub-info {
          display: flex;
          flex-direction: column;
          gap: 4px;
          .label-head {
            font-family: 'Inter';
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 20px;
            letter-spacing: -0.004em;
            color: #5c697c;
            & > span {
              font-family: 'Inter';
              font-style: normal;
              font-weight: 700;
              font-size: 14px;
              line-height: 20px;
              letter-spacing: -0.01em;
              color: #b2baca;
            }
            .ranged-value-main-container-long {
              text-align: initial;
              margin-top: 23px;
              .ranged-value-line {
                background-color: #2f353d;
              }
            }
          }
        }
        .icon-container {
          .icon-icons-summary-duration {
            font-size: 48px;
            &::before {
              color: #74bbe1;
            }
          }
        }
      }
    }
  }
}
