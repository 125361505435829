:root {
  --theme-color-rgba_0_0_0_1: rgba(0, 0, 0, 1);
  --theme-color-rgba_7_10_12_024: rgba(7, 10, 12, 0.24);
  --theme-color-rgba_7_10_12_04: rgba(7, 10, 12, 0.4);
  --theme-color-rgba_10_13_16_32: rgba(10, 13, 16, 0.32);
  --theme-color-rgba_10_13_16_24: rgba(10, 13, 16, 0.24);
  --theme-color-rgba_10_13_16_16: rgba(10, 13, 16, 0.16);
  --theme-color-rgba_10_13_16_48: rgba(10, 13, 16, 0.48);
  --theme-color-rgba_10_13_16_40: rgba(10, 13, 16, 0.4);
  --theme-color-rgba_14_18_22_0: rgba(14, 18, 22, 0);
  --theme-color-rgba_30_144_255_02: rgba(30, 144, 255, 0.2);
  --theme-color-rgba_34_36_38_0: rgba(34, 36, 38, 0);
  --theme-color-rgba_46_160_67_02: rgba(46, 160, 67, 0.2);
  --theme-color-rgba_77_108_164_037: rgba(77, 108, 164, 0.37);
  --theme-color-rgba_110_118_129_016: rgba(110, 118, 129, 0.16);
  --theme-color-rgba_137_147_164_1: rgba(137, 147, 164, 1);
  --theme-color-rgba_135_146_163_024: rgba(135, 146, 163, 0.24);
  --theme-color-rgba_135_146_163_032: rgba(135, 146, 163, 0.32);
  --theme-color-rgba_220_223_229_02: rgba(220, 223, 229, 0.2);
  --theme-color-rgba_220_223_229_008: rgba(220, 223, 229, 0.08);
  --theme-color-rgba_220_223_229_024: rgba(220, 223, 229, 0.24);
  --theme-color-rgba_220_223_229_032: rgba(220, 223, 229, 0.32);
  --theme-color-rgba_220_223_229_04: rgba(248, 81, 73, 0.2);
  --theme-color-rgba_248_81_73_02: rgba(220, 223, 229, 0.4);

  --theme-color-rgba_255_240_179_01: rgba(255, 240, 179, 0.1);

  --theme-color-rgba_0_0_0_02: rgba(0, 0, 0, 0.2);
  --theme-color-rgba_0_0_0_04: rgba(0, 0, 0, 0.4);

  --theme-color-rgba_255_255_255_004: rgba(255, 255, 255, 0.04);
  --theme-color-rgba_255_255_255_05: rgba(255, 255, 255, 0.5);
  --theme-color-rgba_255_255_255_07: rgba(255, 255, 255, 0.7);
  --theme-color-rgba_255_255_255_08: rgba(255, 255, 255, 0.8);
  --theme-color-rgba_255_255_255_012: rgba(255, 255, 255, 0.12);

  --theme-color-rgba_212_212_212_04: rgba(212, 212, 212, 0.4);
  --theme-color-rgba_212_212_212_076: rgba(212, 212, 212, 0.76);
  --theme-color-rgba_217_217_217_025: rgba(217, 217, 217, 0.25);

  --theme-color-rgba_120_132_150_02: rgba(120, 132, 150, 0.2);
  --theme-color-rgba_120_132_150_024: rgba(120, 132, 150, 0.24);
  --theme-color-rgba_120_132_150_032: rgba(120, 132, 150, 0.32);
  --theme-color-rgba_120_132_150_064: rgba(120, 132, 150, 0.64);

  --theme-color-rgba_10_13_16_016: rgba(10, 13, 16, 0.16);
  --theme-color-rgba_10_13_16_024: rgba(10, 13, 16, 0.24);
  --theme-color-rgba_10_13_16_032: rgba(10, 13, 16, 0.32);

  --theme-color-rgba_137_147_164_24: rgba(137, 147, 164, 0.24);
  --theme-color-rgba_137_147_164_32: rgba(137, 147, 164, 0.32);

  --theme_color_lighten_151a20_085: #161b22;
  --theme_color_lighten_232323_085: #242424;
  --theme_color_lighten_838383_40: #b4b4b4;
  --theme_color_darken_f8f8f9_10: #dddde2;
  --theme_color_darken_5a2adc_10: #4e21ca;

  --theme-color-0052cc: #0052cc;
  --theme-color-00764f: #00764f;
  --theme-color-070a0c: #070a0c;
  --theme-color-09152f: #09152f;
  --theme-color-091e42: #091e42;
  --theme-color-a9b2cb: #a9b2cb;
  --theme-color-acacac: #acacac;
  --theme-color-0e1216: #0e1216;
  --theme-color-0e1217: #0e1217;

  --theme-color-151a20: #151a20;
  --theme-color-151a21: #151a21;
  --theme-color-161b22: #161b22;
  --theme-color-172b4d: #172b4d;
  --theme-color-181b1f: #181b1f;
  --theme-color-1b1b1b: #1b1b1b;
  --theme-color-1b232b: #1b232b;
  --theme-color-1c222a: #1c222a;
  --theme-color-1d43dd: #1d43dd;
  --theme-color-1d76e1: #1d76e1;
  --theme-color-1e1e20: #1e1e20;
  --theme-color-1e90ff: #1e90ff;
  --theme-color-1f2f59: #1f2f59;

  --theme-color-202328: #202328;
  --theme-color-202b36: #202b36;
  --theme-color-21262d: #21262d;
  --theme-color-222b35: #222b35;
  --theme-color-232323: #232323;
  --theme-color-238636: #238636;
  --theme-color-254be2: #254be2;
  --theme-color-262b31: #262b31;
  --theme-color-284ce4: #284ce4;
  --theme-color-2a3b50: #2a3b50;
  --theme-color-2a6fe7: #2a6fe7;
  --theme-color-2a84f1: #2a84f1;
  --theme-color-2abf81: #2abf81;
  --theme-color-2c2a2e: #2c2a2e;
  --theme-color-2d2d2d: #2d2d2d;
  --theme-color-2d4967: #2d4967;
  --theme-color-2dcd8a: #2dcd8a;
  --theme-color-2f353d: #2f353d;
  --theme-color-292f38: #292f38;

  --theme-color-30363d: #30363d;
  --theme-color-31363c: #31363c;
  --theme-color-314f73: #314f73;
  --theme-color-3385ff: #3385ff;
  --theme-color-3459ef: #3459ef;
  --theme-color-35383b: #35383b;
  --theme-color-363636: #363636;
  --theme-color-363b41: #363b41;
  --theme-color-39495d: #39495d;
  --theme-color-3e4d61: #3e4d61;
  --theme-color-3794ff: #3794ff;

  --theme-color-404040: #404040;
  --theme-color-4367fd: #4367fd;
  --theme-color-4666e6: #4666e6;
  --theme-color-48576a: #48576a;
  --theme-color-495fe4: #495fe4;
  --theme-color-4a474d: #4a474d;

  --theme-color-5174ff: #5174ff;
  --theme-color-5a2adc: #5a2adc;
  --theme-color-555555: #555555;
  --theme-color-5599ff: #5599ff;
  --theme-color-57d9a3: #57d9a3;
  --theme-color-5a2adc: #5a2adc;
  --theme-color-5c697c: #5c697c;
  --theme-color-5f5f5f: #5f5f5f;

  --theme-color-67b73e: #67b73e;
  --theme-color-6a7689: #6a7689;
  --theme-color-6fdfb1: #6fdfb1;

  --theme-color-71a7ff: #71a7ff;
  --theme-color-737373: #737373;
  --theme-color-73baff: #73baff;
  --theme-color-76d671: #76d671;
  --theme-color-7883a2: #7883a2;
  --theme-color-788496: #788496;

  --theme-color-827c88: #827c88;
  --theme-color-838383: #838383;
  --theme-color-85c167: #85c167;
  --theme-color-868ea2: #868ea2;
  --theme-color-8792a3: #8792a3;
  --theme-color-888888: #888888;
  --theme-color-8993a4: #8993a4;
  --theme-color-8993a4cc: #8993a4cc;
  --theme-color-8a8a8a: #8a8a8a;

  --theme-color-909090: #909090;
  --theme-color-96a0b0: #96a0b0;
  --theme-color-9f3a38: #9f3a38;

  --theme-color-a6a6a6: #a6a6a6;

  --theme-color-b2b3b4: #b2b3b4;
  --theme-color-b2baca: #b2baca;

  --theme-color-caccd1: #caccd1;
  --theme-color-c9d1d9: #c9d1d9;

  --theme-color-d07002: #d07002;
  --theme-color-d0d0d0: #d0d0d0;
  --theme-color-d4d4d4: #d4d4d4;
  --theme-color-d9d9d9: #d9d9d9;
  --theme-color-dcdfe5: #dcdfe5;

  --theme-color-e4e4e4: #e4e4e4;
  --theme-color-e63c3c: #e63c3c;
  --theme-color-e6e6e6: #e6e6e6;
  --theme-color-e7e1f1: #e7e1f1;
  --theme-color-e98715: #e98715;
  --theme-color-eae6ff: #eae6ff;
  --theme-color-ebebeb: #ebebeb;
  --theme-color-ecedf0: #ecedf0;
  --theme-color-ecf4fa: #ecf4fa;
  --theme-color-ebecf0: #ebecf0;

  --theme-color-f1f6fc: #f1f6fc;
  --theme-color-f26c6c: #f26c6c;
  --theme-color-f4f5c7: #f4f5f7;
  --theme-color-f5f6f8: #f5f6f8;
  --theme-color-f7f7f7: #f7f7f7;
  --theme-color-f8f8f8: #f8f8f8;
  --theme-color-f8f8f9: #f8f8f9;
  --theme-color-f85149: #f85149;
  --theme-color-fb4932: #fb4932;
  --theme-color-fbe6e1: #fbe6e1;
  --theme-color-ff5630: #ff5630;
  --theme-color-ff8b00: #ff8b00;
  --theme-color-fff6f6: #fff6f6;
  --theme-color-ffffff: #ffffff;
  --theme-color-f4f5f7: #f4f5f7;
  --theme-color-163dd4: #163dd4;
}

[data-theme='light'] {
  --theme-color-rgba_0_0_0_1: rgba(255, 255, 255, 1);
  --theme-color-rgba_7_10_12_024: rgba(248, 245, 243, 0.24);
  --theme-color-rgba_7_10_12_04: rgba(248, 245, 243, 0.4);
  --theme-color-rgba_14_18_22_0: rgba(241, 237, 233, 0);
  --theme-color-rgba_30_144_255_02: rgba(225, 111, 0, 02);
  --theme-color-rgba_34_36_38_0: rgba(231, 219, 217, 0);
  --theme-color-rgba_77_108_164_037: rgba(178, 147, 91, 0.37);
  --theme-color-rgba_110_118_129_016: rgba(145, 137, 126, 0.16);
  --theme-color-rgba_137_147_164_1: rgba(118, 108, 91 1);
  --theme-color-rgba_135_146_163_024: rgba(120, 130, 92, 0.24);
  --theme-color-rgba_135_146_163_032: rgba(120, 130, 92, 0.32);
  --theme-color-rgba_220_223_229_008: rgba(35, 32, 26, 0.08);
  --theme-color-rgba_220_223_229_02: rgba(35, 32, 26, 0.2);
  --theme-color-rgba_220_223_229_032: rgba(35, 32, 26, 0.32);
  --theme-color-rgba_220_223_229_04: rgba(35, 32, 26, 0.4);
  --theme-color-rgba_255_240_179_01: rgba(0, 15, 76, 0.1);

  --theme-color-rgba_0_0_0_02: rgba(255, 255, 255, 0.2);
  --theme-color-rgba_0_0_0_04: rgba(255, 255, 255, 0.4);

  --theme-color-rgba_255_255_255_004: rgba(0, 0, 0, 0.04);
  --theme-color-rgba_255_255_255_05: rgba(0, 0, 0, 0.5);
  --theme-color-rgba_255_255_255_07: rgba(0, 0, 0, 0.7);
  --theme-color-rgba_255_255_255_08: rgba(0, 0, 0, 0.8);
  --theme-color-rgba_255_255_255_012: rgba(0, 0, 0, 0.12);

  --theme-color-rgba_212_212_212_04: rgba(43, 43, 43, 0.4);
  --theme-color-rgba_212_212_212_076: rgba(43, 43, 43, 0.76);
  --theme-color-rgba_217_217_217_025: rgba(38, 38, 38, 0.25);

  --theme-color-rgba_120_132_150_024: rgba(135, 123, 105, 0.24);
  --theme-color-rgba_120_132_150_032: rgba(135, 123, 105, 0.32);
  --theme-color-rgba_120_132_150_064: rgba(135, 123, 105, 0.64);

  --theme_color_lighten_151a20_085: #e9e4dd;
  --theme_color_lighten_232323_085: #dbdbdb;
  --theme_color_lighten_838383_40: #4b4b4b;
  --theme_color_darken_f8f8f9_10: #22221d;
  --theme_color_darken_5a2adc_10: #b1de35;

  --theme-color-0052cc: #ffad33;
  --theme-color-00764f: #ff89b0;
  --theme-color-070a0c: #f8f5f3;
  --theme-color-09152f: #f6ead0;
  --theme-color-091e42: #f6e1bd;
  --theme-color-a9b2cb: #564d34;
  --theme-color-acacac: #535353;
  --theme-color-0e1216: #f1ede9;
  --theme-color-0e1217: #f1ede8;

  --theme-color-151a20: #eae5df;
  --theme-color-151a21: #eae5de;
  --theme-color-161b22: #e9e4dd;
  --theme-color-172b4d: #e8d4b2;
  --theme-color-181b1f: #e7e4e0;
  --theme-color-1b1b1b: #e4e4e4;
  --theme-color-1b232b: #e4dcd4;
  --theme-color-1c222a: #e3ddd5;
  --theme-color-1d43dd: #e2bc22;
  --theme-color-1d76e1: #e2891e;
  --theme-color-1e1e20: #e1e1df;
  --theme-color-1e90ff: #e16f00;
  --theme-color-1f2f59: #e0d0a6;

  --theme-color-202328: #dfdcd7;
  --theme-color-202b36: #dfd4c9;
  --theme-color-21262d: #ded9d2;
  --theme-color-222b35: #ddd4ca;
  --theme-color-232323: #dcdcdc;
  --theme-color-238636: #dc79c9;
  --theme-color-254be2: #dab41d;
  --theme-color-262b31: #d9d4ce;
  --theme-color-284ce4: #d7b31b;
  --theme-color-2a3b50: #d5c4af;
  --theme-color-2a6fe7: #d59018;
  --theme-color-2a84f1: #d57b0e;
  --theme-color-2abf81: #d5407e;
  --theme-color-2c2a2e: #d3d5d1;
  --theme-color-2d2d2d: #d2d2d2;
  --theme-color-2d4967: #d2b698;
  --theme-color-2dcd8a: #d23275;
  --theme-color-2f353d: #d0cac2;

  --theme-color-30363d: #cfc9c2;
  --theme-color-31363c: #cec9c3;
  --theme-color-314f73: #ceb08c;
  --theme-color-3385ff: #cc7a00;
  --theme-color-3459ef: #cba610;
  --theme-color-35383b: #cac7c4;
  --theme-color-363636: #c9c9c9;
  --theme-color-363b41: #c9c4be;
  --theme-color-39495d: #c6b6a2;
  --theme-color-3e4d61: #c1b29e;
  --theme-color-3794ff: #c86b00;

  --theme-color-404040: #bfbfbf;
  --theme-color-4367fd: #bc9802;
  --theme-color-4666e6: #b99919;
  --theme-color-48576a: #b7a895;
  --theme-color-495fe4: #b6a01b;
  --theme-color-4a474d: #b5b8b2;

  --theme-color-5174ff: #ae8b00;
  --theme-color-5a2adc: #a5d523;
  --theme-color-555555: #aaaaaa;
  --theme-color-5599ff: #aa6600;
  --theme-color-57d9a3: #a8265c;
  --theme-color-5a2adc: #a5d523;
  --theme-color-5c697c: #a39683;
  --theme-color-5f5f5f: #a0a0a0;

  --theme-color-67b73e: #9848c1;
  --theme-color-6a7689: #958976;
  --theme-color-6fdfb1: #90204e;

  --theme-color-71a7ff: #8e5800;
  --theme-color-737373: #8c8c8c;
  --theme-color-73baff: #8c4500;
  --theme-color-76d671: #89298e;
  --theme-color-7883a2: #877c5d;
  --theme-color-788496: #877b69;

  --theme-color-827c88: #7d8377;
  --theme-color-838383: #7c7c7c;
  --theme-color-85c167: #7a3e98;
  --theme-color-868ea2: #79715d;
  --theme-color-8792a3: #786d5c;
  --theme-color-888888: #777777;
  --theme-color-8993a4: #766c5b;
  --theme-color-8993a4cc: #6c5b33;
  --theme-color-8a8a8a: #757575;

  --theme-color-909090: #6f6f6f;
  --theme-color-96a0b0: #695f4f;
  --theme-color-9f3a38: #60c5c7;

  --theme-color-a6a6a6: #595959;

  --theme-color-b2b3b4: #4d4c4b;
  --theme-color-b2baca: #4d4535;

  --theme-color-caccd1: #35332e;
  --theme-color-c9d1d9: #362e26;

  --theme-color-d07002: #2f8ffd;
  --theme-color-d0d0d0: #2f2f2f;
  --theme-color-d4d4d4: #2b2b2b;
  --theme-color-d9d9d9: #262626;
  --theme-color-dcdfe5: #23201a;

  --theme-color-e4e4e4: #1b1b1b;
  --theme-color-e63c3c: #19c3c3;
  --theme-color-e6e6e6: #191919;
  --theme-color-e7e1f1: #181e0e;
  --theme-color-e98715: #1678ea;
  --theme-color-eae6ff: #151900;
  --theme-color-ebebeb: #141414;
  --theme-color-ecedf0: #13120f;
  --theme-color-ecf4fa: #130b05;
  --theme-color-ebecf0: #14130f;

  --theme-color-f1f6fc: #0e0903;
  --theme-color-f26c6c: #0d9393;
  --theme-color-f4f5c7: #0b0a38;
  --theme-color-f5f6f8: #0a0907;
  --theme-color-f7f7f7: #080808;
  --theme-color-f8f8f8: #070707;
  --theme-color-f8f8f9: #070706;
  --theme-color-f85149: #07aeb6;
  --theme-color-fbe6e1: #04191e;
  --theme-color-ff5630: #00a9cf;
  --theme-color-ff8b00: #0074ff;
  --theme-color-fff6f6: #000909;
  --theme-color-ffffff: #000000;
  --theme-color-f4f5f7: #f4f5f7;
}
