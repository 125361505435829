.list-cards-main-container-parent {
  width: 100%;
  &:has(.list-cards-body-loading) {
    height: 100%;
  }
  th {
    position: sticky;
    top: 0;
    background: #0e1216;
    z-index: 30;
  }

  .list-cards-header-column-wrapper {
    display: flex;
  }
  .list-cards-header-items {
    display: flex;
    gap: 5px;
    &:has(.sortable-icon-container) {
      &:hover {
        cursor: pointer;
        filter: brightness(1.2);
      }
    }
    .text {
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 20px;
      align-items: center;
      letter-spacing: -0.01em;
      color: #788496;
      user-select: none;
    }
    .sortable-icon-container {
      display: flex;
      flex-direction: column;
      gap: 3px;
      .icon-up {
        font-size: 5px;
      }
      .icon-up.selected {
        font-size: 5px;
        filter: brightness(1.8);
      }
      .icon-down {
        font-size: 5px;
      }
      .icon-down.selected {
        font-size: 5px;
        filter: brightness(1.8);
      }
    }
  }
}

.list-cards-main-container-parent.with-border {
  width: 100%;
  &:has(.list-cards-body-loading) {
    height: 100%;
  }
  border-collapse: separate !important;
  border-spacing: 0;
  th {
    position: sticky;
    top: 0;
    background: #0e1216;
    z-index: 30;
    height: 40px;
    border-bottom: 2px solid #1b232b;
  }
  .without-data-container {
    width: 100%;
    height: 100%;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .list-cards-body {
    .fly-loading-container {
      display: flex;
      flex-direction: column;
      width: 100%;
      height: 50px;
      justify-content: center;
      align-items: center;
      margin: 50px 0;
    }
  }
  .list-cards-body-row {
    &:hover {
      .with-click-icon {
        position: absolute;
        opacity: 1;
        font-size: 24px;
        top: 0;
        bottom: 0;
        margin: auto 0;
        right: 5px;
      }
    }
    .with-click-icon {
      position: absolute;
      opacity: 0;
      font-size: 24px;
      top: 0;
      height: 24px;
      bottom: 0;
      margin: auto 0;
      right: 10px;
      transition: 0.3s all ease-in;
    }
  }

  .list-cards-header-column-wrapper {
    display: flex;
  }

  .list-cards-header-column-wrapper:first-child {
    padding-left: 16px;
  }
}
