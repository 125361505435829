.test-run-suite-header-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  min-height: 24px;
  width: 100%;
  border-bottom: 1px solid var(--theme-color-222b35);
  margin-bottom: 16px;
  padding-bottom: 15px;

  .title {
    /* Header */

    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    color: var(--theme-color-ffffff);

    display: flex;
    align-items: center;

    i {
      margin-right: 10px;
      font-size: 20px;
    }

    .successful {
      color: var(--theme-color-2dcd8a);
    }

    .running {
      color: var(--theme-color-1d76e1);
    }

    .failed {
      color: var(--theme-color-ff5630);
    }

    .aborted {
      color: var(--theme-color-788496);
    }

    .commit-msg {
      font-style: normal;
      font-weight: 600;
      font-size: 20px;
      line-height: 24px;
      color: var(--theme-color-ffffff);
      max-width: 700px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }

  .start-time {
    font-weight: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 16px;
    /* identical to box height, or 89% */

    display: flex;
    align-items: center;
    text-align: right;

    /* Gray/G100 */
    color: var(--theme-color-788496);
  }
}

.test-run-header-popup {
  background-color: var(--theme-color-151a20) !important;
  padding: 14px 16px !important;

  &::before {
    visibility: hidden;
  }
  .content {
    color: var(--theme-color-c9d1d9);
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
  }
}

@media (max-width: 480px) {
  .test-run-suite-header-container {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    .title {
      width: 360px;
      .commit-msg {
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 24px;
        color: var(--theme-color-ffffff);
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }
    .start-time {
      font-size: 12px;
    }
  }
}
