.workflow-latest-20-summary-widget {
  display: flex;
  flex-direction: column;

  .header {
    display: flex;
    padding: 5px 0px 12px 0px;

    .title {
      font-family: 'Inter';
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 20px;
      letter-spacing: -0.01em;
      color: #dcdfe5;
    }
  }

  .workflow-summary-cards-container {
    display: flex;
    flex-wrap: wrap;

    .summary-card {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: 12px;

      position: static;
      width: 160px;
      max-width: 160px;
      height: 100px;
      left: 0px;
      top: 0px;

      /* Dark/D70 */

      background: #1b232b;
      border-radius: 8px;

      /* Inside auto layout */

      flex: none;
      order: 0;
      flex-grow: 1;
      margin: 2px;

      .workflow-status {
        display: flex;

        .status-title {
          font-family: 'Inter';
          font-style: normal;
          font-weight: 700;
          font-size: 11px;
          line-height: 16px;
          /* identical to box height, or 145% */

          letter-spacing: -0.01em;

          /* Gray/G80 */

          color: var(--theme-color-96a0b0);

          /* Inside auto layout */

          flex: none;
          order: 1;
          flex-grow: 1;
          margin: 0px 4px;
        }
      }

      .workflow-run-time {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 500;
        font-size: 11px;
        line-height: 16px;
        /* identical to box height, or 145% */

        letter-spacing: -0.01em;

        /* Gray/G100 */

        color: #788496;

        /* Inside auto layout */

        flex: none;
        order: 0;
        align-self: stretch;
        flex-grow: 0;
        margin: 0px 0px;
      }

      .workflow-name {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 16px;
        letter-spacing: -0.005em;
        color: var(--theme-color-96a0b0);
        flex: none;
        order: 0;
        align-self: stretch;
        flex-grow: 0;
        margin: 0px 0px;

        overflow: hidden;
        white-space: normal;
        text-overflow: ellipsis;
        word-break: break-word;
        max-height: 32px;
        width: 136px;

        &:hover {
          text-decoration: underline;
        }
      }

      &:hover {
        cursor: pointer;

        .workflow-status {
          .status-title {
            color: #dcdfe5;
          }
        }

        .workflow-run-time {
          color: var(--theme-color-96a0b0);
        }

        .workflow-name {
          color: #dcdfe5;
          text-decoration: underline;
        }
      }

      &.successful {
        background: linear-gradient(119.97deg, #01884d 0%, #0c6444 66.53%);
        border-radius: 8px;

        .workflow-name,
        .status-title {
          color: #dcdfe5;
        }

        .workflow-run-time {
          color: #c9d1d9;
        }

        &:hover {
          cursor: pointer;

          .workflow-status {
            .status-title {
              color: #fafbfc;
            }
          }

          .workflow-run-time {
            color: #dcdfe5;
          }

          .workflow-name {
            color: #fafbfc;
            text-decoration: underline;
          }
        }
      }

      &.errored {
        background: linear-gradient(119.97deg, #900d0a 0%, #cf130f 66.53%);
        border-radius: 8px;

        .workflow-name,
        .status-title {
          color: #dcdfe5;
        }

        .workflow-run-time {
          color: #c9d1d9;
        }

        &:hover {
          cursor: pointer;

          .workflow-status {
            .status-title {
              color: #fafbfc;
            }
          }

          .workflow-run-time {
            color: #dcdfe5;
          }

          .workflow-name {
            color: #fafbfc;
            text-decoration: underline;
          }
        }
      }
      &.disabled {
        opacity: 0.7;
        user-select: none;
        cursor: not-allowed;
        cursor: -moz-not-allowed;
        cursor: -webkit-not-allowed;
        &:hover {
          .workflow-name {
            color: #fafbfc;
            text-decoration: none;
          }
        }
      }
    }
  }
}

@media (max-width: 480px) {
  .workflow-latest-20-summary-widget {
    display: flex;
    flex-direction: column;
    width: 100%;

    .workflow-summary-cards-container {
      display: flex;
      flex-wrap: wrap;
      row-gap: 3px;
      justify-content: space-between;

      .summary-card {
        width: 168px;
        max-width: 169px;
      }
    }
  }
}
