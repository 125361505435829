.overview-comp-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 14px 0px 25px 0px;

  svg {
    width: 24px;
    min-width: 24px;
    height: 24px;
  }

  span {
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    letter-spacing: -0.04em;
    color: var(--theme-color-dcdfe5);
  }

  .button-wrapper {
    min-width: 150px;
    text-align: right;
    margin-left: auto;

    .go-to-workflow-run {
      margin-right: 12px;

      .styled-link {
        margin-left: 10px;
        text-decoration: none;
        color: var(--theme-color-b2baca);
      }
    }

    .buttons-style {
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      letter-spacing: -0.01em;
    }
  }
}
