.page-sub-header {
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 24px 0px;
  width: 100%;

  .page-sub-header-title-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .page-sub-header-title-action-container {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  .page-sub-header-title {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    color: var(--theme-color-dcdfe5);
    padding: 8px 0px;
    letter-spacing: -0.01em;
  }
  .page-sub-header-text {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    width: 100%;
    max-width: 800px;
    color: var(--theme-color-dcdfe5);
  }
}
