.most-failed-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 188px;
  max-height: 150px;
  border: 1px solid #2f353d;
  border-radius: 3px;
  padding: 5px;
  .most-failed-text {
    font-family: 'Inter';
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    display: flex;
    align-items: center;
    letter-spacing: -0.005em;
    color: #c9d1d9;
  }
}
