.duration-and-count-chart-container {
  .duration-count-chart-title {
    display: flex;
    font-size: 16px;
    color: var(--theme-color-4a474d);
    font-weight: normal;
    font-family: 'Inter';
    text-align: left;
    margin-bottom: 25px;

    .duration-count-chart-reset {
      line-height: 12px;
      margin-left: 10px;
      border: 1px solid var(--theme-color-a9b2cb);
      border-radius: 3px;
      color: var(--theme-color-7883a);
      font-family: 'Inter';
      font-size: 10px;
      font-weight: 500;

      &:hover {
        color: white;
        background-color: red;
        border: 1px solid var(--theme-color-1d43dd);
      }
    }
  }

  .recharts-wrapper {
    cursor: pointer !important;
    color: var(--theme-color-555555);
    font-family: 'Inter';
    font-size: 9px;
    font-weight: 500;
    line-height: 11px;
    text-align: center;
    margin-left: -19px;

    .recharts-label,
    .recharts-text {
      line-height: 35px;
      font-size: 10px;
      color: var(--theme-color-555555);
      font-family: 'Inter';
      font-weight: normal;
    }

    .test-history-chart-legend {
      width: 100%;
      height: 30px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      .legend-duration-part {
        height: 100%;
        width: 100%;
        display: flex;
        justify-content: center;
        flex-direction: row;
        padding-left: 50px;

        .execTime {
          height: 100%;
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;

          .title {
            margin: 0px 5px;
            font-style: normal;
            font-weight: 600;
            font-size: 11px;
            line-height: 16px;
            color: var(--theme-color-788496);
          }
        }
      }
    }
  }
}

.test-history-chart-tooltip {
  height: 28px;
  width: 160px;
  border: 1px solid var(--theme-color-caccd1);
  border-radius: 3px;
  background-color: var(--theme-color-ffffff);
  box-shadow: 0 2px 4px -2px var(--theme-color-rgba_77_108_164_037);

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;

  font-size: 11px;
  line-height: 16px;
  color: var(--theme-color-788496);
  background-color: var(--theme-color-0e1216);

  div {
    padding: 0px 3px;
  }
}

.no-history-data-msg-container,
.history-loading-msg-container,
.error-history-msg-container {
  width: 100%;
  height: 100%;
  margin-top: 50px;
}
