.foresight-change-impact-list {
  height: calc(100% - 165px);
  overflow-y: auto;
  padding: 0px 0px 0px 16px;
}

.foresight-change-impact-list-row {
  display: flex;
  flex-direction: column;
  height: 76px;
  min-height: 48px;
  gap: 6px;
  align-items: flex-start;
  justify-content: center;

  .foresight-change-impact-list-row-title {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: #dcdfe5;
  }

  .foresight-change-impact-list-row-date-container {
    display: flex;

    .foresight-change-impact-list-row-date {
      font-weight: 600;
      font-size: 12px;
      line-height: 15px;
      letter-spacing: -0.005em;
      color: #b2baca;

      i {
        font-size: 12px;
      }
    }
  }
}

.foresight-change-impact-list-title {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  display: flex;
  margin: 45px 0 0 0;
  align-items: center;
  letter-spacing: -0.01em;
  color: #788496;
  border-bottom: 2px solid #303335;
  height: 40px;
  padding: 0px 0px 0px 16px;
}

.table-row {
  cursor: pointer;
  .table-cell-right-icon-container {
    width: 100%;
    display: flex;
    justify-content: center;

    i {
      font-size: 15px;
      font-weight: 700;
      color: #788496;
    }
  }
}
