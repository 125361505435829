.test-tab-container {
  display: flex;
  flex-direction: column;

  .test-tab-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 124px;
    margin-top: 20px;
    background-color: var(--theme-color-f5f5f5);
    border-bottom: 1px solid var(--theme-color-e5e5e5);

    &.extended {
      height: 200px;
    }
  }

  .test-tab-scroll {
    overflow-y: auto;
  }
}

@media (max-width: 480px) {
  .test-tab-container {
    .test-tab-header {
      display: flex;
      flex-direction: column;
      width: calc(100% - 15px);
      height: auto;
      margin-top: 20px;

      &.extended {
        height: 200px;
      }
    }
  }
}
