.App {
  height: 100%;
  overflow: hidden;
}

$inputFocusBorderColor: #2a84f1;
$background: rgba(255, 255, 255, 0.04);
$emSize: 8px;
$small: 11px;
$lineHeight: 1em;
$pageBackground: #0e1216;
$menuBackground: #232323;
$borderRadius: 3px;
$itemBackground: none;
$smallSize: calc(11 / $emSize);
$largeSize: calc(13 / $emSize);
$relativeSmall: unit(calc(round($smallSize * $emSize) / $emSize));
$relativeLarge: unit(calc(round($largeSize * $emSize) / $emSize));
$itemVerticalPadding: $relativeSmall;
$itemHorizontalPadding: $relativeLarge;
$itemTextTransform: none;
$textColor: #d9d9d9;
$itemTextColor: $textColor;
$normal: normal;
$itemFontWeight: $normal;
$defaultDuration: 0.1s;
$defaultEasing: ease;
$itemTransition: background $defaultDuration $defaultEasing, box-shadow $defaultDuration $defaultEasing,
  color $defaultDuration $defaultEasing;
$relative5px: 15px;
$iconOpacity: 0.9;
$iconFloat: none;
$iconMargin: 0em $relative5px 0em 0em;
$blue: var(--theme-color-1d43dd);
$white: var(--theme-color-ffffff);
$invertedTextColor: $white;
$primaryTextShadow: $invertedTextColor;
$primaryColor: $blue;
$primaryTextColor: $invertedTextColor;
$coloredBackgroundImage: none;
$verticalAlign: baseline;
$fontName: 'Inter';
$pageFont: $fontName;
$fontFamily: $pageFont;
$horizontalMargin: 0em;
$medium: 12px;
$verticalMargin: $medium;
$textTransform: none;
$textShadow: none;
$fontWeight: $normal;
$lineHeight: 16px;
$boxShadow: none;
$checkboxSize: 14px;
$checkboxLineHeight: $checkboxSize;
$labelDistance: 28px;
$labelFontSize: 12px;
$checkboxBackground: rgba(45, 45, 45, 0.2);
$checkboxBorderRadius: 3px;
$checkboxBorder: 2px solid #2f353d;
$checkboxCheckFontSize: 10px;
$checkboxCheckTop: 0px;
$checkboxCheckLeft: 0px;
$checkboxCheckSize: $checkboxSize;
$checkboxColor: #fff;
$checkboxTransition: none;
$labelColor: #d9d9d9;
$labelTransition: color $defaultDuration $defaultEasing;
$checkboxHoverBackground: #202328;
$checkboxHoverBorderColor: #2f353d;
$hoveredTextColor: #fff;
$labelHoverColor: $hoveredTextColor;
$offWhite: #f9fafb;
$checkboxPressedBackground: $offWhite;
$selectedBorderColor: rgba(34, 36, 38, 0.35);
$checkboxPressedBorderColor: $selectedBorderColor;
$selectedTextColor: #fff;
$checkboxPressedColor: #fff;
$labelPressedColor: #fff;
$checkboxFocusBackground: #0e1216;
$checkboxFocusBorderColor: 2px solid #2f353d;
$checkboxFocusCheckColor: #fff;
$labelFocusColor: $selectedTextColor;
$checkboxActiveBackground: #232323;
$checkboxActiveBorderColor: #163dd4;
$checkboxActiveCheckOpacity: 1;
$checkboxActiveCheckColor: #fff;
$checkboxIndeterminateBackground: #163dd4;
$checkboxIndeterminateBorderColor: $checkboxActiveBorderColor;
$checkboxIndeterminateCheckOpacity: 1;
$checkboxIndeterminateCheckColor: $checkboxActiveCheckColor;
$checkboxActiveFocusBackground: #fff;
$checkboxActiveFocusBorderColor: 2px #163dd4;
$checkboxActiveFocusCheckColor: 2px #fff;
$disabledCheckboxOpacity: 0.5;
$disabledCheckboxLabelColor: rgba(0, 0, 0, 1);
$inputColor: $textColor;
$textAlign: left;
$inputFont: $pageFont;
$padding: 4px 12px;
$borderWidth: 1px;
$borderColor: rgba(34, 36, 38, 0.15);
$border: $borderWidth solid $borderColor;
$defaultBorderRadius: 3px;
$borderRadius: $defaultBorderRadius;
$disabledOpacity: 0.3;
$downBorderColor: rgba(0, 0, 0, 0.3);
$downBackground: #fafafa;
$downColor: $textColor;
$downBoxShadow: none;
$loaderOffset: 2px;
$loaderMargin: $loaderOffset 0em 0em $loaderOffset;
$loaderSize: 3em;
$circularRadius: 500rem;
$loaderLineWidth: 0.2em;
$loaderFillColor: rgba(0, 0, 0, 0.1);
$focusedFormBorderColor: #163dd4;
$focusBorderColor: $focusedFormBorderColor;
$focusBackground: #232323;
$focusColor: #a6a6a6;
$focusBoxShadow: none;
$transition: default;
$inputPlaceholderColor: #737373;
$placeholderColor: $inputPlaceholderColor;
$inputPlaceholderFocusColor: $inputPlaceholderColor;
$placeholderFocusColor: $inputPlaceholderFocusColor;

div,
p,
span {
  font-size: $small;
  line-height: $lineHeight;
}

body #root {
  font-family: $fontFamily;
}

/*******************************
         Button Overrides START
*******************************/

.ui.button {
  cursor: pointer;
  display: inline-block;
  min-height: 1em;
  outline: none;
  border: none;
  vertical-align: baseline;
  background: #e0e1e2 none;
  color: #d9d9d9;
  font-family: 'Inter', 'Helvetica Neue', Arial, Helvetica, sans-serif;
  margin: 0 0.25em 0 0;
  padding: 0.66666667em 1em;
  text-transform: none;
  border-radius: $borderRadius;
  text-shadow: none;
  font-weight: 700;
  line-height: 1.33333333em;
  font-style: normal;
  text-align: center;
  text-decoration: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  transition: opacity 0.1s ease, background-color 0.1s ease, color 0.1s ease, box-shadow 0.1s ease, background 0.1s ease;
  will-change: '';
  -webkit-tap-highlight-color: transparent;
}

.ui.button.medium-button {
  // foresight-medium
  padding: 4px 12px;
}
.ui.button.thin-button {
  // foresight-thin
  padding: 0 12px;
}

.ui.basic.vertical.buttons {
  & > button {
    color: #97a0af !important;
  }

  & > button:hover {
    color: #97a0af !important;
  }
}

.ui.primary.buttons .button,
.ui.primary.button {
  background-color: $primaryColor;
  color: $primaryTextColor;
  text-shadow: $primaryTextShadow;
  background-image: $coloredBackgroundImage;
}

.ui.buttons .button:first-child {
  border-left: none;
  margin-left: 0;
  border-top-left-radius: 0.375rem;
  border-bottom-left-radius: 0.375rem;
}

/*******************************
         Button Overrides END
*******************************/

/*******************************
         Input Overrides START
*******************************/
/*--------------------
        Inputs
---------------------*/

.ui.input {
  position: relative;
  font-weight: $normal;
  font-style: normal;
  display: inline-flex;
  color: $inputColor;
}
.ui.input > input {
  margin: 0em;
  max-width: 100%;
  flex: 1 0 auto;
  outline: none;
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
  text-align: $textAlign;
  line-height: $lineHeight;

  font-family: $inputFont;
  padding: $padding;

  background: $background;
  border: $border;
  color: $inputColor;
  border-radius: $borderRadius;
  transition: $transition;

  box-shadow: $boxShadow;
}

/*--------------------
      Placeholder
---------------------*/

/* browsers require these rules separate */

.ui.input > input::-webkit-input-placeholder {
  color: $placeholderColor;
}
.ui.input > input::-moz-placeholder {
  color: $placeholderColor;
}
.ui.input > input:-ms-input-placeholder {
  color: $placeholderColor;
}

/*******************************
            States
*******************************/

/*--------------------
        Disabled
---------------------*/

.ui.disabled.input,
.ui.input:not(.disabled) input[disabled] {
  opacity: $disabledOpacity;
}

.ui.disabled.input > input,
.ui.input:not(.disabled) input[disabled] {
  pointer-events: none;
}

/*--------------------
        Active
---------------------*/

.ui.input > input:active,
.ui.input.down input {
  border-color: $downBorderColor;
  background: $downBackground;
  color: $downColor;
  box-shadow: $downBoxShadow;
}

/*--------------------
        Focus
---------------------*/

.ui.input.focus > input,
.ui.input > input:focus {
  border-color: $focusBorderColor;
  background: $focusBackground;
  color: $focusColor;
  box-shadow: $focusBoxShadow;
}
.ui.input.focus > input::-webkit-input-placeholder,
.ui.input > input:focus::-webkit-input-placeholder {
  color: $placeholderFocusColor;
}
.ui.input.focus > input::-moz-placeholder,
.ui.input > input:focus::-moz-placeholder {
  color: $placeholderFocusColor;
}
.ui.input.focus > input:-ms-input-placeholder,
.ui.input > input:focus:-ms-input-placeholder {
  color: $placeholderFocusColor;
}

/*******************************
           Variations
*******************************/

/*--------------------
       Inverted
---------------------*/

/* Standard */
.ui.inverted.input > input {
  border: none;
}

/*--------------------
        Fluid
---------------------*/

.ui.fluid.input {
  display: flex;
}
.ui.fluid.input > input {
  width: 0px !important;
}

/*******************************
         Input Overrides END
*******************************/

/*******************************
         Form Overrides START
*******************************/

.ui.inverted.form input:focus {
  border-color: $inputFocusBorderColor;
}

/*******************************
         Form Overrides END
*******************************/

/*******************************
         Menu Overrides START
*******************************/

.ui.menu {
  font-family: Inter;
}

.ui.menu .item > i.icon {
  opacity: $iconOpacity;
  float: $iconFloat;
  margin: $iconMargin;
}

.ui.menu .item {
  position: relative;
  vertical-align: middle;
  line-height: 1;
  text-decoration: none;
  -webkit-tap-highlight-color: transparent;
  flex: 0 0 auto;
  user-select: none;

  background: $itemBackground;
  padding: $itemVerticalPadding $itemHorizontalPadding;
  text-transform: $itemTextTransform;
  color: $itemTextColor;
  font-weight: $itemFontWeight;
  transition: $itemTransition;
}

.ui.menu.topbar-menu {
  background: $background;
  margin: 0;
  height: 56px;

  .item {
    cursor: pointer;
  }
}

.ui.menu > .item:first-child {
  border-radius: $borderRadius 0px 0px $borderRadius;
}

/*******************************
         Site Overrides START
*******************************/
div,
p,
span {
  font-size: $small;
  line-height: $lineHeight;
}
/*******************************
         Site Overrides END
*******************************/

/*******************************
         Accordion Overrides START
*******************************/
.accordion.ui.source-accordion {
  .title {
    color: #d9d9d9;
  }
}

.ui.vertical.accordion.menu {
  background-color: $pageBackground;
  margin-top: 11px;

  .item {
    padding: 0 0 12px 0;
    width: 240px;
  }

  .title {
    color: #6a7689;
    font-weight: 700;

    &:hover {
      color: #96a0b0;
    }

    & > i {
      float: right;
    }
  }

  .content {
    padding-top: 6px !important;
  }
}
/*******************************
         Accordion Overrides END
*******************************/

/*******************************
         Checkbox Overrides START
*******************************/
.ui.checkbox input:focus:checked ~ .box:before,
.ui.checkbox input:checked ~ .box:before,
.ui.checkbox input:checked ~ label:before,
.ui.checkbox input:focus:checked ~ label:before {
  background-color: #163dd4 !important;
  color: #fff;
}

.ui.checkbox input:focus ~ label {
  color: #dcdfe5;
}

.ui.checkbox {
  position: relative;
  display: inline-block;
  backface-visibility: hidden;
  outline: none;
  vertical-align: baseline;
  font-style: normal;

  min-height: $checkboxSize;
  font-size: $medium;
  line-height: $checkboxLineHeight;
  min-width: $checkboxSize;
}

/* HTML Checkbox */
.ui.checkbox input[type='checkbox'],
.ui.checkbox input[type='radio'] {
  cursor: pointer;
  position: absolute;
  top: 0px;
  left: 0px;
  opacity: 0 !important;
  outline: none;
  z-index: 3;
  width: $checkboxSize;
  height: $checkboxSize;

  &:focus {
    color: #163dd4;
    background-color: #fff;
  }
}

.ui.radio.checkbox input:focus:checked ~ .box:after,
.ui.radio.checkbox input:focus:checked ~ label:after {
  background-color: #fff;
}

.ui.radio.checkbox .box:after,
.ui.radio.checkbox label:after {
  background-color: transparent;
}

.ui.checkbox input:indeterminate ~ .box:before,
.ui.checkbox input:indeterminate ~ label:before {
  background-color: #0e1216;
  border: 2px solid #2f353d;
}

.ui.checkbox input:checked:focus ~ .box:before,
.ui.checkbox input:checked:focus ~ label:before,
.ui.checkbox input:indeterminate:focus ~ .box:before,
.ui.checkbox input:indeterminate:focus ~ label:before {
  background: #0e1216;
  border-color: rgba(34, 36, 38, 0.5);
}

/*--------------
      Box
---------------*/

.ui.checkbox .box,
.ui.checkbox label {
  cursor: auto;
  position: relative;
  display: block;
  padding-left: $labelDistance;
  outline: none;
  font-size: $labelFontSize;
}

.ui.checkbox .box:before,
.ui.checkbox label:before {
  position: absolute;
  top: 0px;
  left: 0px;

  width: $checkboxSize;
  height: $checkboxSize;
  content: '';

  background: $checkboxBackground;
  background-color: #0e1216;
  border-radius: $checkboxBorderRadius;

  transition: $checkboxTransition;
  border: $checkboxBorder;
}

/*--------------
    Checkmark
---------------*/

.ui.checkbox .box:after,
.ui.checkbox label:after {
  position: absolute;
  font-size: $checkboxCheckFontSize;
  top: -1px;
  left: $checkboxCheckLeft;
  width: $checkboxCheckSize;
  height: $checkboxCheckSize;
  text-align: center;

  opacity: 0;
  color: $checkboxColor;
  transition: $checkboxTransition;
}

.ui.checkbox input:focus ~ label {
  color: #dcdfe5;
}

/* Inside */
.ui.checkbox label,
.ui.checkbox + label {
  color: $labelColor;
  transition: $labelTransition;
}

/* Outside */
.ui.checkbox + label {
  vertical-align: middle;
}

.ui.radio.checkbox input:checked ~ .box:focus,
.ui.radio.checkbox input:checked ~ .box:after,
.ui.radio.checkbox input:checked ~ label:after {
  background-color: #fff;
}

/*******************************
           States
*******************************/

/*--------------
      Hover
---------------*/

.ui.checkbox .box:hover::before,
.ui.checkbox label:hover::before {
  background: $checkboxHoverBackground;
  border-color: $checkboxHoverBorderColor;
}
.ui.checkbox label:hover,
.ui.checkbox + label:hover {
  color: $labelHoverColor;
}

/*--------------
      Down
---------------*/

.ui.checkbox .box:active::before,
.ui.checkbox label:active::before {
  background: $checkboxPressedBackground;
  border-color: $checkboxPressedBorderColor;
}
.ui.checkbox .box:active::after,
.ui.checkbox label:active::after {
  color: $checkboxPressedColor;
}
.ui.checkbox input:active ~ label {
  color: $labelPressedColor;
}

/*--------------
     Focus
---------------*/

.ui.checkbox input:focus ~ .box:before,
.ui.checkbox input:focus ~ label:before {
  background: $checkboxFocusBackground;
  border: $checkboxFocusBorderColor;
}
.ui.checkbox input:focus ~ .box:after,
.ui.checkbox input:focus ~ label:after {
  color: $checkboxFocusCheckColor;
}

// .ui.checkbox input:focus,
// .ui.checkbox input:focus ~ label {
// color: $labelFocusColor;
// }

/*--------------
     Active
---------------*/

.ui.checkbox input:checked ~ .box:before,
.ui.checkbox input:checked ~ label:before {
  background: $checkboxActiveBackground;
  border-color: $checkboxActiveBorderColor;
}
.ui.checkbox input:checked ~ .box:after,
.ui.checkbox input:checked ~ label:after {
  opacity: $checkboxActiveCheckOpacity !important;
  color: $checkboxActiveCheckColor !important;
}

/*--------------
  Indeterminate
---------------*/

.ui.checkbox input:not([type='radio']):indeterminate ~ .box:before,
.ui.checkbox input:not([type='radio']):indeterminate ~ label:before {
  background: $checkboxIndeterminateBackground;
  border-color: $checkboxIndeterminateBorderColor;
}
.ui.checkbox input:not([type='radio']):indeterminate ~ .box:after,
.ui.checkbox input:not([type='radio']):indeterminate ~ label:after {
  opacity: $checkboxIndeterminateCheckOpacity;
  color: $checkboxIndeterminateCheckColor;
  top: 0px;
}

/*--------------
  Active Focus
---------------*/

.ui.checkbox input:not([type='radio']):indeterminate:focus ~ .box:before,
.ui.checkbox input:not([type='radio']):indeterminate:focus ~ label:before,
.ui.checkbox input:checked:focus ~ .box:before,
.ui.checkbox input:checked:focus ~ label:before {
  background: $checkboxActiveFocusBackground;
  border-color: $checkboxActiveFocusBorderColor;
}
.ui.checkbox input:not([type='radio']):indeterminate:focus ~ .box:after,
.ui.checkbox input:not([type='radio']):indeterminate:focus ~ label:after,
.ui.checkbox input:checked:focus ~ .box:after,
.ui.checkbox input:checked:focus ~ label:after {
  color: $checkboxActiveFocusCheckColor;
}

/*--------------
    Read-Only
---------------*/

.ui.read-only.checkbox,
.ui.read-only.checkbox label {
  cursor: default;
}

/*--------------
     Disabled
---------------*/

.ui.disabled.checkbox .box:after,
.ui.disabled.checkbox label,
.ui.checkbox input[disabled] ~ .box:after,
.ui.checkbox input[disabled] ~ label {
  cursor: default !important;
  opacity: $disabledCheckboxOpacity;
  color: $disabledCheckboxLabelColor;
}

/*--------------
     Hidden
---------------*/

/* Initialized checkbox moves input below element
 to prevent manually triggering */
.ui.checkbox input.hidden {
  z-index: -1;
}

/* Selectable Label */
.ui.checkbox input.hidden + label {
  cursor: pointer;
  user-select: none;
}

/*******************************
         Checkbox Overrides END
*******************************/

/*******************************
         Dimmer Overrides START
*******************************/
.ui.dimmer {
  background: rgba(106, 118, 137, 0.6);
}
/*******************************
         Dimmer Overrides END
*******************************/

/*******************************
         Dropdown Overrides START
*******************************/

.ui.active.selection.dropdown {
  border-bottom-left-radius: 3px !important;
  border-bottom-right-radius: 3px !important;
}

.ui.selection.dropdown .menu > .item {
  border-top: none;
}

.ui.dropdown.selected,
.ui.dropdown .menu .selected.item {
  color: #d9d9d9;
}

.ui.search.dropdown > input.search {
  color: #d9d9d9;
  font-size: 11px;
  height: 32px;
}

.ui.search.dropdown > i.dropdown.icon {
  top: 1.5rem;
}

.ui.menu .ui.dropdown .menu {
  font-size: unset;
  background-color: $menuBackground;

  & > .item {
    padding: 10px !important;
  }
}

/*******************************
         Dropdown Overrides END
*******************************/

/*******************************
         Menu Overrides START
*******************************/
.ui.page.modals {
  justify-content: center;
  align-items: center;

  &.dimmer.transition {
    z-index: 2000;
  }
}

.ui.modal {
  color: #d9d9d9;
  background-color: #0e1216;
  box-shadow: 0px 4px 8px -2px rgba(135, 146, 163, 0.24), 0px 0px 1px rgba(135, 146, 163, 0.32);
  border-radius: 3px;
  padding: 24px;
  top: unset;
  left: unset;
  margin: auto;

  .ui.positive.button {
    background-color: #1d76e1;
    color: #ffffff;
  }

  .ui.negative.button {
    background-color: transparent;
    color: #d9d9d9;
  }

  &.mini {
    width: 400px;
  }
}

/*******************************
         Menu Overrides END
*******************************/

/*******************************
         Popup Overrides START
*******************************/

.ui.popup {
  border: none;
  color: #b2baca;
}

.ui.basic.popup {
  background-color: #1b232b;

  span {
    color: #c9d1d9;
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;
    font-family: Inter;
  }
}

/*******************************
         Popup Overrides END
*******************************/

/*******************************
        Card Overrides START
*******************************/

.ui.cards > .card,
.ui.card {
  box-shadow: unset;
}

/*******************************
         Card Overrides END
*******************************/

/*******************************
         Table Overrides START
*******************************/

.ui.table {
  color: #d4d4d4;
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;

  td {
    padding: 1.125em;
    text-align: inherit;
  }

  thead th {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: #ebebeb;
  }
}

/*******************************
         Table Overrides END
*******************************/

i.mini.icon,
i.mini.icons {
  line-height: 1;
  font-size: 0.4em;
}

.ui.grid > .row > .column {
  position: relative;
  display: inline-block;
  width: 6.25%;
  padding-left: 8px;
  padding-right: 8px;
  vertical-align: top;
}
