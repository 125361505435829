.foresight-status-card-main-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: auto;
  height: 128px;
  background: #1b232b;
  border-radius: 6px;
  align-items: center;
  padding: 28px 32px;

  .foresight-status-card-item-container {
    display: flex;
    flex: 1 1 auto;
    .foresight-status-card-item {
      display: flex;
      align-items: center;
    }
  }

  .vertical-line {
    height: 80px;
    width: 1px;
    background-color: rgba(120, 132, 150, 0.24);
    margin: 0 36px;
  }
}
