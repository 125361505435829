.area-line-chart-legend-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  min-height: 10px;
  flex-wrap: wrap;
  margin: 0 0 20px 30px;

  .legend {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 3px;
    &:hover {
      cursor: pointer;
    }

    .legend-icon {
      height: 12px;
      width: 12px;
      border-radius: 1px;
    }
    .legend-title {
      margin: 0 5px;
      color: var(--theme-color-788496);
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      line-height: 16px;
    }
  }
}
