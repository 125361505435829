.cross-popup-icon-container {
  padding: 20px 32px;

  i {
    font-size: 24px;

    &:hover {
      border-radius: 50%;
      background-color: var(--theme-color-rgba_220_223_229_008);
      cursor: pointer;
    }
  }
}
