.toggle-tab-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px 16px;
  gap: 12px;

  height: 40px;
  background: #1b232b;
  border-radius: 3px;
  flex: none;
  order: 1;
  flex-grow: 0;

  .toggle-tab-title {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    display: flex;
    align-items: center;
    letter-spacing: -0.004em;
    color: #ebebeb;
  }

  .toggle-tab {
    .toggle-tab-button {
      background: #2f353d;
      font-family: 'Inter';
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      line-height: 16px;
      letter-spacing: -0.01em;
      color: #b2baca;
      display: flex;
      align-items: center;
      height: 24px;
      padding: 0px auto;

      &.active {
        background: #163dd4;
        color: #ffffff;
      }
    }
  }
  .ui.buttons .button:first-child {
    border-top-left-radius: 32px;
    border-bottom-left-radius: 32px;
  }

  .ui.buttons .button:last-child {
    border-top-right-radius: 32px;
    border-bottom-right-radius: 32px;
  }
}
