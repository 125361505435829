.foresight-dropdown-container {
  .ui.fluid.selection.dropdown.foresight-dropdown.dropdown-filter {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 8px;
    gap: 12px;
    height: 40px;
    background: #151a20;
    border: 2px solid #2f353d;
    color: var(--theme-color-dcdfe5);
    border-radius: 3px;
    border: 2px solid #2f353d;
    &:hover {
      border: 2px solid #1d43dd;
      cursor: pointer;
    }
    .error-valid {
      border: 2px solid tomato;
    }
  }

  .ui.fluid.selection.dropdown.foresight-dropdown.dropdown-filter {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 8px;
    gap: 12px;
    height: 40px;
    background: #151a20;
    border: 2px solid #2f353d;
    color: var(--theme-color-dcdfe5);
    border-radius: 3px;
    border: 2px solid #2f353d;
    &:hover {
      border: 2px solid #1d43dd;
      cursor: pointer;
    }
    &.error-valid {
      border: 2px solid red;
    }
  }

  .ui.fluid.search.selection.dropdown.foresight-dropdown.dropdown-filter {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 8px;
    gap: 12px;
    height: 40px;
    background: #151a20;
    border: 2px solid #2f353d;
    color: var(--theme-color-dcdfe5);
    border-radius: 3px;
    border: 2px solid #2f353d;
    &:hover {
      border: 2px solid #1d43dd;
      cursor: pointer;
    }
  }

  .divider.text {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #dcdfe5;
  }
  .ui.selection.visible.dropdown > .text:not(.default) {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #87878798;
  }

  .ui.selection.active.dropdown .menu {
    border: 2px solid #1d43dd;
    color: var(--theme-color-dcdfe5);
    left: -1px;
    top: 34px;
    width: calc(100% + 4px);
  }
  .ui.fluid.search.selection.dropdown.foresight-dropdown.dropdown-filter {
    .search {
      color: var(--theme-color-dcdfe5);
      line-height: 20px;
      height: 38px;
      left: -7px;
      top: -2px;
      font-size: 14px;
      font-family: 'Inter';
    }
    &.error-valid {
      border: 2px solid red;
    }
  }
  .ui.active.visible.fluid.selection.dropdown.foresight-dropdown.dropdown-filter {
    border-top: 2px solid #1d43dd;
    border-left: 2px solid #1d43dd;
    border-right: 2px solid #1d43dd;
    color: var(--theme-color-dcdfe5);
  }

  .ui.active.visible.fluid.search.selection.dropdown.foresight-dropdown.dropdown-filter {
    border-top: 2px solid #1d43dd;
    border-left: 2px solid #1d43dd;
    border-right: 2px solid #1d43dd;
    color: var(--theme-color-dcdfe5);

    .search {
      color: var(--theme-color-dcdfe5);
      line-height: 20px;
      height: 38px;
      left: -7px;
      top: -2px;
      font-size: 14px;
      font-family: 'Inter';
      &::selection {
        color: #1d43dd;
        background: var(--theme-color-dcdfe5);
      }
    }
  }
  .visible.menu.transition {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #dcdfe5;
    .message {
      font-family: 'Inter';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: #dcdfe5;
    }
  }

  i.dropdown.icon {
    top: 10px !important;
    &::before {
      content: '' !important;
      display: inline-block;
      border-bottom: 2px solid var(--theme-color-dcdfe5);
      border-right: 2px solid var(--theme-color-dcdfe5);
      height: 10px;
      width: 10px;
      transform: rotate(45deg);
    }
  }
}
.dropdown-filter {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px;
  gap: 12px;
  height: 40px;
  background: #151a20;
  border: 2px solid #2f353d;
  color: var(--theme-color-dcdfe5);
  border-radius: 3px;
  border: 2px solid #2f353d;
  &:hover {
    border: 2px solid #1d43dd;
    cursor: pointer;
  }

  .visible.menu.transition {
    background: #151a20;
    margin: 2px 0 0 -1px;
    border: 1px solid #1d43dd;
    .active.item {
      background-color: #1d43dd;
      transition: background-color 0.2s ease-in-out;
      color: #dcdfe5;
    }
    .active.selected.item {
      background-color: #1d43dd;
      transition: background-color 0.2s ease-in-out;
      color: #dcdfe5;
    }

    & > div {
      color: var(--theme-color-dcdfe5);
      span {
        font-size: 14px;
      }
      &:hover {
        background-color: #1c222a;
        transition: background-color 0.2s ease-in-out;
        color: #dcdfe5;
      }
    }
  }
}
