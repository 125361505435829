.dashboard-grid-heatmap-widget-card-container {
  min-height: 172px;

  background-color: #1b232b;
  border-radius: 6px;
  display: flex;
  flex-direction: column;

  .header {
    padding: 6px 0px 6px 32px;
    margin-top: 12px;
  }

  .recharts-wrapper {
    cursor: default !important;
  }

  .other-contents {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  // This is to hide right down icon
  .ui.dropdown {
    > i {
      display: none;
    }

    .menu {
      margin-top: 6px;
    }
  }
}

.chart-heatmap-grid {
  width: 1200px;
  overflow-y: auto;
  margin-top: 10px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 6px 32px 6px 32px;
}

.status-section-desc {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: var(--theme-color-96a0b0);
}

@media (max-width: 480px) {
  .dashboard-grid-heatmap-widget-card-container {
    width: calc(100% - 335px);

    .chart-heatmap-grid {
      width: 100%;
      overflow-x: auto;
      margin-top: 10px;
      align-items: center;
      flex-direction: column;
      padding: 6px 16px 6px 16px;
    }
  }
}
