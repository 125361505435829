@mixin thundra-font-interUI-fS10-wBold {
  font-family: 'Inter';
  font-size: 10px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 12px;
}

@mixin thundra-font-interUI-fS10-w600 {
  font-family: 'Inter';
  font-size: 10px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 12px;
}

@mixin thundra-font-interUI-fS10-w500 {
  font-family: 'Inter';
  font-size: 10px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 12px;
}

@mixin thundra-font-interUI-fS12-w500 {
  font-family: 'Inter';
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 12px;
}

@mixin thundra-font-interUI-fS13-w500 {
  font-family: 'Inter';
  font-size: 13px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 16px;
}
