.test-view-page-filters-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
  padding: 32px 24px;
  background-color: var(--theme-color-161b22);
  overflow-y: auto;

  label {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    display: flex;
    align-items: center;
    letter-spacing: -0.01em;
    color: #8792a3;
    margin-bottom: 4px;
  }

  .test-view-page-filters-title {
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: baseline;
    margin-bottom: 32px;

    .title-text {
      font-family: 'Inter';
      font-style: normal;
      font-weight: 600;
      font-size: 20px;
      line-height: 24px;
      letter-spacing: -0.016em;
      color: var(--theme-color-dcdfe5);
    }

    .title-icon {
      font-weight: 600;
      font-size: 24px;
      color: #b2baca;
      width: 40px;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;

      &:hover {
        cursor: pointer;
        border-radius: 20px;
        background: var(--theme-color-rgba_220_223_229_008);
      }
    }
  }
  .test-view-page-filters-content-container {
    overflow-y: auto;
    padding: 0 5px;
    height: 100%;
    .horizontal-line-test-filter {
      width: 100%;
      border-bottom: 2px solid #1b232b;
      margin: 10px 0;
    }
    .test-view-page-filters-content {
      display: flex;
      flex-direction: column;
      gap: 24px;
      .select-branch-container {
        margin-bottom: 24px;
        .check-box-tree-list-cont {
          height: 100%;
          border-radius: 3px;
        }
      }
      .select-repo-container {
        .divider.text {
          font-family: 'Inter';
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 20px;
        }
        .visible.menu.transition {
          font-family: 'Inter';
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 20px;
        }
        .ui.active.selection.dropdown {
          border-color: #1d43dd;
          color: var(--theme-color-dcdfe5);
          .search {
            color: var(--theme-color-dcdfe5);
            height: 40px;
          }
          &:hover {
            border-color: #1d43dd;
          }
        }
      }
    }
  }
  .filter-footer-container {
    margin-top: auto;
    .footer-divider {
      width: 100%;
      border: 1px solid #2f353d;
      margin-top: 24px;
    }
    .test-view-page-filters-actions {
      display: flex;
      gap: 10px;
      margin-top: 30px;
    }
  }
  .main-branch-container {
    display: flex;
    flex-direction: column;
    margin-top: 26px;
    .main-branch-toggle-wrapper {
      display: flex;
      gap: 10px;
      align-items: center;
      margin-bottom: 10px;
      .ui.fitted.toggle.checkbox.test-view-page-filter-toggle {
        & > label {
          &::before {
            background-color: transparent;
            border: 1px solid #4d5b6e;
            transition: all 0.2s ease-in;
          }
        }
      }
      .ui.checked.fitted.toggle.checkbox.test-view-page-filter-toggle {
        & > label::before {
          background-color: #1d43dd !important;
          border: 1px solid transparent;
        }
      }
      .test-view-page-filter-toggle-text {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: #dcdfe5;
      }
    }
    .toggle-input {
      .master-branch-input {
        height: 40px;
        border: none;
        border-radius: 3px;

        width: 100%;
        & > input {
          border: 2px solid #2f353d;
          font-family: 'Inter';
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 20px;
          color: #dcdfe5;
          background: #151a20;
          &:focus {
            border: 2px solid #163dd4;
          }
        }
      }
      & > label > span {
        color: #ff5630;
        margin-left: 3px;
      }
    }
  }
  .save-filter-container {
    display: flex;
    flex-direction: column;
    margin-top: 26px;
    .save-filter-wrapper {
      display: flex;
      gap: 10px;
      align-items: center;
      margin-bottom: 10px;

      .test-view-page-filter-checkbox-text {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: #dcdfe5;
      }
    }
    .checkbox-input {
      .save-filter-input {
        height: 40px;
        border: none;
        border-radius: 3px;

        width: 100%;
        & > input {
          border: 2px solid #2f353d;
          font-family: 'Inter';
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 20px;
          color: #dcdfe5;
          background: #151a20;
          &:focus {
            border: 2px solid #163dd4;
          }
        }
      }
      & > label > span {
        color: #ff5630;
        margin-left: 3px;
      }
    }
  }
}
