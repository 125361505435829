.ui.breadcrumb.foresight-menu-breadcrumb {
  margin-bottom: 16px;
  color: var(--theme-color-8993a4cc);

  & > .divider {
    padding: 0 6px;
    color: var(--theme-color-868ea2);
    overflow: hidden;
  }

  & > .section {
    font-size: 14px;
    font-weight: 500;
    color: var(--theme-color-788496);
    white-space: nowrap;
    max-width: 300px;
    line-height: 16px;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  a {
    color: var(--theme-color-788496);
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;

    &:hover {
      text-decoration: underline;
    }
  }
}
