.badge-modal-title {
  color: var(--theme-color-dcdfe5);
  font-size: 20px;
  line-height: 24px;
  font-weight: 500;
  margin-bottom: 16px;
}

.ui.form .badges-form-field {
  #copy-popup-id {
    z-index: 9999;
  }

  .field {
    width: 100%;
    padding-right: 0;

    label {
      color: var(--theme-color-788496);
      line-height: 16px;
      font-weight: 600;
      font-size: 11px;
    }

    .ui.selection.dropdown {
      background: transparent;
      color: var(--theme-color-b2baca);
      display: flex;
      justify-content: space-between;
      padding-right: 6px;
      border-color: var(--theme-color-2f353d) !important;

      &.active {
        border-color: var(--theme-color-254be2) !important;
      }

      .text {
        font-size: 14px;
        line-height: 20px;
        color: var(--theme-color-b2baca);
      }

      .visible.menu.transition {
        background: var(--theme-color-0e1216);
        border-color: var(--theme-color-254be2) !important;

        .active.selected.item {
          background: var(--theme-color-1d43dd);
          font-weight: 400;

          .text {
            color: var(--theme-color-ffffff);
          }
        }

        .item:hover {
          background: var(--theme-color-1c222a);
        }
      }

      .icon {
        font-size: 16px;
        align-self: center;
      }
    }

    .embed-code-container {
      background-color: var(--theme-color-1c222a);
      padding: 8px;
      display: flex;
      justify-content: space-between;
      margin-left: 0 !important;
      margin-bottom: 24px;
      border-radius: 3px;

      div {
        max-width: 100%;
        word-wrap: break-word;
        padding-right: 10px;
      }

      i {
        margin-left: -10px;
      }
    }
  }

  margin: 8px 0px;
}

.modal-actions {
  width: 100%;
  text-align: end;
  margin-right: 24px;

  .close-modal {
    background-color: var(--theme-color-1d43dd);
    margin-right: 0;
  }
}
