.list-card-main-info-container {
  display: flex;
  gap: 24px;
}

.list-card-flex-row-item {
  display: flex;
  height: 24px;
  align-items: center;
  gap: 4px;

  .icon-container {
    cursor: default;

    i {
      font-size: 16px;
      line-height: 16px;
    }
  }

  .desc-container {
    cursor: default;

    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 15px;
    letter-spacing: -0.005em;
    color: #b2baca;

    &.secondary {
      color: #5c697c;
    }
  }
}

.list-card-main-text-item {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #dcdfe5;
}

.list-card-header-filter-label {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 2px 8px;
  gap: 4px;

  width: 142px;
  height: 20px;

  background: rgba(110, 118, 129, 0.24);
  border-radius: 12px;

  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: -0.01em;
  color: #788496;

  .icon {
    cursor: pointer;
    font-size: 10px;
  }
}
