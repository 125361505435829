.badge-container {
  display: flex;
  align-items: center;
  width: 100%;
  height: 20px;
  max-width: fit-content;
  border: 1px solid rgba(255, 255, 255, 0.24);
  border-radius: 10px;
  padding: 0 8px;
  gap: 4px;
  .badge-text {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 11px;
    line-height: 16px;
    color: #09152f;
    height: 16px;
  }
}
