.wf-run-summary-title {
  position: sticky;
  top: 0;
  height: 20px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  display: flex;
  align-items: center;
  letter-spacing: -0.01em;
  color: #b2baca;
  margin-bottom: 10px;
}

.wf-run-summary-container {
  position: sticky;
  top: 25px;
  display: flex;
  flex-direction: column;
  gap: 24px;

  .mini-card-sub-info {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: #dcdfe5;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 180px;
  }
  .mini-card-link {
    color: var(--theme-color-1e90ff);
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;

    &:hover {
      cursor: pointer;
      text-decoration: underline;
    }
  }

  .wf-run-test-summary-info-cont {
    display: flex;
    gap: 16px;
    .wf-run-test-summary-info {
      display: flex;
      align-items: center;
      gap: 5px;
      .wf-run-test-count {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 20px;
        color: #dcdfe5;
      }
    }
    .test-run-check {
      font-size: 16px;
      &::before {
        color: #2dcd8a;
      }
    }
    .test-run-failed {
      font-size: 16px;
      &::before {
        color: #ff5630;
      }
    }
  }

  .wf-run-test-summary-sub-info-cont {
    display: flex;
    gap: 8px;

    .sub-info-cont {
      border: 1px solid #2f353d;
      border-radius: 3px;
      padding: 2px 5px;
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 16px;
      letter-spacing: -0.005em;
      color: #6a7689;
    }
  }
}
