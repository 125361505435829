.repository-pr-view-page-container {
  background-color: var(--theme-color-0e1216);
  display: flex;
  flex-direction: column;

  .repository-title-container {
    display: flex;
    align-items: center;
    min-height: 32px;

    div {
      color: var(--theme-color-dcdfe5);
      font-style: normal;
      font-weight: 500;
      font-size: 20px;
      line-height: 24px;
      letter-spacing: -0.04em;

      display: flex;
      align-items: center;
      height: 100%;
    }

    .title {
      margin-right: 24px;
    }
  }

  .pull-requests {
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: calc(100vh - 250px);

    .left-part {
      width: 280px;
    }

    .right-part {
      overflow-y: auto;
      width: calc(100% - 280px);
    }
  }

  .workflow-no-runs {
    width: 100%;
    height: calc(100vh - 235px);
    display: flex;
    align-items: center;
    justify-content: center;

    .msg-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin-bottom: 172px;

      .main-msg {
        font-style: normal;
        font-weight: 600;
        font-size: 20px;
        line-height: 24px;
        display: flex;
        align-items: center;
        letter-spacing: -0.02em;
        color: #dcdfe5;
        margin-bottom: 16px;
      }

      .sub-msg {
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 20px;

        display: flex;
        align-items: center;
        text-align: center;
        color: #dcdfe5;
        margin-bottom: 24px;
      }

      .get-started-btn {
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        text-align: center;
        letter-spacing: -0.01em;

        color: #ffffff;
        background: #1d43dd;
        border-radius: 3px;
      }
    }
  }
}

.pr-list-header {
  .list-cards-header-items {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    align-items: center;
    letter-spacing: -0.01em;
    color: #788496;
  }
}

@media (max-width: 480px) {
  .repository-pr-view-page-container {
    .pull-requests {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      width: 100%;
      height: calc(100vh - 250px);
      .left-part {
        width: calc(100% - 5px);
      }

      .right-part {
        overflow-y: auto;
        width: calc(100% - 5px);
      }
    }
  }
}
