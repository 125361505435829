.test-suite-card-list-container-wrapper{
  &:hover{
     text-decoration: none;
  }
}


.test-card-card-list-container-wrapper{
  &:hover{
     text-decoration: none;
  }
}


.test-suite-card-list-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;

  .title {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    display: flex;
    align-items: center;
    letter-spacing: 0.02em;
    color: var(--theme-color-b2baca);
  }

  .filter-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    height: 40px;
    margin: 4px 0px 20px 0px;
  }

  .suites-container {
    height: calc(100vh - 232px);
    overflow-y: auto;

    .suite-container {
      border: 1px solid var(--theme-color-222b35);
      box-sizing: border-box;
      border-radius: 3px;

      /* Inside Auto Layout */

      flex: none;
      order: 0;
      flex-grow: 0;
      margin: 0px 0px 16px 0px;

      &.selected {
        border: 1px var(--theme-color-363b41) solid;

        .suite-head {
          background-color: var(--theme-color-363b41);

          .left-part {
            background-color: var(--theme-color-363b41);

            .title {
              color: var(--theme-color-ffffff);
            }

            .stats {
              i {
                margin-right: 7px;
                margin-left: 7px;

                &:first-child {
                  margin-left: 0px;
                }
              }


              span {
                color: var(--theme-color-ffffff);
              }
            }
          }

          .right-part {
            i {
              color: var(--theme-color-ffffff);
            }
          }
        }
      }

      .suite-head {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        background-color: var(--theme-color-1c222a);
        cursor: pointer;
        
        .left-part {
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          align-items: flex-start;
          padding: 12px 8px;
          
          
          title {
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            line-height: 20px;
            display: flex;
            align-items: center;
            color: var(--theme-color-b2baca);

            word-break: break-all !important;
            white-space: nowrap !important;
            overflow: hidden !important;
            max-width: 352px;
            text-overflow: ellipsis !important;
          }

          .stats {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            padding: 3px 0px;

            i {
              margin-right: 7px;
              margin-left: 7px;
              font-size: 14px;


              &:first-child {
                margin-left: 0px;
              }
            }


            .split-point {
              margin-right: 0px;
              margin-left: 12px;

              display: flex;
              justify-content: center;

              svg {
                color: var(--theme-color-5c697c);
                font-size: 3px;
              }
            }


            span {
              font-style: normal;
              font-weight: 500;
              font-size: 11px;
              line-height: 16px;
              color: var(--theme-color-b2baca);
            }
          }
        }

        .right-part {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: center;
          padding: 8px;



          i {
            color: var(--theme-color-788496);
            font-size: 14px;
          }
        }
      }

      .test {
        display: flex;
        padding: 0px 2px;
        border-bottom: 1px solid var(--theme-color-151a20);
        justify-content: space-between;
        align-items: center;
        cursor: pointer;
        height: 38px;
        min-height: 38px;


        &.selected {
          background-color: var(--theme-color-363b41);
          color: white;

          &:hover {
            background-color: var(--theme-color-363b41);
            color: white;
          }
        }

        &:hover {
          background-color: var(--theme-color-1c222a);
        }

        .left-part {
          display: flex;

          .test-name {
            word-break: break-all !important;
            white-space: nowrap !important;
            overflow: hidden !important;
            max-width: 316px;
            text-overflow: ellipsis !important;
            margin-left: 10px;

            font-style: normal;
            font-weight: 500;
            font-size: 12px;
            line-height: 16px;
            color: var(--theme-color-96a0b0);
          }

          .test-status {
            margin-left: 5px !important;
            font-size: 12px !important;
          }
        }

        .right-part {
          .test-duration {

            font-style: normal;
            font-weight: 500;
            font-size: 11px;
            line-height: 16px;
            color: var(--theme-color-788496);

            margin: 8px;
            align-items: center;
          }
        }
      }
    }
  }

  .no-suite-card-data-msg-container {
    height: calc(100vh - 232px);
    overflow-y: auto;
  }
}
