.foresight-circle-container {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 100%;
  width: 100%;

  & > .recharts-wrapper.piechart > .recharts-surface {
    transform: scaleX(-1) rotate(275deg);
  }
  & > .recharts-wrapper.piechart > .recharts-surface > text {
    transform: scaleX(-1) rotate(275deg) translate(-54%, -45%);
  }

  .foresight-circle-text-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 6px;
    /* It's a hack to make the text align with the center of the circle height. */
    margin-left: 2%;
    .foresight-circle-text {
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 20px;
      color: #dcdfe5;
    }
    .foresight-circle-sub-text {
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      line-height: 16px;
      color: #788496;
      white-space: nowrap;
    }
  }
  .foresight-circle-small-text-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 4px;
    /* It's a hack to make the text align with the center of the circle height. */
    margin-left: 15px;
    .foresight-circle-text {
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 20px;
      color: #dcdfe5;
    }
    .foresight-circle-sub-text {
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      line-height: 16px;
      color: #788496;
      white-space: nowrap;
    }
  }
}
