.change-pricing-plan-page-container {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  height: 100%;

  .change-pricing-plan-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 1200px;
  }

  .manage-part-container {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 36px;
  }

  .actions-container {
    border-top: 1px solid var(--theme-color-2f353d);
    padding-top: 32px;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
  }
}
