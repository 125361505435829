.ten-most-active-workflows {
  display: flex;
  flex-direction: column;
  margin-top: 12px;

  .most-active-workflows-header {
    padding: 6px 0px 6px 32px;
    margin-top: 12px;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: -0.01em;
    color: #dcdfe5;
  }

  .section-charts-container {
    display: flex;

    .most10active-chart {
      width: 50%;
      padding: 16px 32px 16px 32px;
    }
  }
}

@media (max-width: 480px) {
  .ten-most-active-workflows {
    .section-charts-container {
      display: flex;
      flex-direction: column;

      .most10active-chart {
        width: 100%;
        padding: 16px 32px 16px 32px;
      }
    }
  }
}
