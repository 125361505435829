.layout-container {
  display: flex;
  justify-content: center;
}

.layout-container-wrapper {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
  width: 1200px;
  row-gap: 24px;
}

.first-row-main-container {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.row-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  background-color: #1b232b;
  border-radius: 6px;
}

.first-item {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background-color: #1b232b;
  margin-bottom: 24px;
  border-radius: 6px;
}

.second-item {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: #1b232b;
  border-radius: 6px;
}

.third-item {
  width: 100%;
}

.forth-item {
  width: 100%;
}

.fifth-item {
  width: 100%;
}

.sixth-item {
  width: 100%;
}

.seventh-item {
  width: 100%;
}

.eight-item {
  width: 100%;
}

.section {
  display: flex;
  align-self: flex-start;
  font-weight: 600;
  font-family: 'Inter';
  font-size: 16px;
  line-height: 20px;
  color: #dcdfe5;
  margin-top: 30px;
}

.status-section-desc {
  display: flex;
  align-self: flex-start;
  margin-top: -14px;
  flex-wrap: wrap;
}

.section-none {
  display: none;
}

@media (max-width: 480px) {
  .layout-container-wrapper {
    width: 100%;
  }
  .first-item {
    flex-direction: column;
  }
}
