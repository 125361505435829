.workflow-run-overview-page-wrapper {
  min-width: 1200px;

  &.loading {
    height: 100%;
  }
}

.header-wrapper {
  margin-top: 10px;

  .breadcrumb span {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: -0.01em;
    color: var(--theme-color-788496);
  }

  .commit-msg {
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    color: var(--theme-color-ffffff);
    max-width: 700px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    margin-left: 12px;
  }
}

.worklow-card-list-panel-wrapper {
  height: 90px;
  width: 100%;
}

.workflow-run-job-wrapper {
  margin-top: 26px;
  width: 100%;
  display: flex;

  .worflow-job-list-wrapper {
    min-width: 350px;
    margin-right: 40px;

    .title {
      height: 20px;
      margin-bottom: 14px;

      span {
        font-weight: 600;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: -0.01em;
        color: var(--theme-color-b2baca);
      }
    }
  }

  .workflow-job-tabs-wrapper {
    display: flex;
    flex-direction: column;
    width: calc(100% - 390px);
  }
}

#duration-popup {
  background: var(--theme-color-5c697c);
  border-radius: 3px;
}

.worklflow-statuses {
  &:hover {
    text-decoration: underline;
    text-decoration-color: var(--theme-color-b2baca);
    cursor: pointer;
  }
}

.failed-link-icon {
  &::before {
    color: var(--theme-color-b2baca);
    margin-left: 10px;
  }
}

.workflow-header-popup {
  background-color: var(--theme-color-151a20) !important;
  padding: 14px 16px !important;

  &::before {
    visibility: hidden;
  }
  .content {
    color: var(--theme-color-c9d1d9);
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
  }
}

@media (max-width: 480px) {
  .workflow-run-overview-page-wrapper {
    min-width: 100%;

    .worklow-card-list-panel-wrapper {
      height: 100%;
      width: 100%;
    }
    .workflow-run-job-wrapper {
      margin-top: 26px;
      flex-direction: column;
    }
    .workflow-run-message {
      width: 100%;
      align-items: flex-start;
      flex-direction: column;
    }
  }
}
