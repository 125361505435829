@import 'assets/styles/size.scss';
@import 'assets/styles/font.scss';

.logs-table-container {
  display: flex;
  height: 100%;
  width: 100%;

  .logs-table-header {
    .ui.table {
      border: none !important;
    }

    .ui.table thead th {
      border: none; // remove table header borders
      padding: 10px 5px; // remove table header paddings
      color: var(--theme-color-909090);
      font-size: 10px;
      font-weight: normal;
      background-color: var(--theme-color-ffffff);
      text-align: left;
      border-bottom: 1px solid var(--theme-color-e6e6e6);
    }
  }

  .logs-table-header-wrapper {
    display: flex;

    .ui.table {
      border: none !important;
    }

    .ui.table thead th {
      border: none; // remove table header borders
      padding: 10px 5px; // remove table header paddings
      color: var(--theme-color-909090);
      font-size: 10px;
      font-weight: normal;
      background-color: var(--theme-color-ffffff);
      text-align: left;
      border-bottom: 1px solid var(--theme-color-e6e6e6);
    }
  }

  .logs-table-body-wrapper {
    height: calc(100% - 100px);
    overflow-y: auto;

    .logs-table-row {
      .expandable-msg-container-icon {
        display: flex;
        justify-content: center;
        visibility: hidden;
      }

      .log-timestamp-container {
        display: flex;
        align-items: center;
        min-height: 27px;

        .log-timestamp {
          color: var(--theme-color-1f2f59);
          @include thundra-font-interUI-fS12-w500;
          display: block;
        }

        .go-to-invocation {
          padding: 0px;
          margin: 0px;
          height: 24px;
          width: 108px;
          border-radius: 3px;

          background-color: var(--theme-color-2a6fe7);
          color: var(--theme-color-ffffff);
          font-family: 'Inter';
          font-size: 12px;
          font-weight: 500;
          line-height: 15px;
          text-align: center;

          display: none;
        }
      }

      .log-function-name {
        color: var(--theme-color-1f2f59);
        @include thundra-font-interUI-fS12-w500;
        display: block;
        width: 200px;
        white-space: nowrap !important;
        overflow: hidden !important;
        text-overflow: ellipsis !important;
      }

      .log-message-container {
        display: flex;
        flex-direction: row;
        align-items: center;

        .log-message-in-table-cell {
          color: var(--theme-color-1f2f59);
          @include thundra-font-interUI-fS12-w500;
          word-break: break-all !important;
        }

        .show-more-less-container {
          .btn_show_more_less {
            text-align: left !important;
            padding: 0px !important;
            margin-left: 8px !important;
            background-color: transparent !important;
            height: 26px;
            color: var(--theme-color-0052cc) !important;
            @include thundra-font-interUI-fS12-w500;
          }
        }
      }

      &:hover {
        background-color: var(--theme-color-f5f6f8) !important;

        .expandable-msg-container-icon {
          visibility: visible;
        }

        .log-timestamp-container {
          .go-to-invocation {
            display: block;
          }

          .log-timestamp {
            display: none;
          }
        }
      }
    }

    .logs-table-row.log-error {
      background-color: #fdefed;

      &:hover {
        background-color: #fbe6e1 !important;
      }
    }

    .logs-table-row.detail-opened {
      background-color: var(--theme-color-ecedf0);
      border-radius: 4px 4px 0 0;

      .expandable-msg-container-icon {
        visibility: visible;
      }
    }

    .logs-table-row.log-error.detail-opened {
      background-color: var(--theme-color-faded8);
      border-radius: 4px 4px 0 0;

      .expandable-msg-container-icon {
        visibility: visible;
      }
    }

    .logs-table-detail-row {
      background-color: var(--theme-color-091e42);

      .log-detail-container {
        max-width: calc(100vw - 340px);
      }
    }

    .ui.table {
      border: none !important;
    }

    .ui.table thead tr:first-child > th {
      position: sticky !important;
      top: 0;
      z-index: 999;
    }

    .ui.table thead th {
      border: none; // remove table header borders
      padding: 10px 7px; // remove table header paddings
      color: var(--theme-color-909090);
      font-size: 10px;
      font-weight: normal;
      background-color: var(--theme-color-ffffff);
      text-align: left;
      border-bottom: 1px solid var(--theme-color-e6e6e6);
    }

    .ui.table tr td {
      border: none; // remove row spacing borders
      border-bottom: 1px solid var(--theme-color-e6e6e6);
      padding: 10px 7px; // remove row paddings
      text-align: left;
      color: var(--theme-color-1f2f59);
      font-family: 'Inter';
      font-size: 13px;
      font-weight: 500;
      line-height: 16px;

      .log-error-stack-line {
        margin: 0px;
        padding: 0px;
      }
    }
  }
}

.no-logs-data-msg-container,
.error-logs-msg-container,
.loading-data-msg-container,
.logs-loading-msg-container {
  width: 100%;
  height: 100%;
  margin-top: 100px;
}
