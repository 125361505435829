.heatmap-color-ranges {
  display: flex;
  align-items: center;
  justify-content: center;

  .heatmap-color-ranges-colors {
    width: 75%;
    height: 10px;
    float: left;
    margin: 5px 0px 5px 0px;

    .heatmap-color-ranges-start-val {
      float: left;
      margin-left: -28px;
      color: #909090;
      font-family: 'Inter';
      font-size: 11px;
      font-weight: 500;
      line-height: 10px;
    }

    .heatmap-color-ranges-end-val {
      float: right;
      margin-right: -30px;
      color: #909090;
      font-family: 'Inter';
      font-size: 11px;
      font-weight: 500;
      line-height: 10px;
    }
  }
}

.heatmap-color-ranges {
  display: flex;
  align-items: center;
  justify-content: center;

  .heatmap-color-ranges-colors-width {
    width: 80%;
    height: 10px;
    margin: 5px -40px 5px 0px;

    .heatmap-color-ranges-start-val {
      float: left;
      margin-left: -23px;
      color: #909090;
      font-family: 'Inter';
      font-size: 11px;
      font-weight: 500;
      line-height: 10px;
    }

    .heatmap-color-ranges-end-val {
      float: right;
      margin-right: -25px;
      color: #909090;
      font-family: 'Inter';
      font-size: 11px;
      font-weight: 500;
      line-height: 10px;
    }
  }
}
