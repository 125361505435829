.ui.very.basic.table.foresight-table {
  position: relative;
  margin-top: 0px;
  border-collapse: collapse;

  thead {
    border-bottom: 2px solid #3e4d6168;
  }

  tbody > tr {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    &:hover {
      background-color: #161b22;
      box-shadow: 0px 8px 16px -2px rgba(9, 10, 12, 0.56), 0px 0px 1px rgba(9, 10, 12, 0.4);
    }
  }

  thead > tr > th {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: var(--theme-color-ebebeb);
    padding-left: 16px !important;
    padding-right: 16px !important;
  }
  tbody > tr > td {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: var(--theme-color-ebebeb);
    padding: 8px 16px !important;
  }
}
