.clndr-cell {
  background-color: #161b22 !important;
  border: none;
  color: inherit;
  cursor: pointer;
  font-family: inherit;
  height: 30px;
  padding: 5px 0;
  transition: all 0.2s;
}
.clndr-cell.inverted {
  background-color: #161b22;
}
.clndr-cell.inverted:hover {
  background-color: #2f353d !important;
  filter: brightness(1.3) !important;
  color: inherit;
}
.clndr-cell:hover {
  background-color: #2f353d !important;
  filter: brightness(1.3) !important;
  color: inherit;
}
.clndr-cell-today {
  background-color: #2f353d !important;
  opacity: 0.45;
}
.clndr-cell-inrange {
  background-color: #2f353d !important;
  color: inherit;
}
.clndr-cell-inrange.inverted {
  background-color: yellowgreen;
  color: inherit;
}
.clndr-cell-disabled {
  cursor: default;
  background-color: #21252d !important;
  opacity: 1 !important;
}
.clndr-cell-disabled:hover {
}
.clndr-cell-disabled.inverted:hover {
  background-color: lime !important;
  color: inherit;
}
.clndr-cell-selected {
  background-color: #2f353d !important;
  filter: brightness(1.3) !important;
  color: #f2f2f2;
}
.clndr-cell-selected.inverted {
  background-color: #2f353d !important;
  filter: brightness(1.3) !important;
  color: #000;
}
.clndr-cell-other-month {
  color: #d9d9d9;
}
.clndr-cell-other-month.inverted {
  color: #a6a6a6;
}
.clndr-calendars-segment {
  margin-bottom: 0.25rem !important;
  margin-top: 0.25rem !important;
  text-align: center;
  background-color: #161b22 !important;
}
.clndr-calendars-segment.clndr-floating {
  position: absolute !important;
  z-index: 2000;
}
.clndr-calendars-wrapper {
  grid-gap: 1em;
  display: grid;
  grid-template-columns: repeat(var(--n, 1), 1fr);
  .ui.basic.button {
    background: #161b22 !important;
    box-shadow: inset 0px 0px 0px 1px rgba(220, 223, 229, 0.15);
    border-radius: 3px;
    color: #c9d1d9 !important;
  }
}
.clndr-control {
  align-items: center;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  margin-bottom: 10px;
  & > span {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
  }
}
.clndr-days {
  grid-gap: 1px;
  background-color: rgba(0, 0, 0, 0.1);
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 0.28571429rem;
  display: grid;
  grid-template-columns: repeat(7, minmax(2.2rem, 1fr));
  overflow: hidden;
  text-align: center;
}
.clndr-left {
  left: 0;
}
.clndr-right {
  right: 0;
}
.clndr-top {
  bottom: 100%;
}
.clndr-bottom {
  top: 100%;
}
.clndr-calendars-segment.clndr-top {
  box-shadow: 0 -1px 2px 0 rgba(34, 36, 38, 0.15) !important;
  margin-bottom: 0.25rem !important;
}

.clndr-calendars-segment {
  box-shadow: 0 -1px 2px 0 rgba(34, 36, 38, 0.15) !important;
  margin-bottom: 0.25rem !important;
  & > button[data-testid='datepicker-today-button'] {
    background-color: #2f353d !important;
    color: rgba(201, 209, 217, 0.6) !important;
    &:hover {
      filter: brightness(1.3);
    }
  }
}

.ui.icon.input.foresight-calendar-component {
  &:hover {
    filter: brightness(1.3);
    cursor: pointer;
  }
  & > input {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px 16px;
    gap: 8px;
    height: 32px;
    font-size: 14px;
    color: #3e4d61;
    border: 2px solid #2f353d;
    border-radius: 3px;
    &:hover {
      cursor: pointer;
    }
  }
}

.ui.input.focus > input,
.ui.input > input:focus {
  background: #0e1216;
}
