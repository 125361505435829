.test-view-advanced-container {
  display: flex;
  gap: 20px;

  .ui.button.GrayBorderButton {
    min-width: 150px;
    height: 32px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    display: flex;
    align-items: center;
    text-align: right;
    letter-spacing: -0.004em;
    color: #b2baca;
    padding: 6px 12px 6px 8px;
  }

  .icon-filter-icon {
    font-size: 16px;
    margin: 0 6px 0 0;
  }
}
