:root {
  --margin-between-menu-items: 16px;
  --margin-between-doc-items: 8px;
  --top-margin-for-menu-items: 2px;
  --left-margin-live-tag: 16px;
  --left-margin-leftmost-item: 32px;
  --projects-testruns-inbetween: 8px;
}

.thundra-test-analytics-logo {
  display: inline-block;
  width: 100%;
  background-size: 100% auto;
  background-position: center;
  background-repeat: no-repeat;
  padding-right: 0px;

  margin: var(--top-margin-for-menu-items) 0px 0px 0px;
}

.ui.menu {
  border: 1px solid var(--theme-color-rgba_34_36_38_0);
}

#mobile-menu-icon {
  display: none;
}
#no-grid {
  display: block;
}

.ui.menu .item {
  display: flex;
  justify-content: flex-end;
  align-self: center;
  height: 100%;
  a:hover {
    text-decoration: none;
    border: none;
  }

  &.logo {
    padding-left: 0px;
    padding-right: 0px;
    margin-left: var(--margin-between-menu-items);
    margin-right: var(--margin-between-menu-items);
    margin-top: var(--top-margin-for-menu-items);
  }

  &.projects {
    margin: var(--top-margin-for-menu-items) 0px 0px var(--projects-testruns-inbetween);
    padding: 0px;
  }

  &.header {
    margin-top: var(--top-margin-for-menu-items);
    padding: 0;
    height: 100%;
    margin-left: var(--projects-testruns-inbetween);
    margin-top: var(--top-margin-for-menu-items);
  }

  &.explore {
    margin-top: var(--top-margin-for-menu-items);
    padding: 0;
    height: 100%;
    margin-left: var(--projects-testruns-inbetween);
    margin-top: var(--top-margin-for-menu-items);
    font-style: normal;
    font-weight: 600;
    font-size: 30px !important;
    line-height: 16px;
    color: var(--theme-color-dcdfe5);
  }

  border-bottom: 4px solid transparent;

  &.active {
    border-bottom: 4px solid var(--theme-color-4367fd);
    background-color: var(--theme-color-151a20);
  }

  &.user-item {
    height: 40px;
    padding: 11px;
    margin-top: var(--top-margin-for-menu-items);

    .user-item-circle {
      background-color: var(--theme-color-dcdfe5);
      color: var(--theme-color-0e1216);
      padding: 5px;
      border-radius: 50%;
      width: 24px;
      height: 24px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 8px;
      margin-top: 8px;
      font-style: normal;
      font-weight: 700;
      font-size: 12px;

      &:hover {
        box-shadow: 0px 0px 0px 10px var(--theme-color-222b35);
      }
    }
  }
}

.topbar-menu > .right.menu {
  margin-right: 20px;
}

i.grid.layout {
  color: var(--theme-color-rgba_255_255_255_05);
}

.custom-profile-menu-wrapper {
  z-index: 100;
  position: fixed;
  width: 284px;
  top: 52px;
  right: 16px;
  background-color: var(--theme-color-161b22);
  border: 1px solid var(--theme-color-30363d);
  /* D-Card-DropShadow-01 */

  box-shadow: 1px 2px 8px var(--theme-color-rgba_10_13_16_48), 2px 4px 16px var(--theme-color-rgba_10_13_16_40) 0,
    4px 8px 16px var(--theme-color-rgba_10_13_16_32);
  border-radius: 3px;
  text-align: left;
  color: var(--theme-color-6a7689);
  font-size: 11px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 14px;

  .menu-item-wrapper {
    border-bottom: 2px solid var(--theme-color-30363d);
    padding-bottom: 6px;

    & > a {
      text-decoration: none;
    }

    &.no-border {
      border-bottom: 0px;
    }

    .transition-header {
      padding: 12px 16px;
      color: var(--theme-color-788496);
      font-weight: 500;
      font-size: 11px;
      line-height: 16px;
    }
    .transition-icon-menu-item {
      a:hover {
        text-decoration: none;
      }
    }

    .transition-icon-menu-item:hover {
      background-color: var(--theme-color-rgba_110_118_129_016);
      cursor: pointer;
    }

    .transition-text-menu-item {
      color: var(--theme-color-c9d1d9);
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      padding: 6px 0 6px 16px;
      width: 100%;

      &.single {
        padding-top: 8px;
      }

      &:hover {
        background-color: var(--theme-color-rgba_110_118_129_016);
        cursor: pointer;
      }

      a {
        color: var(--theme-color-c9d1d9);
        text-decoration: none;
      }
    }

    .table-menu-item {
      display: flex;
      align-items: center;
      padding: 6px 18px 6px 18px;

      .active-organization-icon {
        font-size: 16px;
        &::before {
          color: #2dcd8a;
        }
      }

      .avatar-icon {
        font-size: 24px;
        margin-right: 10px;
      }

      .menu-letter-avatar {
        border-radius: 50%;
        background-color: var(--theme-color-788496);
        height: 32px;
        width: 32px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 10px;
        color: var(--theme-color-0e1216);
        font-weight: 600;
        text-transform: capitalize;

        &.selected-item {
          background-color: var(--theme-color-c9d1d9);
        }
      }

      .title-wrapper {
        position: block;
        flex: 1 1 auto;
        align-items: center;

        &.with-owner-icon {
          display: flex;
        }

        .avatar-owner-img {
          height: 12px;
          width: 12px;
          margin-left: 8px;
        }

        .title {
          color: var(--theme-color-788496);
          font-weight: 500;
          font-size: 14px;
          line-height: 20px;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
          max-width: 155px;

          &.selected-item {
            color: var(--theme-color-c9d1d9);
          }
        }

        .subtitle {
          color: var(--theme-color-6a7689);
          font-size: 11px;
          line-height: 16px;

          &.selected-item {
            color: var(--theme-color-c9d1d9);
          }
        }
      }

      .checked-img {
        height: 16px;
        width: 16px;
      }
    }
  }
}

#no-grid {
  font-size: 24px;
  pointer-events: none;
}

.topbar-menu-wrapper {
  #topbar-banner {
    background-color: var(--theme-color-21262d);
    height: 40px;
    min-height: 30px;
    margin-top: 0px;
    display: flex;
    align-items: center;
    justify-content: center;

    .topbar-banner-text {
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 17px;
      color: var(--theme-color-dcdfe5);
    }

    .start-link {
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 17px;
      padding-left: 1px;
      color: var(--theme-color-3385ff);

      &:hover {
        color: var(--theme-color-5599ff);
        text-decoration: underline;
        cursor: pointer;
      }
    }
  }
}

.docs {
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 3px solid transparent;
}

.other-item {
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 12px 24px 12px 24px;
  cursor: pointer;
}

.doc-item {
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--theme-color-dcdfe5);
  margin: var(--top-margin-for-menu-items) 0px 0px var(--margin-between-doc-items);
  border-radius: 3px;
  cursor: pointer;

  &:hover {
    background-color: var(--theme-color-222b35);
  }

  a {
    text-decoration: none;
    color: var(--theme-color-dcdfe5);
  }
}
.doc-item-btn {
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--theme-color-dcdfe5);
  margin: var(--top-margin-for-menu-items) 0px 0px var(--margin-between-doc-items);
  border-radius: 3px;
  cursor: pointer;

  a {
    text-decoration: none;
    color: var(--theme-color-dcdfe5);
  }
}

.connect-btn-top-bar {
  margin-right: 8px;
}

.documentation {
  text-align: center;
  padding: 8px;
}

.get-help {
  padding: 8px;
  text-align: center;
  height: 32px;
}

.get-started {
  color: var(--theme-color-ffffff);
  background-color: var(--theme-color-1d43dd);
  padding: 8px;
  text-align: center;
  height: 32px;
  cursor: pointer;
}

#get-started-id {
  &:hover {
    background-color: var(--theme-color-4367fd);
  }
}

.topbar-menu-wrapper {
  #topbar-banner {
    background-color: var(--theme-color-21262d);
    height: 40px;
    min-height: 30px;
    margin: 0px 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    color: var(--theme-color-dcdfe5);

    .start-link {
      padding-left: 1px;
      color: var(--theme-color-3385ff);

      &:hover {
        cursor: pointer;
      }
    }
  }
}

.repositories-header,
.testruns-header {
  color: var(--theme-color-dcdfe5);
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  display: flex;
  align-items: center;
  height: 32px;
  color: var(--theme-color-dcdfe5);

  border: 8px solid transparent;

  &:hover {
    background-color: var(--theme-color-222b35);
    border-radius: 3px;
    border: 8px solid var(--theme-color-222b35);
    color: var(--theme-color-dcdfe5);
    text-decoration: none;
  }

  a {
    color: var(--theme-color-dcdfe5);
    text-decoration: none;
  }
}

.workflows-header,
.highlight-header,
.explore-header {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  color: var(--theme-color-dcdfe5);
  margin-left: 0;

  border: 8px solid transparent;

  &:hover {
    background-color: var(--theme-color-222b35);
    border-radius: 3px;
    border: 8px solid var(--theme-color-222b35);
    text-decoration: none;
    color: var(--theme-color-dcdfe5);
  }

  a {
    color: var(--theme-color-dcdfe5);
    text-decoration: none;
  }
}

.live-tag {
  border-radius: 3px;
  margin-left: 0px;
}

#live-tag-container {
  padding: 0px;
  margin-left: var(--left-margin-live-tag);
  margin-right: var(--left-margin-live-tag);

  .public-domain-tag {
    padding: 4px 12px;
    height: 24px;
    background: #222b35;
    border-radius: 12px;
    flex: none;
    flex-grow: 0;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    color: #dcdfe5;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 120px;
  }
}

#menu-item-grid {
  padding: 0px;
  margin: 0px;
  font-size: 24px;
}

.ui.menu.topbar-menu {
  background-color: var(--theme-color-151a20);
}

.ui.menu .item::before {
  background-color: transparent;
}

.ui.menu .active.item:hover,
.ui.vertical.menu .active.item:hover {
  background-color: var(--theme-color-rgba_0_0_0_1);
}

.ui.menu .active.item:hover,
.ui.vertical.menu .active.item:hover {
  background-color: transparent;
}

.ui.menu .active.item {
  background-color: transparent;
}

@media (max-width: 480px) {
  .ui.menu .item {
    &.header {
      display: none;
    }
  }
  .repositories-header {
    justify-content: flex-start;
  }
  .workflows-header,
  .highlight-header,
  .explore-header {
    text-align: left;
  }
  #mobile-menu-icon {
    display: flex;
    font-size: 24px;
    justify-content: center;
    align-items: center;
    margin-left: 5px;
  }
  .connect-btn-top-bar {
    & > .ui.primary.button.BlueButton {
      height: 30px;
      font-size: 12px;
    }
  }
  #no-grid {
    display: none;
  }

  .circle-left-days-classes {
    display: none;
  }
}
