@import 'assets/styles/size.scss';


.error-stack-flex-container {
  display: flex;
  height: 100%;

  .error-stack-row-list {
    height: 100%;
    width: 100%;
    overflow: auto;

    margin-right: -30px;
    padding-right: 40px;

    .error-stack-row {
      display: flex;
      width: 100%;
      letter-spacing: 0;

      font-family: IBM Plex Mono;
      font-style: normal;
      font-weight: 500;

      color: var(--theme-color-b2baca);

      span {
        font-size: 12px !important;
        line-height: 16px !important;
      }

      .line-number {
        display: none; //This is hidden because of Austin comments: Log Selection Without Number
        color: var(--theme-color-71a7ff);
        width: 24px;
        text-align: right;
        margin-right: $spacing;

        span {
          width: 24px;
        }
      }

      .error-content-line {
        span {
          white-space: pre;
        }

        .code-line-number {
          color: var(--theme-color-57d9a3);
        }
      }
    }
    
  }

}


