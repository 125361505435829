.pr-sum-main-container {
  margin-top: 48px;

  .table-row {
    cursor: default !important;
  }

  .pr-sum-workflow-table {
    margin-bottom: 20px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    text-transform: uppercase;
    color: #dcdfe5;
  }
  .pr-sum-status-item-header-cont {
    display: flex;
    flex-direction: column;
    gap: 8px;
    align-items: flex-start;
    justify-content: center;
    .pr-sum-status-item-header {
      font-family: 'Inter';
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      line-height: 16px;
      color: #788496;
    }

    .pr-summary-status-icon {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 12px;
      .pr-summary-icon-text {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 20px;
        color: #dcdfe5;
      }
      .icon-icons-summary-cost {
        font-size: 40px;
        &::before {
          color: #5db95c;
        }
      }
      .icon-icons-summary-duration {
        font-size: 40px;
        &::before {
          color: #5fb1c6;
        }
      }
    }
  }
}
