.cld-main-container {
  display: flex;
  background-color: #222b35;
  border-radius: 16px;
  padding: 4px 12px 4px 4px;
  width: 130px;
  height: 32px;
  justify-content: space-between;
  align-items: center;

  .cld-circle-cont {
    position: relative;
    width: auto;
    height: auto;
    transform: scale(-1, 1) rotate(275deg);
  }

  .cld-text-cont {
    display: flex;
    align-items: center;

    .text {
      font-family: 'Inter';
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 16px;
    }

    .orange {
      color: #ff9214;
    }
    .green {
      color: #2dcd8a;
    }
  }
}
