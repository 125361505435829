:root {
  --active-item-inner-margins: -5px;
}

.menu-dropdown .text {
  font-size: 14px;
  font-weight: 600;
}

.ui .item .project-header {
  color: var(--theme-color-dcdfe5);
}

#projects-text {
  border: 8px solid transparent;
  height: 100%;
  &:hover {
    background-color: var(--theme-color-222b35);
    border-radius: 3px;
  }
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  color: var(--theme-color-dcdfe5);
  display: flex;
  align-items: center;
}
.menu-dropdown i.dropdown.icon {
  margin-left: 4px !important;
}

.project-header-container {
  display: flex;
  align-items: center;
  justify-content: center;
}
