.foresight-checkbox.fullwidth.ui.checkbox {
  label {
    width: 100%;
    max-width: none !important;
  }
}

.repo-card-list-item {
  flex: 0 0 33.333333%;
  max-width: 384px;
  max-height: 424px;

  .card {
    list-style: none;
    cursor: pointer;

    .card-header {
      background-color: var(--theme-color-1c222a);
      border-radius: 6px 6px 0px 0px;
      .repo-title-and-logo {
        min-height: 65px;
        max-height: 65px;
        display: flex;
        justify-content: space-between;

        .title {
          display: -webkit-box;
          margin: 25px 0px 0px 16px;
          width: 280px;
          font-weight: 500;
          font-size: 16px;
          line-height: 20px;
          color: var(--theme-color-dcdfe5);
          white-space: normal;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          overflow: hidden;
          text-overflow: ellipsis;

          background-color: var(--theme-color-1c222a);
          cursor: pointer;
          max-height: 60px;

          &.hover,
          &:hover {
            text-decoration: underline;
          }
        }

        .title-right-section {
          display: flex;
          margin-left: 8px;
          margin-top: 18px;
          margin-right: 20px;
          .more-icon-container {
            width: 32px;
            height: 32px;
            margin: 0px;

            border-radius: 3px;

            font-weight: 500;
            font-size: 14px;
            line-height: 20px;
            &:hover {
              background-color: var(--theme-color-222b35);
              cursor: pointer;
            }
            padding: 4px;

            .focus-state {
              background-color: var(--theme-color-b2baca);
              border-radius: 3px;
            }

            .normal-state {
              background-color: var(--theme-color-1c222a);
              border-radius: 3px;
              background-size: 220% auto;
            }
          }

          .more-icon-container:hover .normal-state,
          .more-icon-container.hover .normal-state {
            background: var(--theme-color-222b35);
          }

          .logo {
            width: 32px;
            height: 32px;
            .github-icon-style {
              padding: 4px;
              width: 100%;
              height: 100%;
              font-size: 26px;
            }
          }
        }
      }

      .foresight-tabs-main-container {
        margin-top: 10px;
      }
    }

    .card-body {
      --margin-top-card-tabs: 24px;

      padding: 10px;
      padding-left: 16px;
      height: 313px;
      background-color: var(--theme-color-151a20);
      border-radius: 0px 0px 6px 6px;

      .ci-cd-body {
        .recent-stats {
          font-style: normal;
          font-weight: 600;
          font-size: 12px;
          line-height: 16px;
          color: var(--theme-color-6a7689);
          margin-top: 24px;

          & > span {
            font-size: 12px;
          }

          .stats {
            display: flex;

            .workflow-piechart {
              margin-top: 8px;
              display: flex;

              .piechart {
                margin-right: 8px;
              }

              .workflow-info {
                .success-rate {
                  font-style: normal;
                  font-weight: 500;
                  font-size: 14px;
                  line-height: 20px;
                  color: var(--theme-color-dcdfe5);
                }
                .success-rate-text {
                  font-style: normal;
                  font-weight: 500;
                  font-size: 10px;
                  line-height: 16px;
                  color: var(--theme-color-6a7689);
                }
              }

              .recent-stats-exec-icon {
                margin-right: 8px;
                width: 20px;
                height: 20px;
                margin-left: 20px;
              }
            }
          }
        }
      }

      .latest-test-runs {
        color: var(--theme-color-788496);

        .header {
          font-style: normal;
          font-weight: 600;
          font-size: 12px;
          color: var(--theme-color-6a7689);
        }
        .tests-run-result-section {
          display: flex;

          .successful-tests {
            display: flex;
          }

          .failed-tests {
            display: flex;
          }

          .skipped-tests {
            display: flex;
          }

          .aborted-tests {
            display: flex;
          }

          .scale {
            transform: scale(1.8);
            margin-top: 5px;
          }

          .testrun-count {
            margin-top: 4px;
            margin-left: 8px;
          }

          .item {
            margin-right: 25px;
            margin-top: 12px;
          }
        }

        .recent-stats {
          font-style: normal;
          font-weight: 600;
          font-size: 12px;
          line-height: 16px;
          color: var(--theme-color-6a7689);
          margin-top: 23px;

          & > span {
            font-size: 12px;
          }

          .stats {
            display: flex;

            .testrun-piechart {
              margin-top: 8px;
              display: flex;

              .piechart {
                margin-right: 8px;
              }

              .testrun-info {
                .success-rate {
                  font-style: normal;
                  font-weight: 500;
                  font-size: 14px;
                  line-height: 20px;
                  color: var(--theme-color-dcdfe5);
                }
                .success-rate-text {
                  font-style: normal;
                  font-weight: 500;
                  font-size: 10px;
                  line-height: 16px;
                  color: var(--theme-color-6a7689);
                }
              }

              .recent-stats-exec-icon {
                margin-right: 8px;
                width: 20px;
                height: 20px;
                margin-left: 20px;
              }
            }
          }
        }
      }
    }
  }
}

#hover-status {
  background-color: var(--theme-color-1c222a);
  box-shadow: 2px 4px 16px var(--theme-color-rgba_10_13_16_32), 4px 8px 32px var(--theme-color-rgba_10_13_16_024),
    8px 16px 32px var(--theme-color-rgba_10_13_16_016);
  border-radius: 3px;
}

.at-hover {
  margin-top: 0px !important;
  padding-bottom: 8px;
}

#stop-watching-parent {
  border-top: 12px solid var(--theme-color-151a20);
  border-bottom: 7px solid var(--theme-color-151a20);
  box-shadow: 1px 2px 8px var(--theme-color-rgba_10_13_16_032), 2px 4px 16px var(--theme-color-rgba_10_13_16_024),
    4px 8px 16px var(--theme-color-rgba_10_13_16_016);
  border-radius: 3px;
  padding: 4px 0;

  display: flex;
  align-content: center;
  background-color: var(--theme-color-151a20);
  color: var(--theme-color-b2baca);

  .stop-watching {
    padding: 0;
  }

  &:hover {
    cursor: pointer;
  }

  .modal-header {
    color: var(--theme-color-6a7689);
    font-weight: 700;
    font-size: 11px;
    line-height: 16px;
    margin-bottom: 10px;
    padding: 0 16px;
  }

  .modal-item {
    color: var(--theme-color-b2baca);
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    padding: 5px 16px;

    &:hover {
      background-color: var(--theme-color-rgba_220_223_229_008);
    }
  }

  .delimiter {
    height: 2px;
    width: 100%;
    background: var(--theme-color-rgba_120_132_150_02);
    margin-top: 7px;
    margin-bottom: 7px;
  }
}
.empty-tab {
  display: table;
  margin: 0 auto;
  box-sizing: border-box;
  width: 100%;

  .flying-bird {
    margin-top: 14px;
  }

  .center {
    display: table;
    margin: 0 auto;
  }

  #empty-icon {
    margin-top: 24px;
    height: 40px;
    width: 40px;
  }

  .content {
    width: 100%;
  }

  .preparing-header {
    margin-top: 24px;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    text-align: center;
    color: var(--theme-color-dcdfe5);
  }

  .preparing-text {
    margin-top: 4px;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    text-align: center;
    color: var(--theme-color-788496);
  }

  .text {
    text-align: center;
    margin-top: 22px;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: var(--theme-color-788496);
  }

  .test-text {
    text-align: center;
    margin-top: 22px;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: var(--theme-color-788496);
  }

  .light-text {
    margin: 12px 0 12px 0;
    color: var(--theme-color-b2baca);
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;

    &.connect-repository {
      white-space: initial;
      text-align: center;
    }

    .no-testrun-found {
      margin-bottom: 16px;
    }

    &.no-testrun-found,
    &.no-workflows-found {
      text-align: left;
      margin-left: 16px;
      margin-right: 32px;
      line-height: 20px;
      font-size: 14px;

      .help-text {
        margin-top: 4px;
        font-size: 14px;
        line-height: 20px;
      }

      ul {
        padding-left: 12px;

        li {
          white-space: initial;
          padding-left: 10px;

          &::marker {
            content: '\2022';
            font-weight: bold;
            display: inline-block;
            margin-left: 8px;
          }
        }
      }
    }
  }

  .card-connect-button-wrapper {
    margin-top: 4px;
    text-align: center;

    button {
      background: var(--theme-color-1b232b);
      color: var(--theme-color-b2baca);

      &:focus,
      &:hover {
        background: var(--theme-color-222b35);
        color: var(--theme-color-b2baca);
      }
    }
  }

  .integrate-tests-button {
    background-color: var(--theme-color-1b232b);
    font-family: Inter;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: -0.01em;
    color: var(--theme-color-b2baca);
    display: table;
    margin: 0 auto;
    &:hover {
      background-color: var(--theme-color-222b35);
      color: var(--theme-color-b2baca);
    }
  }
}

.ci-cd-header {
  margin-top: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px;
  color: var(--theme-color-6a7689);
  font-size: 12px;

  .title-container {
    display: flex;
    align-items: center;
    color: var(--theme-color-6a7689);

    .title-sub-header-card {
      font-size: 12px;
      line-height: 20px;
    }

    i {
      margin-left: 4px;
      font-size: 12px;
    }

    &:hover {
      i {
        transition: all 0.3s ease-in-out;
        margin-left: 8px;
      }

      i::before {
        color: var(--theme-color-dcdfe5);
      }
    }
  }
  .title-container:hover {
    color: var(--theme-color-dcdfe5);
    transition: all 0.3s ease-in-out;
  }

  .item {
    margin-top: 8px;
  }

  .status {
    display: flex;
    color: var(--theme-color-dcdfe5);

    .status-text {
      margin-left: 8px;
    }
  }

  .status-icon {
    cursor: pointer;
    width: 16px;
    height: 16px;
  }

  .ci-cd-header-texts {
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
  }

  .execution-date {
    color: var(--theme-color-dcdfe5);
    display: flex;
  }

  .execution-time {
    color: var(--theme-color-dcdfe5);
    display: flex;
  }

  .last-workflow-runs {
    display: flex;

    .dot {
      height: 7px;
      width: 7px;
      border-radius: 50%;
      margin: 15px 30px 5px 8px;
    }
  }
}

.top {
  margin-top: 14px;
}

.ui.fluid.image.exec-icon {
  width: 16px;
  height: 16px;
  margin-right: 8px;
}

.stop-repo-watching-modal {
  box-shadow: 0px 4px 8px -2px var(--theme-color-rgba_137_147_164_24),
    0px 0px 1px var(--theme-color-rgba_137_147_164_32);
  border-radius: 3px;
  max-width: 600px;

  .title-section {
    display: flex;
    .icon-warning {
      font-size: 24px;
      margin-right: 8px;
    }
    .title {
      font-size: 20px;
      font-weight: 500;
      line-height: 20px;
      color: var(--theme-color-dcdfe5);
      margin-bottom: 16px;
    }
  }

  .description {
    font-size: 14px;
    font-weight: 400;
    color: var(--theme-color-b2baca);
    margin-bottom: 16px;
    line-height: 20px;
  }

  .webhook-preferences {
    padding-bottom: 16px;
  }

  .delete-actions {
    text-align: end;
    background: unset;

    .ui.button {
      &.cancel-button {
        background: transparent;
        border: 0;
        color: var(--theme-color-b2baca);
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        margin-right: 8px;

        &:hover {
          color: var(--theme-color-96a0b0);
          background-color: var(--theme-color-1b232b);
        }
      }

      &.primary {
        background-color: var(--theme-color-1d43dd);
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;

        &:hover {
          background-color: var(--theme-color-3459ef);
        }
      }
    }
  }
}

.link-wrapper-hover {
  &:hover {
    text-decoration: none;
  }
}

#repositories-project-card {
  .ui.primary.button {
    color: var(--theme-color-dcdfe5);
    font-size: 12px;
    font-style: normal;
    letter-spacing: -0.01em;
    line-height: 16px;
  }
}
