.existed-project-repo-selection-page-container {
  background-color: var(--theme-color-181b1f);
  display: block;
  overflow: auto;
  height: 100vh;

  .page-footer {
    height: 66px;
  }

  .page-body-container {
    width: 800px;
    height: calc(100% - 112px);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: auto;
    border-radius: 8px;
    background-color: var(--theme-color-0e1216);

    .page-body {
      width: 100%;
      height: 100%;
      padding: 20px 40px 0 40px;
      max-height: calc(100% - 112px);
      overflow: auto;

      .page-title {
        color: var(--theme-color-fafbfc);
        font-size: 24px;
        line-height: 33px;
        font-weight: 600;
        margin-bottom: 30px;
      }

      .project-title {
        color: var(--theme-color-dcdfe5);
        font-size: 16px;
        line-height: 21px;
        font-weight: 500;
        margin-bottom: 9px;
      }

      .repository-title-container {
        margin-top: 32px;
        margin-bottom: 30px;

        .repository-title-elements {
          display: inline-block;

          &.title {
            color: var(--theme-color-dcdfe5);
            font-weight: 500;
            font-size: 16px;
            line-height: 21px;
          }

          &.search {
            width: 240px;
            float: right;

            .repositories-search-input {
              width: 100%;
              border: 2px solid var(--theme-color-2f353d);
              border-radius: 3px;

              input {
                background-color: var(--theme-color-070aoc);

                &::placeholder {
                  color: var(--theme-color-3e4d61);
                }
              }

              &:focus {
                border: 2px solid var(--theme-color-163dd4);
              }
            }
          }
        }
      }

      .repository-desc-title {
        font-size: 14px;
        font-weight: 400;
        line-height: 21px;
        text-align: left;
        color: var(--theme-color-b2baca);
        margin-bottom: 30px;

        a {
          color: var(--theme-color-3385ff);
        }
      }
    }
  }

  .section-divider {
    border: 2px solid var(--theme-color-2f353d);
    width: 100%;
  }

  .footer-actions {
    display: flex;
    justify-content: center;
    align-self: end;
    margin: 32px 40px;

    .cancel-button {
      background-color: transparent;
      color: var(--theme-color-96a0b0);
      font-weight: 500;
      padding: 6px 12px;
      font-size: 14px;

      &:hover {
        background-color: var(--theme-color-1b232b);
      }
    }

    .submit-button {
      margin-left: 8px;
      background: var(--theme-color-1d43dd);
      color: var(--theme-color-ffffff);
      border-radius: 3px;
      font-family: 'Inter';
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 21px;
      align-items: center;
      text-align: center;
      letter-spacing: -0.01em;

      &:hover {
        background-color: var(--theme-color-4367fd);
      }
    }
  }
}

@media (max-width: 480px) {
  .existed-project-repo-selection-page-container {
    .page-body-container {
      width: 95%;
    }
    .section-divider {
      border: none;
      width: 0;
    }
    .footer-actions {
      margin: 0 40px;
    }
  }
}
