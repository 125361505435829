.available-repo-list {
  overflow-y: auto;
  height: auto;

  .repo-list-loading-wrapper {
    padding: 60px 0;
  }

  .repo-owner-select-all {
    display: flex;
    align-items: center;
    padding: 5px;

    > span {
      margin-left: 14px;
      font-size: 12px;
      line-height: 16px;
    }
  }

  .repo-owner-repo-list {
    padding-left: 24px;

    .repo-owner-repo-item {
      display: flex;
      padding: 5px 0;

      .ui.checkbox {
        display: flex;
        align-items: center;
        line-height: 16px;
      }

      > span {
        margin-left: 10px;
        padding: 0 2px;

        &:hover {
          background-color: var(--theme-color-1b232b);
          border-radius: 3px;
          cursor: pointer;
        }
      }

      i.icon {
        color: var(--theme-color-96a0b0);
        margin: 0;
      }
    }
  }
}

.loading-container {
  padding: 60px 0;
}

.no-repo-in-list {
  text-align: center;

  i {
    font-size: 48px;
  }

  .no-repo-title {
    font-size: 16px;
    font-weight: 600;
    line-height: 20px;
    margin-top: 12px;
    color: var(--theme-color-c9d1d9);
  }

  .no-repo-content {
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
    margin-top: 4px;
    color: var(--theme-color-788496);
  }

  .no-repo-button {
    margin-top: 16px;
  }

  .no-repo-footer {
    margin-top: 16px;
    color: var(--theme-color-788496);
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
  }
}
