div * {
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* IE 10+ */

  ::-webkit-scrollbar-track {
    -webkit-box-shadow: none !important;
    background-color: transparent;
  }

  ::-webkit-scrollbar {
    width: 6px !important;
    height: 6px !important;
    background-color: transparent;
  }

  ::-webkit-scrollbar-thumb {
    background-color: transparent;
  }
}

.show-scrollbar {
  scrollbar-width: thin; /* Firefox */
  -ms-overflow-style: none; /* IE 10+ */
}

.show-scrollbar::-webkit-scrollbar-track {
  -webkit-box-shadow: none !important;
  background-color: transparent !important;
}

.show-scrollbar::-webkit-scrollbar {
  width: 6px !important;
  height: 6px !important;
  background-color: transparent;
}

.show-scrollbar::-webkit-scrollbar-thumb {
  background-color: var(--theme-color-acacac);
}

/// END
