.dashboard-widget-card-container {
  .recharts-wrapper {
    cursor: default !important;
  }

  .other-contents {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  // This is to hide right down icon
  .ui.dropdown {
    > i {
      display: none;
    }

    .menu {
      margin-top: 6px;
    }
  }
}

.chart {
  display: flex;
  justify-content: center;
}
