.loading-container {
  padding: 60px 0;
}

.no-checkbox-in-list {
  text-align: center;
}

.pure-checkbox-list-and-list {
  // padding-left: 24px;
  max-height: 480px;
  overflow-y: auto;
  .checkbox-list-item {
    display: flex;
    justify-content: space-between;
    align-items: center;

    &:hover {
      background: linear-gradient(
        270deg,
        #161b22 0%,
        rgba(22, 27, 34, 0.64) 78.12%,
        rgba(22, 27, 34, 0) 96.97%,
        rgba(22, 27, 34, 0) 100%
      );
      border-radius: 6px;
    }

    > span {
      padding: 0 2px;

      &:hover {
        background-color: var(--theme-color-1b232b);
        border-radius: 3px;
        cursor: pointer;
      }
    }

    i.icon {
      color: var(--theme-color-96a0b0);
      margin: 0;
    }
  }
}
