@import 'assets/styles/size.scss';
@import 'assets/styles/font.scss';

.ui.modal {
  width: 63vw;

  .ss-modal-content {
    width: 100%;

    img {
      display: block;
      width: 100%;
      height: auto;
    }
  }
}

.test-case-screenshots-container {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  height: calc(100vh - 300px);
  overflow-y: auto;

  .screenshot-container {
    display: flex;
    justify-content: center;
    width: 275px;
    min-height: 184px;
    max-height: 210px;
    padding: 7px 20px;

    .screenshot-container-in {
      display: flex;
      flex-direction: column;
      width: 260px;
      height: 100%;

      justify-content: flex-start;
      align-items: center;

      img {
        display: block;
        max-width: 260px;
        max-height: 146px;
        width: auto;
        height: auto;
        cursor: pointer;
      }

      .info-part {
        margin-top: 8px;
        width: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: center;

        .right-part {
          font-style: normal;
          font-weight: 500;
          font-size: 12px;
          line-height: 16px;

          color: #96a0b0;
        }

        .left-part {
          font-size: 16px;
          margin-right: 8px;

          .icon-container {
            max-width: 12px;
            max-height: 12px;
            font-size: 12px;
          }
        }
      }
    }
  }
}

.no-ss-data-msg-container,
.error-ss-msg-container,
.loading-data-msg-container,
.ss-loading-msg-container {
  width: 100%;
  height: 100%;
  margin-top: 100px;
}

.ui.dimmer {
  background-color: rgba(106, 118, 137, 0.8);
}

@media (max-width: 480px) {
  .ui.modal {
    width: 100%;
  }
}
