.empty-state-container-highlights {
  max-width: 1200px;
  min-width: 1200px;
  margin: 0 auto;
  height: 100%;
}

.empty-state-highlights {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin: 0 auto;
  align-content: center;
}

.body-container-highlights {
  text-align: center;
  margin: 0 auto;
  align-content: center;
  display: flex;

  .empty-image-highlights {
    margin: 0 auto;
    align-content: center;
  }
  .body-text-title-highlights {
    margin-top: 8px;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    color: var(--theme-color-dcdfe5);
  }

  .body-text-container-highlights {
    margin-top: 16px;
    margin-bottom: 35px;
    .body-text-highlights {
      font-weight: normal;
      width: 450px;
      font-size: 14px;
      line-height: 20px;
      color: var(--theme-color-dcdfe5);
    }
  }

  .initial-and-project-connect-repositories-highlights {
    margin: var(--margin-top-var) 0 0 0;
  }
}
.connect-button-container-highlights {
  display: flex;
  font-style: normal;
  font-family: Inter;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.01em;
  margin-top: -3px;
}

.ui.primary.button {
  &:hover {
    background-color: var(--theme-color-4367fd);
  }
}

@media (max-width: 480px) {
  .empty-state-container-highlights {
    max-width: 100%;
    min-width: 100%;
    margin: 0 auto;
    height: 100%;
  }
}
