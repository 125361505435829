.horizontal-line {
  width: 48px;
  height: 2px;
  background: var(--theme-color-788496);
  margin-left: 4px;
  margin-right: 4px;

  &.active {
    background: var(--theme-color-b2baca);
  }
}

.display-block {
  display: inline-block;
}

.checkmark {
  display: inline-block;
  width: 22px;
  height: 22px;
  -ms-transform: rotate(45deg); /* IE 9 */
  -webkit-transform: rotate(45deg); /* Chrome, Safari, Opera */
  transform: rotate(45deg);
  vertical-align: middle;
}

.checkmark_circle {
  position: absolute;
  width: 22px;
  height: 22px;
  background-color: var(--theme-color-b2baca);
  border-radius: 11px;
  left: 0;
  top: 0;
}

.checkmark_stem {
  position: absolute;
  width: 2px;
  height: 9px;
  background-color: var(--theme-color-0e1216);
  left: 11px;
  top: 6px;
}

.checkmark_kick {
  position: absolute;
  width: 3px;
  height: 2px;
  background-color: var(--theme-color-0e1216);
  left: 8px;
  top: 13px;
}

.dot-icon {
  border-radius: 50%;
  height: 8px;
  width: 8px;
  background: var(--theme-color-b2baca);
  margin-bottom: -3px;
  margin-left: 4px;
  margin-right: 4px;
}
