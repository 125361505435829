.overview-title-container {
  display: flex;
  width: 50%;
  gap: 15px;
  align-items: center;

  .overview-title-main {
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    color: var(--theme-color-ffffff);
    max-width: 700px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .overview-title-extra-status {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .overview-extra-state-icon-style {
    font-size: 12px;
    margin-left: 12px;
    line-height: 24px;
  }
  .pr-view-icon {
    font-size: 24px;
  }
}
