@import 'common';
@import 'theme';
@import 'font';

html,
body {
  margin: 0;
  padding: 0;
  font-family: Inter;
  font-style: normal;
  color: var(--theme-color-d4d4d4);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  background-color: var(--theme-color-0e1216);

  .ui.vertical.accordion.menu {
    background-color: var(--theme-color-0e1216);
  }
}

* {
  background-repeat: no-repeat;
  box-sizing: border-box;
}

span,
div {
  &::selection {
    background: var(--theme-color-2d4967);
  }
}

a {
  color: var(--theme-color-5174ff);

  &:hover {
    color: var(--theme-color-5599ff);
    text-decoration: underline;
  }
}
