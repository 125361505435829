.list-cards-main-container-parent {
  border-collapse: collapse;
  td {
    border-bottom: thin solid #1b232b;
  }

  th {
    padding-bottom: 10px;
    padding-left: 10px;
    text-align: left;
  }

  .pr-view-list-cards-row-body {
    height: 120px;
    &:hover {
      cursor: pointer;
      background: #161b22;
    }

    .cell-container {
      min-width: 120px;
      padding: 10px;

      .sub-row {
        height: 24px;
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        line-height: 15px;
        letter-spacing: -0.005em;
        color: #b2baca;
        display: flex;
        align-items: center;
        gap: 5px;

        .icon-pullrequest {
          font-size: 14px;
        }

        .icon-icons-pr-open {
          font-size: 14px;
        }
        .icon-icons-calendar {
          font-size: 14px;
        }

        .pr-view-icon {
          font-size: 14px;
        }
      }
      .flex-row {
        display: flex;
      }

      .metadata-cell {
        .title {
          display: flex;
          max-width: 346px;
          height: 24px;
          gap: 8px;

          .title-text {
            font-family: 'Inter';
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            line-height: 20px;
            color: #dcdfe5;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }

          .mergable-state {
            margin-left: 6px;
          }
        }

        .text {
          display: flex;
          height: 24px;
          font-style: normal;
          font-weight: 500;
          font-size: 12px;
          line-height: 16px;
          letter-spacing: -0.005em;
          color: #5c697c;
          align-items: center;
        }

        .last-committer {
          display: flex;
          gap: 1px;
        }

        .strong-text {
          font-style: normal;
          font-weight: 600;
          font-size: 12px;
          line-height: 16px;
          letter-spacing: -0.005em;
          color: #788496;
          margin-left: 4px;
        }

        .section-dot {
          display: inline-block;
          border-radius: 50%;
          height: 6px;
          width: 6px;
          background: #5c697c;
          margin: 5px 13px 0 8px;
        }

        .ellipsis {
          align-items: center;
          padding: 2px 8px;
          width: 85px;
          height: 20px;
          background: rgba(110, 118, 129, 0.24);
          border-radius: 12px;
          margin-left: 3px;
          display: flex;
          justify-content: center;

          .ellipsis-text {
            font-style: normal;
            font-weight: 600;
            font-size: 12px;
            line-height: 16px;
            letter-spacing: -0.01em;
            color: #788496;
          }
        }
      }

      .pr-sum-status-item-header-cont {
        display: flex;
        flex-direction: column;
        gap: 8px;
        justify-content: center;
        align-items: flex-start;

        .pr-sum-status-item-header {
          font-family: 'Inter';
          font-style: normal;
          font-weight: 600;
          font-size: 12px;
          line-height: 16px;
          color: #788496;
        }

        .pr-summary-status-icon {
          display: flex;
          flex-direction: row;
          align-items: center;
          gap: 12px;
          .pr-summary-icon-text {
            font-family: 'Inter';
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            line-height: 20px;
            color: #dcdfe5;
          }
          .icon-icons-summary-cost {
            font-size: 40px;
            &::before {
              color: #5db95c;
            }
          }
          .icon-icons-summary-duration {
            font-size: 40px;
            &::before {
              color: #5fb1c6;
            }
          }
        }
      }
    }
  }

  .icon-info-circle-project-integration:before {
    color: #788496;
    font-size: 9px;
  }
}

#pull-requests-scrollable {
  height: calc(100% - 35px);
  overflow-y: auto;
}

.header-icon-style {
  margin-top: -3px;
  margin-left: 6px;
}

.ci-conclusion-icon-style {
  margin: 0 8px 2px 8px;
}

.failed-workflows {
  display: flex;
  .failed-workflows-icon {
    margin-right: 4px;
    padding-top: 2px;
  }
}

.four-items {
  padding-top: 26px !important;
}
