.mini-card-container {
  display: flex;
  gap: 4px;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 16px;
  width: 220px;
  height: auto;
  background: #1b232b;
  border-radius: 6px;

  .mini-card-title {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    display: flex;
    align-items: center;
    text-transform: uppercase;
    color: #5c697c;
  }

  .mini-card-title-not-uppercase {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    display: flex;
    align-items: center;
    color: #5c697c;
  }

  .fly-loading-container {
    .loading-lines {
      .load-base {
        width: 140px;
      }
    }
  }
}
