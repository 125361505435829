.highlights-page-first-row {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-bottom: 20px;
  width: 100%;
  gap: 0px;
}

.summaryOfLatest10Workflows {
  width: 840px;
}

.button-and-text-group-main-container {
  display: flex;
  justify-content: center;
  position: sticky;
  top: -16px;
  z-index: 1;
  background-color: var(--theme-color-0e1217);
}

.button-and-text-subgroup-container {
  width: 1200px;
  height: 47px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid var(--theme-color-1b232b);
}

.ui.buttons {
  > .ui.button {
    background-color: var(--theme-color-1b232b);
    color: #b2baca;
    transition: background-color 0.2s ease-in-out;
    font-size: 16px;
  }
  .ui.button:hover {
    background-color: var(--theme-color-1b232b);
    transition: background-color 0.2s ease-in-out;
  }
  .ui.button:focus {
    background-color: var(--theme-color-163dd4);
    color: #fff;
    transition: background-color 0.2s ease-in-out;
  }

  .ui.active.button {
    background-color: var(--theme-color-163dd4);
    color: #fff;
    transition: background-color 0.2s ease-in-out;
  }
}
.workflow-name-btgroup {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: var(--theme-color-dcdfe5);
}

#last24hours,
#sevenDays,
#last30Days {
  font-size: 12px;
}

.button-time-groups-container {
  margin-bottom: -13px;
}

.countOf30DaysWorkflows {
  width: 360px;
}

.title-of-no-data {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: -0.01em;
  color: #dcdfe5;
}

.main-header-hg {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  color: var(--theme-color-dcdfe5);
  width: 1200px;
}

.main-header-cont-hg {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
  width: 100%;
  grid-gap: 0px;
  grid-gap: 0px;
  gap: 0px;
}

.breadcrumb-menu-cont {
  width: 1200px;
}

@media (max-width: 480px) {
  .summaryOfLatest10Workflows {
    width: 100%;
  }
  .main-header-hg {
    width: 100%;
  }
  .highlights-page-first-row {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-bottom: 20px;
    width: 100%;
    gap: 0px;
  }
  .button-and-text-subgroup-container {
    display: flex;
    flex-direction: column;
    height: 125px;
    align-items: center;
    justify-content: center;
    position: sticky;
    top: -16px;
    z-index: 1;
    background-color: var(--theme-color-0e1217);
  }
}
