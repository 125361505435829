.benefits-container {
  &:hover {
    border-color: var(--theme-color-3459ef);
  }

  cursor: pointer;
  background-color: var(--theme-color-1c222a);
  margin-bottom: 32px;
  border: 1px solid;
  border-color: #010101;
  border-radius: 6px;

  .content-container {
    display: flex;
    align-items: center;
    padding: 18px 32px 14px 14px;
    user-select: none;

    .hand-bulb-icon {
      width: 40px;
      margin-right: 32px;
    }

    .header-content-container {
      text-align: left;
      min-width: 800px;
      .header {
        font-size: 20px;
        font-weight: 600;
        line-height: 24px;
        text-align: left;
        color: var(--theme-color-ffffff);
      }

      .content {
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        color: var(--theme-color-b2baca);
      }
    }

    i.right-button {
      font-size: 24px;
    }
  }

  .benefit-item-container-with-icon {
    display: flex;
    margin: 0 56px 0 70px;

    .benefit-item-container {
      display: inline-block;
      width: 100%;

      .benefit-item-title {
        margin-top: 14px;
        color: var(--theme-color-ffffff);
        font-size: 14px;
        font-weight: 600;
        line-height: 20px;
        text-align: left;
      }

      .benefit-item-content {
        color: var(--theme-color-96a0b0);
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        text-align: left;
        margin-bottom: 24px;
      }
    }

    img {
      width: 80px !important;
    }
  }
}
