.credit-card-form {
  * {
    margin: unset !important;
  }

  & > .field,
  & > .fields {
    padding-left: 0.5em !important;
    margin-bottom: 24px !important;
  }

  .stripe-input {
    border-radius: 3px;
    padding: 10px 8px;
    font-size: 14px;
    line-height: 20px;
    font-weight: 400;
    height: 40px !important;
    color: var(--theme-color-ffffff) !important;

    background-color: var(--theme-color-151a20) !important;
    border: 2px solid var(--theme-color-2f353d) !important;
    border-radius: 3px;

    &:hover {
      background-color: var(--theme-color-323232);
    }
  }

  .ui.divider {
    margin-top: 25px !important;
    margin-bottom: 25px !important;
  }
  label {
    font-style: normal !important;
    font-weight: 600 !important;
    font-size: 12px !important;
    line-height: 16px !important;
    align-items: center !important;
    letter-spacing: -0.01em !important;
    color: var(--theme-color-8792a3) !important;
    padding-bottom: 4px;
  }
}
