.workflow-run-detail-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
  padding: 32px;

  .workflow-run-detail-title {
    margin-top: 28px;
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: baseline;

    .title-text {
      font-style: normal;
      font-weight: 600;
      font-size: 20px;
      line-height: 24px;
      display: flex;
      align-items: center;
      letter-spacing: -0.04em;
      color: var(--theme-color-dcdfe5);
    }

    .title-icon {
      font-weight: 600;
      font-size: 24px;
      color: #b2baca;
      width: 40px;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;

      &:hover {
        cursor: pointer;
        border-radius: 20px;
        background: var(--theme-color-rgba_220_223_229_008);
      }
    }
  }

  .coverage-info-container {
    margin-top: 16px;
    margin-bottom: 16px;
    display: flex;
    align-items: center;
    width: 100%;
    padding: 12px;
    background: var(--theme-color-222b35);
    border-radius: 3px;

    i {
      font-size: 16px;
      margin-right: 12px;
    }

    span {
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: var(--theme-color-b2baca);
    }
  }

  .workflow-run-detail-body {
    display: flex;
    width: 100%;

    .workflow-run-detail-body-left {
      display: flex;
      flex-direction: column;
      max-width: 574px;
      width: 574px;
      height: 100%;

      .filename-coverage-container {
        display: flex;
        flex-direction: column;
        width: 100%;

        .filename-coverage-row-header {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: space-between;
          width: 100%;
          margin-bottom: 8px;
          padding: 10px;

          span {
            font-style: normal;
            font-weight: 600;
            font-size: 12px;
            line-height: 16px;

            display: flex;
            align-items: center;
            letter-spacing: -0.04em;

            /* Light/L50 */

            color: var(--theme-color-78849);
          }
        }

        .filename-coverage-row {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: space-between;
          width: 100%;
          padding: 10px;

          span {
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 20px;
            letter-spacing: -0.01em;
            color: var(--theme-color-b2baca);
          }

          .filename-coverage-row-right-part {
            display: flex;
            justify-content: flex-end;
            align-items: center;

            i {
              margin-left: 12px;
              font-size: 12px;
              visibility: hidden;
            }
          }

          &:hover,
          &.selected {
            cursor: pointer;
            background: var(--theme-color-21262d);
            border-radius: 3px;

            i {
              visibility: visible;
            }
          }
        }
      }
    }

    .workflow-run-detail-body-right {
      width: calc(100% - 574px);
      height: 100%;
      display: flex;
      flex-direction: column;
      margin-left: 16px;

      .code-coverage-row-header {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        padding: 2px 10px 10px 10px;

        .code-coverage-row-header-right {
          display: flex;
          justify-content: flex-end;
          align-items: center;

          .uncovered-icon {
            width: 24px;
            height: 16px;
            background: var(--theme-color-rgba_220_223_229_04);
            display: flex;
            justify-content: center;
            margin: 8px;
          }

          .covered-icon {
            width: 24px;
            height: 16px;
            background: var(--theme-color-rgba_46_160_67_02);
            display: flex;
            justify-content: center;
            margin: 8px;
          }

          .collapse-right-icon {
            color: var(--theme-color-788496);
            margin-left: 32px;
            display: flex;
            justify-content: center;
            cursor: pointer;

            &:hover {
              path {
                fill: var(--theme-color-b2baca);
              }
            }
          }
        }

        span {
          font-style: normal;
          font-weight: 600;
          font-size: 12px;
          line-height: 16px;
          display: flex;
          align-items: center;
          letter-spacing: -0.04em;
          color: var(--theme-color-788496);
        }
      }

      .code-coverage-editors-container {
        display: flex;
        flex-direction: column;
        background-color: var(--theme-color-0e1216);
        padding: 24px;
        overflow-y: auto;
        height: calc(100vh - 250px);

        .more-code-placeholder-container {
          display: flex;
          height: 24px;
          margin: 24px 12px;
        }
      }
    }
  }
}
