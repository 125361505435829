a.ALink {
  color: var(--theme-color-1e90ff);

  &:hover {
    cursor: pointer;
    color: var(--theme-color-73baff);
  }
}

a.ButtonLink {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 6px 12px;
  height: 32px;
  background: #1b232b;
  border-radius: 3px;

  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.01em;
  color: #b2baca;

  &:hover {
    background-color: var(--theme-color-222b35);
    text-decoration: none;
  }
}
