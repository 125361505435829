.checkbox-list-component {
  overflow-y: auto;
  height: 100%;

  .fly-loading-container {
    height: 100px;
  }

  .repo-list-loading-wrapper {
    padding: 60px 0;
  }

  .checkbox-list-select-all {
    display: flex;
    align-items: center;
    margin-top: 12px;

    > span {
      margin-left: 14px;
      font-size: 12px;
      line-height: 16px;
    }

    .ui.indeterminate.checkbox.foresight-checkbox {
      label {
        &::after {
          top: 0;
        }
      }
    }
    .foresight-checkbox-container > .foresight-checkbox.ui.checkbox > label {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      max-width: 240px;
      color: #788496;
    }
  }

  .checklist-header {
    font-size: 10px;
    font-weight: 600;
    margin-top: 8px;
    margin-bottom: 5px;
    color: var(--theme-color-6a7689);
    text-transform: uppercase;
  }

  .repo-owner-repo-list {
    max-height: 240px;
    overflow-y: auto;

    .repo-owner-repo-item {
      display: flex;

      > span {
        margin-left: 10px;
        padding: 0 2px;

        &:hover {
          background-color: var(--theme-color-1b232b);
          border-radius: 3px;
          cursor: pointer;
        }
      }

      i.icon {
        color: var(--theme-color-96a0b0);
        margin: 0;
      }
    }
  }
  .checkbox-list-and-list {
    overflow-y: auto;
  }
}

.loading-container {
  padding: 60px 0;
}

.no-checkbox-in-list {
  text-align: center;
}

@media only screen and (min-width: 1450px) {
  .checkbox-tree-data-list {
    overflow-y: auto;
    height: 100%;

    .fly-loading-container {
      height: 100px;
    }

    .repo-list-loading-wrapper {
      padding: 60px 0;
    }

    .checkbox-list-select-all {
      display: flex;
      align-items: center;
      margin-top: 6px;

      > span {
        margin-left: 14px;
        font-size: 12px;
        line-height: 16px;
      }
    }

    .checkbox-list-and-list {
      padding-left: 24px;
      max-height: 480px;
      overflow-y: auto;
      .checkbox-list-item {
        display: flex;

        > span {
          padding: 0 2px;

          &:hover {
            background-color: var(--theme-color-1b232b);
            border-radius: 3px;
            cursor: pointer;
          }
        }

        i.icon {
          color: var(--theme-color-96a0b0);
          margin: 0;
        }
      }
    }
  }
}
