.card-list-panel-container {
  margin-top: 24px;
  height: 90px;
  display: flex;

  .panel-box-container {
    width: 50%;
    display: inline-block;
    min-height: 100px;

    &:not(:first-child) {
      padding-left: 20px;
    }

    .panel-box {
      border-radius: 6px;
      height: 100%;
      background-color: var(--theme-color-1b232b);
      padding: 20px 32px;

      .flex-container {
        display: flex;
        justify-content: space-between;
      }

      .title {
        font-weight: 600;
        font-size: 12px;
        line-height: 16px;
        color: var(--theme-color-96a0b0);
      }

      .content {
        color: var(--theme-color-dcdfe5);
        font-weight: 600;
        font-size: 16px;
        line-height: 20px;
        margin-top: 4px;

        .test-overall-overview-container {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          padding: 0px;
          background: #1b232b;
          border-radius: 6px;
          flex: none;
          order: 0;
          flex-grow: 1;

          .first-row {
            display: flex;
            width: 100%;

            .title {
              font-style: normal;
              font-weight: 600;
              font-size: 16px;
              line-height: 20px;
              display: flex;
              align-items: center;
              letter-spacing: -0.01em;
              color: #dcdfe5;
            }
          }

          .second-row {
            display: flex;
            width: 100%;
            justify-content: space-between;

            .summary {
              display: flex;

              .icon {
                font-size: 13px;
              }

              .success-test-summary {
                padding: 2px 0px;
                .success-test-count {
                  font-style: normal;
                  font-weight: 600;
                  font-size: 16px;
                  line-height: 24px;
                  letter-spacing: -0.02em;
                  color: #dcdfe5;
                }
              }

              .failed-test-summary {
                margin-left: 48px;
                padding: 2px 0px;

                .success-test-count {
                  font-style: normal;
                  font-weight: 600;
                  font-size: 16px;
                  line-height: 24px;
                  letter-spacing: -0.02em;
                  color: #dcdfe5;
                }
              }
            }

            .empty-info-container {
              .empty-info {
                margin-top: 6px;
                font-style: normal;
                font-weight: 500;
                font-size: 10px;
                line-height: 14px;
                letter-spacing: -0.005em;
                color: #788496;
              }

              .integrate-now-btn {
                margin: 10px 0px;
              }
            }

            .svg-img-container {
              display: flex;
              align-items: center;
              justify-content: center;
              margin: auto 35px;
            }

            .doc-info-container {
              font-style: normal;
              font-weight: 500;
              font-size: 10px;
              line-height: 14px;
              letter-spacing: -0.005em;
              color: #6a7689;
              a {
                margin-left: 3px;
                color: #1e90ff;

                &:hover {
                  text-decoration: underline;
                }
              }
            }
          }

          .third-row {
            display: flex;
            width: 100%;
            gap: 8px;

            .tag {
              display: flex;
              flex-direction: column;
              align-items: flex-start;
              padding: 2px 5px;
              height: 20px;

              /* Dark/Stroke */

              border: 1px solid #2f353d;
              border-radius: 3px;

              font-style: normal;
              font-weight: 500;
              font-size: 12px;
              line-height: 16px;
              /* identical to box height, or 133% */

              letter-spacing: -0.005em;

              /* Gray/G200 */

              color: #6a7689;
            }
          }
        }

        .tooltipContext {
          color: var(--theme-color-dcdfe5);
          font-weight: 600;
          font-size: 16px;
          line-height: 20px;
          margin-top: 4px;
          width: 350px;
          white-space: nowrap !important;
          overflow: hidden !important;
          text-overflow: ellipsis !important;
        }

        span {
          font-size: 12px;
          line-height: 16px;
          font-weight: 600;
        }
      }

      .link-title {
        color: var(--theme-color-1e90ff);
        font-weight: 500;
        font-size: 12px;
        margin-top: 4px;
        line-height: 16px;

        &:hover {
          cursor: pointer;
          text-decoration: underline;
        }
      }

      .duration-extra {
        color: var(--theme-color-76d671);
        font-weight: 500;
        font-size: 12px;
        margin-top: 4px;
        line-height: 16px;
      }
    }
  }
}

@media (max-width: 480px) {
  .card-list-panel-container {
    flex-direction: column;
    height: 100%;
    width: 100%;
    gap: 30px;
    .panel-box-container {
      width: 100%;
      display: block;
      min-height: 100px;

      &:not(:first-child) {
        padding-left: 0;
      }
    }
  }
}
