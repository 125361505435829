.test-runs-page-header {
  margin-bottom: 32px;
  font-weight: 600;
  font-size: 20px;
  color: var(--theme-color-ffffff);
  line-height: 24px;
}

.header-block {
  display: inline-block;

  &.align-right {
    float: right;
    padding-right: 12px;
  }
}

.page-updated-time {
  color: var(--theme-color-rgba_77_147_164_1);
  font-weight: 500;
}

.testruns-page-wrapper {
  display: flex;
  flex-direction: column;
  background-color: var(--theme-color-0e1216);
  width: 100%;
  height: calc(100vh - 200px);
  .repository-title-container {
    display: flex;
    align-items: center;
    min-height: 32px;

    div {
      color: var(--theme-color-dcdfe5);
      font-style: normal;
      font-weight: 500;
      font-size: 20px;
      line-height: 24px;
      letter-spacing: -0.04em;

      display: flex;
      align-items: center;
      height: 100%;
    }

    .title {
      margin-right: 24px;
    }

    .setting {
      width: 32px;
      height: 32px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 3px;
      cursor: pointer;
      color: var(--theme-color-b2baca);

      svg {
        font-size: 12px !important;
      }

      &.pressed {
        background-color: var(--theme-color-b2baca);
        color: var(--theme-color-09152f);
      }

      &:hover {
        background-color: var(--theme-color-202b36);

        &.pressed {
          background-color: var(--theme-color-b2baca);
          color: var(--theme-color-09152f);
        }
      }
    }
  }
  .content {
    display: flex;
    flex-direction: row;
    width: 100%;

    .content-left {
      width: 280px;
      height: 100%;
      padding-right: 40px;
    }

    .content-right {
      width: calc(100% - 280px);
      height: 100%;
    }

    #testruns-scrollable {
      overflow-y: auto;
    }
  }
}
