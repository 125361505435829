.summary-container {
  width: 100%;

  .content {
    .table-and-title-container {
      display: flex;
      flex-direction: column;
      width: 100%;
      height: 100%;

      .title {
        font-style: normal;
        font-weight: bold;
        font-size: 12px;
        line-height: 16px;
        display: flex;
        align-items: center;
        color: var(--theme-color-5c697c);
        margin-bottom: 12px;
      }

      .stats-table {
        width: 100%;
        height: 152px;
        background: var(--theme-color-1c222a);
        border-radius: 3px;
        padding: 8px 16px;

        &.one-row {
          height: 60px;
          max-height: 60px;
          padding-top: 20px;
        }

        .block-summary {
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
        }

        .block {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: flex-start;

          .title {
            font-style: normal;
            font-weight: 500;
            font-size: 11px;
            line-height: 14px;
            padding: 2px 2px;
            color: var(--theme-color-788496);
          }

          .value {
            display: flex;
            align-items: baseline;

            font-style: normal;
            font-weight: 500;
            font-size: 18px;
            line-height: 16px;
            color: var(--theme-color-b2baca);
            
            

            i {
              font-size: 16px;
              margin-right: 3px;
            }
          }
        }
      }
    }
  }
}
