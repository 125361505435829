.increase-msg-plan-container {
  border: 1px solid var(--theme-color-76d671);
  border-radius: 5px;
  padding: 15px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  background-color: var(--theme-color-1c222a);
  width: 100%;

  .increase-msg-plan-header {
    display: flex;
    align-items: center;
    gap: 10px;
    .increase-msg-plan-header-title {
      font-family: 'Inter';
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 20px;
      color: var(--theme-color-dcdfe5);
    }
    .icon-circle-info {
      font-size: 24px;
      &::before {
        color: var(--theme-color-5174ff);
      }
    }
  }
  .increase-msg-plan-information-text {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    margin-left: 35px;
    color: var(--theme-color-788496);

    .highlight {
      color: var(--theme-color-1e90ff);
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      margin-left: 5px;
    }

    .break-line {
      margin-top: 8px;
    }
  }
}
