.foresight-checkbox-container {
  display: flex;
  padding: 5px 0;

  .ui.checkbox {
    display: flex;
    align-items: center;
    line-height: 16px;
    label {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      max-width: 285px;
      font-family: 'Inter';
      font-size: 12px;
    }
  }

  .ui.disabled.checkbox .box:after,
  .ui.disabled.checkbox label,
  .ui.checkbox input[disabled] ~ .box:after,
  .ui.checkbox input[disabled] ~ label {
    cursor: not-allowed !important;
    opacity: 0.5;
    color: rgb(163 152 152);
  }

  .ui.checkbox input:checked:focus ~ label:before {
    border-color: transparent !important;
  }
  .ui.checkbox input:focus ~ label:before {
    border: 2px solid #2f353d;
  }

  .checkbox-icon {
    display: flex;
    margin-left: 5px;
    align-items: center;
    & > i {
      font-size: 16px;
    }
  }

  > span {
    margin-left: 10px;
    padding: 0 2px;

    &:hover {
      background-color: var(--theme-color-1b232b);
      border-radius: 3px;
      cursor: pointer;
    }
  }

  i.icon {
    color: var(--theme-color-96a0b0);
    margin: 0;
  }
}
