.search-component-container {
  display: flex;
  gap: 10px;
  height: 32px;
  .input-section-search {
    display: flex;
    align-items: center;

    .ui.input.foresight-input {
      width: 360px;
      height: 32px;
    }
  }
  .ui.icon.input.foresight-calendar-component {
    width: 320px;
  }
  .button-section {
    display: flex;
    align-items: center;
    gap: 20px;
    .vertical-line {
      width: 0px;
      height: 24px;
      border: 1px solid #2f353d;
    }
    .clear-button {
      font-family: 'Inter';
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      color: #96a0b0;
      &:hover {
        cursor: pointer;
        filter: brightness(1.3);
      }
    }
  }
}
