@import 'assets/styles/size.scss';

.landing-page {
  height: 100%;
  min-height: 640px;

  .layout-main-container {
    max-width: 1200px;
    margin: auto;
    padding-top: 40px;
  }
}

.from-aws-signup-note {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--theme-color-eae6ff);
  height: 32px;
  color: var(--theme-color-091e42);
  font-size: 16px;
  font-weight: 500;
}

.merge {
  flex-direction: column;
}

.landing-page-wrapper {
  display: flex;
  height: 100%;
  width: 100%;
  overflow: auto;
}

.landing-page-left-container {
  background-color: var(--theme-color-0e1216);
}

.thundra-logo {
  max-width: 1200px;
  margin: 40px auto 0 auto;
  .image {
    width: 180px;
    height: 40px;
  }
}

.landing-page-right-container {
  flex: 10;
  display: flex;
  height: 100%;
  width: 100%;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;

  .action-rect {
    display: flex;
    width: 100%;
    height: 100%;
    min-height: 400px;
  }

  .certificates {
    display: flex;
    width: 100%;
    margin-top: auto;
    height: 150px;
    justify-content: center;
    align-items: center;

    .image {
      &.aws {
        height: 110px;
        margin: 0 12px;
      }

      &.gdpr {
        margin: 0 12px;
        width: 80;
        height: 80;
        border-radius: 40px;
        background: white;
      }
    }
  }

  .tos-copyright {
    text-align: center;
    width: 100%;
    color: var(--theme-color-d4d4d4);
    font-size: 10px;
    font-weight: 500;

    a {
      color: unset;
    }

    .interpunct-span {
      padding: 0 12px;
    }
  }
}

@media (max-width: 900px) {
  .landing-page-left-container {
    display: none;
  }
}

.mp-mode {
  margin-left: 180px;
}

.github-mp-content {
  margin-top: 72px;
  .github-mp-desc-logo {
    width: 368px;
    height: 88px;
    margin-top: -52px;
  }

  .description-bold-text {
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
    letter-spacing: -0.02em;
    color: var(--theme-color-c9d1d9);
  }

  .description-body-text {
    margin-top: 12px;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    align-items: center;
    letter-spacing: -0.01em;
    color: var(--theme-color-788496);
    width: 480px;
  }

  .docs {
    margin-top: 8px;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: -0.01em;
    color: var(--theme-color-1d43dd);
    float: left;
  }
}
