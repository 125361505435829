.failed-test-card-container {
  margin-top: 24px;
  /* Dark/D80 */

  border: 1px solid var(--theme-color-222b35);
  box-sizing: border-box;
  border-radius: 3px;

  .title {
    width: 100%;
    background: var(--theme-color-1c222a);
    border-radius: 3px 3px 0px 0px;
    padding: 8px 16px;

    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    display: flex;
    align-items: center;
    color: var(--theme-color-b2baca);
  }

  .content {
    .list-items {
      margin: 0px 0px;
      display: flex;
      flex-direction: column;
      width: 100%;
      height: 100%;

      .all-test-passed-container {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;

        .centered-container {
          width: 200px;
          height: 96px;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;

          span {
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 20px;
            /* identical to box height, or 143% */

            display: flex;
            align-items: center;

            /* Gray/G300 */

            color: var(--theme-color-5c697c);
          }

          i {
            font-size: 24px;
            color: var(--theme-color-5c697c);
          }
        }
      }

      .item-wrapper {
        &:hover {
          text-decoration: none;
        }

        .item {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
          cursor: pointer;
          padding: 8px 20px;
          background-color: transparent;
          border-bottom: 1px solid var(--theme-color-1b232b);

          &:hover {
            background-color: var(--theme-color-1c222a);
          }

          &:last-child {
            border-bottom: 1px solid transparent;
          }

          .left-part {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: baseline;

            .status {
              padding-right: 12px;

              i {
                font-size: 14px;
              }

              .successful {
                color: var(--theme-color-2dcd8a);
              }

              .failed {
                color: var(--theme-color-ff5630);
              }

              .skipped {
                color: var(--theme-color-ff5630);
              }

              .aborted {
                color: var(--theme-color-ff5630);
              }
            }

            .item-title {
              .name {
                font-style: normal;
                font-weight: 500;
                font-size: 14px;
                line-height: 20px;
                color: var(--theme-color-96a0b0);
              }

              .value {
                font-style: normal;
                font-weight: 500;
                font-size: 11px;
                line-height: 16px;
                color: var(--theme-color-5c697c);

                .duration {
                  display: flex;
                  align-items: center;

                  span {
                    font-style: normal;
                    font-weight: normal;
                    font-size: 11px;
                    line-height: 16px;
                    color: var(--theme-color-5c697c);
                    margin-left: 6px;
                  }

                  .suite-name-in-testcase {
                    &:hover {
                      text-decoration: underline;
                    }
                  }

                  i {
                    font-size: 12px;
                    color: var(--theme-color-5c697c);
                  }

                  svg {
                    margin-left: 8px;
                    color: var(--theme-color-5c697c);
                    font-size: 3px;
                  }
                }

                .error-count {
                  span {
                    font-style: normal;
                    font-weight: normal;
                    font-size: 10px;
                    line-height: 16px;
                    color: var(--theme-color-5c697c);
                    margin-left: 0px;
                  }

                  i {
                    font-size: 9px;
                    color: var(--theme-color-5c697c);
                  }
                }
              }
            }
          }

          .right-part {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            padding: 8px;

            i {
              color: var(--theme-color-788496);
              font-size: 14px;
            }
          }
        }
      }
    }
  }
}
