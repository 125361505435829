.wf-create-label-panel {
  .action-buttons-container {
    display: flex;
    justify-content: flex-end;
    margin-top: 16px;
  }

  .error-input-message {
    color: var(--theme-color-ff5630);

    i {
      margin-right: 2px;
    }
  }
}
