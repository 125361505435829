.reviewers-count {
  align-items: center;
  width: 24px;
  height: 20px;
  background: rgba(110, 118, 129, 0.24);
  display: inline-flex;
  border-radius: 12px;
  text-align: center;
  padding-top: 1px;
  justify-content: center;

  .reviewers-count-text {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: -0.01em;
    text-align: center;
    vertical-align: middle;
    vertical-align: middle;
    color: #788496;
  }
}

.requsted-reviewers-tooltip-conent {
  display: flex;
  margin-bottom: 4px;
  .avatar-image {
    border-radius: 50%;
    width: 24px;
    height: 24px;
  }
  .text-wrapper {
    margin-left: 8px;
    padding-top: 10px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: -0.005em;
    color: #dcdfe5;
  }
}
