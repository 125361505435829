.test-run-filter-main-container {
  .workflow-runs-filter-head-container {
    display: flex;
    border-bottom: 1px solid var(--theme-color-2f353d);
    padding: 0 0 10px 0;

    .filter-by-title {
      flex-grow: 1;
      font-family: Inter;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 24px;
      display: flex;
      align-items: center;
      letter-spacing: -0.01em;
      color: var(--theme-color-788496);
    }
    .filter-by-checkbox {
      flex-grow: 0;
      margin-right: 10px;
    }
  }
}

.filter-group-buttons {
  .branches-box {
    max-height: 165px;
    overflow-y: auto;
  }

  .scroll-shadow {
    background: linear-gradient(180deg, var(--theme-color-rgba_14_18_22_0) 0%, var(--theme-color-0e1216) 100%);
    position: absolute;
    left: 0;
    right: 0;
    bottom: 10px;
    height: 10px;
    pointer-events: none;
  }

  .ui.checkbox {
    height: 16px;
    margin-bottom: 4px;
    margin-top: 4px;
    width: 228px;

    input {
      font-size: 12px;
      height: 14px;
      width: 14px;
      border: 2px solid var(--theme-color-2f353d);
      border-radius: 3px;

      &:hover {
        background-color: var(--theme-color-202328);
        border: 2px solid var(--theme-color-2f353d);
      }
    }

    label {
      font-size: 12px;
      padding-left: 22px;
      color: var(--theme-color-dcdfe5);
      font-weight: 500;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;

      &:focus,
      &:active,
      &:hover {
        color: var(--theme-color-dcdfe5);
      }
    }
  }
}

.branch-filter-input {
  width: 225px;

  input {
    &::-webkit-input-placeholder {
      color: var(--theme-color-5c697c) !important;
      font-weight: 500;
    }

    &:-moz-placeholder {
      color: var(--theme-color-5c697c) !important;
      font-weight: 500;
    }

    &::-moz-placeholder {
      color: var(--theme-color-5c697c) !important;
      font-weight: 500;
    }

    &:-ms-input-placeholder {
      color: var(--theme-color-5c697c) !important;
      font-weight: 500;
    }

    background-color: var(--theme-color-070a0c) !important;
    color: var(--theme-color-ffffff) !important;
    border: 1px solid var(--theme-color-2f353d) !important;

    &:focus {
      border: 1px solid var(--theme-color-254be2) !important;
    }
  }
}

.branch-label {
  font-size: 9px;
  font-weight: 600;
  margin-top: 8px;
  margin-bottom: 5px;
  color: var(--theme-color-6a7689);
}

.ui.basic.buttons .button.selected-filter {
  border: 1px solid var(--theme-color-222b35);
  border-radius: 3px;

  &.ui.button {
    background: var(--theme-color-222b35) !important;
  }
}

.ui.vertical.accordion.menu {
  .accordion-content-container {
    margin-top: 6px;
  }

  .item.border-box {
    border-top: 1px solid var(--theme-color-2f353d);
    border-bottom: 1px solid var(--theme-color-2f353d);
    padding: 11px 0 15px 0;
    border-radius: 0px;
  }
}

@media (max-width: 480px) {
  .ui.vertical.accordion.menu {
    width: 100%;
    .accordion-content-container {
      margin-top: 6px;
    }

    .item.border-box {
      border-top: 1px solid var(--theme-color-2f353d);
      border-bottom: 1px solid var(--theme-color-2f353d);
      padding: 11px 0 15px 0;
      border-radius: 0px;
      width: 100%;
    }
  }
}
