.overall-avg-duration-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  .part {
    margin: 3px;
  }
}

.avg-duration-list-table {
  width: 100%;
  padding: 12px 0;
  margin-bottom: 16px;

  .header-workflow-id {
    width: 48px;
  }

  .header-workflow-name {
    width: 556px;
  }

  .header-workflow-duration {
    width: 130px;
  }

  .header-workflow-name,
  .header-workflow-duration {
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    /* identical to box height, or 133% */

    text-align: right;
    letter-spacing: -0.005em;

    /* Blue/B40 */

    color: var(--theme-color-5599ff);
  }

  .workflow-id {
    display: flex;
    align-items: center;
    justify-content: space-evenly;

    .workflow-id-color {
      display: flex;
      border-radius: 3px;
      width: 12px;
      height: 12px;
      justify-content: center;
      align-items: center;
      margin-left: 8px;
    }
  }

  .workflow-name {
    display: flex;
    align-items: center;
  }

  .workflow-stat {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  .workflow-list-cell-text {
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: -0.005em;
  }
}

.avg-duration-list-widget-row {
  color: var(--theme-color-96a0b0);
}

.avg-duration-list-widget-row:hover {
  cursor: pointer;
  padding: 0px 16px 0px 8px;
  gap: 8px;
  background: var(--theme-color-rgba_255_255_255_012);
  color: var(--theme-color-ffffff);
}

.active-line {
  background-color: var(--theme-color-rgba_255_255_255_012);
  transition: all 0.2s ease-in-out;
}
