.checkbox-tree-data-list {
  overflow-y: auto;
  height: 100%;

  .fly-loading-container {
    height: 100px;
  }

  .repo-list-loading-wrapper {
    padding: 60px 0;
  }

  .repo-owner-select-all {
    display: flex;
    align-items: center;
    padding: 0;

    > span {
      margin-left: 14px;
      font-size: 12px;
      line-height: 16px;
    }
  }

  .repo-owner-repo-list {
    padding-left: 56px;
    max-height: 240px;
    overflow-y: auto;
    .repo-owner-repo-item-not-visible {
      display: none;
    }
    .foresight-checkbox-container {
      padding: 0 !important;
    }
    .repo-owner-repo-item {
      display: flex;
      padding: 0 0;

      > span {
        margin-left: 10px;
        padding: 0 2px;

        &:hover {
          background-color: var(--theme-color-1b232b);
          border-radius: 3px;
          cursor: pointer;
        }
      }

      i.icon {
        color: var(--theme-color-96a0b0);
        margin: 0;
      }
    }
  }

  .ui.checkbox .box:before,
  .ui.checkbox label:before {
    top: 1.7px;
  }

  .ui.checkbox input:not([type='radio']):indeterminate ~ .box:after,
  .ui.checkbox input:not([type='radio']):indeterminate ~ label:after {
    top: 1.7px;
  }

  .ui.checkbox input:checked ~ .box:after,
  .ui.checkbox input:checked ~ label:after {
    top: 1px;
  }
}

.loading-container {
  padding: 60px 0;
}

.no-repo-in-list {
  text-align: center;

  i {
    font-size: 48px;
  }

  .no-repo-title {
    font-size: 16px;
    font-weight: 600;
    line-height: 20px;
    margin-top: 12px;
    color: var(--theme-color-c9d1d9);
  }

  .no-repo-content {
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
    margin-top: 4px;
    color: var(--theme-color-788496);
  }

  .no-repo-button {
    margin-top: 16px;
  }

  .no-repo-footer {
    margin-top: 16px;
    color: var(--theme-color-788496);
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
  }
}

@media only screen and (min-width: 1450px) {
  .checkbox-tree-data-list {
    overflow-y: auto;
    height: 100%;

    .fly-loading-container {
      height: 100px;
    }

    .repo-list-loading-wrapper {
      padding: 60px 0;
    }

    .repo-owner-select-all {
      display: flex;
      align-items: center;
      padding: 5px;

      > span {
        margin-left: 14px;
        font-size: 12px;
        line-height: 16px;
      }
    }

    .repo-owner-repo-list {
      padding-left: 56px;
      max-height: 480px;
      overflow-y: auto;
      .repo-owner-repo-item-not-visible {
        display: none;
      }
      .repo-owner-repo-item {
        display: flex;
        padding: 2px 0;

        > span {
          margin-left: 10px;
          padding: 0 2px;

          &:hover {
            background-color: var(--theme-color-1b232b);
            border-radius: 3px;
            cursor: pointer;
          }
        }

        i.icon {
          color: var(--theme-color-96a0b0);
          margin: 0;
        }
      }
    }
    label {
      margin-bottom: 5px;
    }

    .label {
      margin-bottom: 5px;
    }
  }
}

.collapsable-title {
  display: flex;
  align-items: center;
}

.collapsable-icon-wrapper {
  height: 24px;
  width: 24px;
  padding-bottom: 4px;
  display: flex;
  align-items: center;

  .collapsable-icon {
    color: #788496;
  }

  #icon-resize {
    font-size: 16px;
  }
}
