@import 'assets/styles/size.scss';

.error-stack-viewer-wrapper {
  background-color: var(--theme-color-070a0c);
  width: 100%;
  height: calc(100vh - 320px); //320 top fixed part of Logs Page
  padding: 17px 16px;
  position: relative;
  
  .clipboard-icon-wrapper {
    position: absolute;
    top: 0px;
    right: 0px;


    width: 30px;
    color: white;
    z-index: 1;


    .copy-clipboard-wrapper {
      padding-left: 7px;
      padding-right: 10px;
      cursor: pointer;
      right: 13px;
      background-color: var(--theme-color-070a0c);
    }
  }

}


