.main-pricing-container-wrapper {
  display: flex;
  flex-direction: column;
  gap: 24px;

  .plan-container {
    display: flex;
    flex-direction: column;
    .plan-header {
      display: flex;
      flex-direction: column;
      gap: 15px;
    }

    .plan-summary-section-container {
      display: flex;
      gap: 32px;
      .plan-summary-part-one {
        width: 70%;
        background-color: var(--theme-color-1c222a);
        height: 216px;
        border-radius: 6px;

        .plan-summary-section-header-container {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 12px 24px;

          .plan-summary-section-header-left {
            font-family: 'Inter';
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            line-height: 20px;
            color: var(--theme-color-dcdfe5);
          }

          .plan-summary-section-header-right {
            a {
              &:hover {
                text-decoration: none;
              }
            }
            .ui.primary.button {
              margin: 0px;
              width: 120px;
              height: 32px;
              background: #1d43dd;
              border-radius: 3px;
              font-family: 'Inter';
              font-style: normal;
              font-weight: 500;
              font-size: 14px;
              line-height: 20px;
              color: var(--theme-color-ffffff);
              display: flex;
              align-items: center;
              text-align: center;
              justify-content: center;
              &:hover {
                filter: brightness(1.3);
              }
            }
          }
        }
      }
      .plan-summary-part-two {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: 30%;
        background-color: var(--theme-color-1c222a);
        height: 216px;
        border-radius: 6px;
        padding: 16px 24px;

        .payment-method-container {
          display: flex;
          flex-direction: column;
          gap: 18px;

          .payment-method-header {
            display: flex;
            align-items: center;
            gap: 8px;
            .icon-Credit-card {
              font-size: 16px;
            }
            .payment-method-header-title {
              font-family: 'Inter';
              font-style: normal;
              font-weight: 600;
              font-size: 16px;
              line-height: 20px;
              letter-spacing: -0.012em;
              color: #dcdfe5;
            }
          }
          .payment-method-credit-card-text {
            font-family: 'Inter';
            font-style: normal;
            font-weight: 500;
            font-size: 12px;
            line-height: 16px;
            letter-spacing: -0.005em;
            color: #96a0b0;
          }
        }
        .payment-card-change {
          display: flex;
        }
      }
      .plan-summary-section-content-container {
        display: grid;
        grid-template-columns: 1fr 1px 1fr;
        gap: 16px;
        .plan-vertical-line {
          width: 1x;
          background-color: #2f353d;
          margin-bottom: 16px;
        }
        .user-plan-summary-left-section {
          display: flex;
          gap: 16px;
          flex-direction: column;
          padding-left: 24px;
          padding-bottom: 16px;
          .user-plan-summary-left-section-header {
            display: flex;
            justify-content: space-between;
            align-items: center;

            .committer-sub-main-header {
              font-family: 'Inter';
              font-style: normal;
              font-weight: 700;
              font-size: 14px;
              line-height: 20px;
              text-transform: uppercase;
              color: #dcdfe5;
            }
          }
          .paid-committers {
            display: flex;
            flex-direction: column;
            gap: 4px;
            .paid-committers-text {
              font-family: 'Inter';
              font-style: normal;
              font-weight: 500;
              font-size: 12px;
              line-height: 16px;
              color: #6a7689;
              span {
                font-family: 'Inter';
                font-style: normal;
                font-weight: 600;
                font-size: 20px;
                line-height: 24px;
                color: var(--theme-color-dcdfe5);
                margin-right: 5px;
              }
            }
          }
          .active-committers {
            display: flex;
            flex-direction: column;
            gap: 4px;
            span {
              font-family: 'Inter';
              font-style: normal;
              font-weight: 600;
              font-size: 20px;
              line-height: 24px;
              color: var(--theme-color-dcdfe5);
              margin-right: 5px;
            }
            .active-committers-text {
              font-family: 'Inter';
              font-style: normal;
              font-weight: 500;
              font-size: 12px;
              line-height: 16px;
              display: flex;
              align-items: center;
              letter-spacing: -0.005em;
              color: #6a7689;
              .active-comitter-number {
                color: #ff9214;
                font-size: 13px;
                margin: 0 4px;
              }
            }
          }
        }
        .user-plan-summary-right-section {
          display: flex;
          flex-direction: column;
          gap: 16px;
          text-align: right;
          padding-right: 24px;
          padding-bottom: 16px;
          .user-plan-summary-right-section-billing {
            font-family: 'Inter';
            font-style: normal;
            font-weight: 700;
            font-size: 14px;
            line-height: 20px;
            text-transform: uppercase;
            color: #dcdfe5;
          }
          .next-plan-date {
            display: flex;
            flex-direction: column;
            gap: 4px;
            .next-plan-date-text {
              font-family: 'Inter';
              font-style: normal;
              font-weight: 600;
              font-size: 20px;
              line-height: 24px;
              letter-spacing: -0.016em;
              color: #dcdfe5;
            }
          }
          .plan-total {
            .billing-total-value-info {
              font-family: 'Inter';
              font-style: normal;
              font-weight: 500;
              font-size: 12px;
              line-height: 16px;
              letter-spacing: -0.005em;
              color: #6a7689;
              margin-top: 4px;
            }
            .billing-total-value {
              font-family: 'Inter';
              font-style: normal;
              font-weight: 600;
              font-size: 20px;
              line-height: 24px;
              letter-spacing: -0.016em;
              color: #dcdfe5;
              margin-left: 5px;
            }
          }
        }
      }
    }
  }
  .plan-subheader {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    color: var(--theme-color-96a0b0);
  }

  .whitelist-pricing-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 24px 0px;
    gap: 24px;
    width: 1200px;
    height: 100%;
    overflow-y: scroll;
    flex: none;
    order: 1;
    align-self: stretch;
    flex-grow: 0;

    .whitelist-pricing-center-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 32px;
      width: 600px;
      height: 368px;
      border-radius: 3px;
      order: 0;
      flex-grow: 0;

      .img-container {
        width: 160px;
        height: 160px;
        flex: none;
        order: 0;
        flex-grow: 0;
      }

      .content-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 16px 0px 0px;
        width: 536px;
        height: 144px;
        flex: none;
        order: 1;
        align-self: stretch;
        flex-grow: 0;

        .header {
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          padding: 16px 0px;
          width: 536px;
          height: 56px;

          font-style: normal;
          font-weight: 600;
          font-size: 20px;
          line-height: 24px;
          letter-spacing: -0.006em;
          color: #dcdfe5;
        }

        .text-container {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          padding: 0px;
          gap: 12px;

          .text {
            display: flex;
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 20px;
            text-align: center;
            letter-spacing: -0.004em;
            color: #dcdfe5;
          }
        }
      }
    }
  }
}

@media (max-width: 480px) {
  .main-pricing-container-wrapper {
    display: flex;
    flex-direction: column;
    gap: 24px;

    .plan-container {
      display: flex;
      flex-direction: column;
      .plan-header {
        display: flex;
        flex-direction: column;
        gap: 15px;
      }

      .plan-summary-section-container {
        flex-direction: column;
        .plan-summary-part-one {
          width: 100%;
          height: auto;
        }
      }
      .plan-summary-section-container {
        .plan-summary-section-content-container {
          .user-plan-summary-right-section {
            display: flex;
            flex-direction: column;
            gap: 16px;
            text-align: left;
            padding-right: 24px;
            padding-bottom: 16px;
            .user-plan-summary-right-section-billing {
              font-family: 'Inter';
              font-style: normal;
              font-weight: 700;
              font-size: 14px;
              line-height: 20px;
              text-transform: uppercase;
              color: #dcdfe5;
            }
          }
        }
        .plan-summary-part-two {
          width: 100%;
        }
      }
    }
  }
}
