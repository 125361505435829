.label {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  color: var(--theme-color-8792a3);
  margin-bottom: 4px;

  span {
    color: #ff5630;
  }
}

.foresight-input.ui.input {
  width: 100%;
  border-radius: 3px;
  height: 40px;

  input {
    background-color: var(--theme-color-070aoc);
    font-size: 14px;
    border: 2px solid var(--theme-color-2f353d) !important;
    color: #dcdfe5;

    &:hover {
      background: #21262d;
    }
    &::placeholder {
      color: var(--theme-color-3e4d61);
      font-size: 14px;
    }
    &:focus {
      border: 2px solid var(--theme-color-163dd4) !important;
      background-color: var(--theme-color-0e1216) !important;
      color: var(--theme-color-d9d9d9) !important;
    }
  }

  &.error-valid {
    input {
      border: 2px solid red !important;
    }
  }
}

.foresight-small-input.ui.input {
  width: 100%;
  border-radius: 3px;
  height: 28px;

  input {
    background-color: var(--theme-color-070aoc);
    font-size: 11px;
    border: 2px solid var(--theme-color-2f353d) !important;
    color: #dcdfe5;

    &:hover {
      background: #21262d;
    }
    &::placeholder {
      color: var(--theme-color-3e4d61);
      font-size: 11px;
    }
    &:focus {
      border: 2px solid var(--theme-color-163dd4) !important;
      background-color: var(--theme-color-0e1216) !important;
      color: var(--theme-color-d9d9d9) !important;
    }
  }

  &.error-valid {
    input {
      border: 2px solid red !important;
    }
  }
}
