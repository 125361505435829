.tests-suites-page-wrapper {
  display: flex;
  flex-direction: column;
  gap: 50px;
  height: calc(100% - 10px);

  td:not(:first-child) {
    padding: 16px;
  }
  .tests-suites-header-container {
    .header-first-section {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .advanced-filter-btn {
        display: flex;
        gap: 20px;
        .ui.button.GrayBorderButton {
          width: 150px;
          height: 32px;
          font-family: 'Inter';
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 20px;
          display: flex;
          align-items: center;
          text-align: right;
          letter-spacing: -0.004em;
          color: #b2baca;
          padding: 6px 12px 6px 8px;
        }
        .icon-filter-icon {
          font-size: 16px;
          margin: 0 6px 0 0;
        }
      }
    }
    .header-second-section {
      display: flex;
      justify-content: space-between;
      .test-suites-search-input {
        display: flex;
        gap: 10px;
        .ui.input.foresight-input {
          width: 360px;
          height: 32px;
        }
      }
      .ui.icon.input.foresight-calendar-component {
        width: 320px;
      }
    }
  }

  #tests-suites-table-container {
    height: calc(100% - 10px);
    overflow: auto;
  }

  .list-cards-body-row {
    position: relative;
    transition: 0.3s all ease-in;
    &:hover {
      background-color: #161b22;
      cursor: pointer;
      transition: 0.3s all ease-in;
    }

    .tests-suites-cell-container {
      display: flex;
      flex-direction: column;
      gap: 8px;
      .test-title {
        display: flex;
        font-family: 'Inter';
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 20px;
        color: #dcdfe5;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 520px;
      }
      .test-information {
        display: flex;
        gap: 24px;
        .test-suites-info-title {
          font-family: 'Inter';
          font-style: normal;
          font-weight: 600;
          font-size: 12px;
          line-height: 15px;
          letter-spacing: -0.005em;
          color: #5c697c;
        }
        .test-suites-info-title.with-dot {
          &::after {
            content: '';
            width: 4px;
            height: 4px;
            border-radius: 15px;
            background-color: #5c697c;
            position: absolute;
            top: 6px;
            margin-left: 10px;
          }
        }
        .test-information-cont {
          display: flex;
          gap: 7.5px;
          position: relative;
          .icon-workflows {
            font-size: 16px;
          }
          .icon-icons-action-job {
            font-size: 16px;
          }
          .icon-bookmark {
            font-size: 16px;
          }
        }
      }
    }
    .tests-suites-performance-cell-container {
      display: flex;
      flex-direction: column;
      gap: 4px;

      .test-performance-row-cont {
        display: flex;
        gap: 2px;
        justify-content: flex-end;
        width: 132px;
        height: 20px;
        align-items: center;

        label {
          font-family: 'Inter';
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 20px;
          letter-spacing: -0.004em;
          color: #5c697c;
        }
        .performance-value {
          font-family: 'Inter';
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 20px;
          letter-spacing: -0.004em;
          color: #b2baca;
        }
      }
    }
    .tests-suites-successfulruns-cell-container {
      display: flex;
      flex-direction: column;
      gap: 4px;

      .test-successful-row-cont {
        display: flex;
        gap: 2px;
        justify-content: flex-end;
        width: 120px;
        height: 20px;
        align-items: center;

        label {
          font-family: 'Inter';
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 20px;
          letter-spacing: -0.004em;
          color: #5c697c;
        }
        .successful-value {
          font-family: 'Inter';
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 20px;
          letter-spacing: -0.004em;
          color: #b2baca;
        }
      }
    }
    .tests-suites-failedruns-cell-container {
      display: flex;
      flex-direction: column;
      gap: 4px;

      .test-failed-row-cont {
        display: flex;
        gap: 2px;
        justify-content: flex-end;
        width: 120px;
        height: 20px;
        align-items: center;

        label {
          font-family: 'Inter';
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 20px;
          letter-spacing: -0.004em;
          color: #5c697c;
        }
        .failed-value {
          font-family: 'Inter';
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 20px;
          letter-spacing: -0.004em;
          color: #b2baca;
        }
      }
    }
    .tests-suites-latest-five-runs-cell-container {
      display: flex;
      gap: 8px;
      :first-child {
        font-size: 16px;
      }
    }
  }
  .tests-suites-list-row-body {
    padding: 20px;
  }
}
