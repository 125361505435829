.extra-warning-container {
  display: flex;
  flex-direction: row;
  height: 92px;
  width: 100%;
  padding: 12px 10px;
  margin-top: 20px;
  background: var(--theme-color-1c222a);
  border-radius: 3px;
  justify-content: space-between;

  .content-part {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;

    .icon-part {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;

      i {
        font-size: 14px;
        margin: 2px 10px;

        &::before {
          color: #5174ff;
        }
      }
    }

    .text-part {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      width: 100%;
      padding-right: 32px;

      .text-part-row-1 {
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 20px;
        letter-spacing: -0.012em;
        color: #dcdfe5;
      }

      .text-part-row-2 {
        margin-top: 4px;
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 16px;
        letter-spacing: -0.005em;
        color: #788496;
      }

      .text-part-row-3 {
        display: flex;
        justify-content: space-between;
        width: 100%;

        .learn-more-part {
          a {
            margin-top: 10px;
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 20px;
            display: flex;
            align-items: center;
            letter-spacing: -0.01em;
            color: #1e90ff;

            &:hover {
              cursor: pointer;
              text-decoration: underline;
            }
          }
        }

        .got-it-part {
          align-self: flex-end;
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 20px;
          display: flex;
          align-items: center;
          text-align: right;
          letter-spacing: -0.01em;
          color: #96a0b0;

          &:hover {
            cursor: pointer;
            color: darken(#96a0b0, 10%);
          }
        }
      }
    }
  }
}

@media (max-width: 480px) {
  .extra-warning-container {
    height: auto;
  }
}
