.workflow-job-steps-header-wrapper {
  border-radius: 3px;
  margin-top: 16px;

  .job-steps-header-wrapper {
    padding: 6px 0px 2px 0px;

    .step-item {
      padding: 10px 24px;
      display: flex;
      justify-content: space-between;

      .right-side {
        display: flex;
        align-items: center;
        justify-content: space-around;
        width: 180px;
        font-style: normal;
        font-weight: 600;
        font-size: 10px;
        line-height: 16px;
        letter-spacing: -0.01em;
        text-transform: uppercase;
        color: var(--theme-color-b2baca);
      }

      .left-side {
        display: flex;

        .left-folding-part {
          width: 30px;
          display: flex;
          align-items: center;
          justify-content: center;
        }

        .step-item-icon {
          margin-top: 3px;
        }
      }

      svg {
        font-size: 15px;
        margin-right: 8px;
      }

      span {
        font-style: normal;
        font-weight: 600;
        font-size: 10px;
        line-height: 16px;
        letter-spacing: -0.01em;
        text-transform: uppercase;
        color: var(--theme-color-b2baca);
      }
    }
  }
}

.workflow-job-steps-wrapper {
  width: calc(100% - 390px);
  background-color: var(--theme-color-070a0c);
  border-radius: 3px;
  margin-top: 2px;

  .job-steps-wrapper {
    padding: 6px 0px;

    .step-item {
      padding: 10px 24px;
      display: flex;
      justify-content: space-between;
      cursor: pointer;
      .right-side {
        display: flex;
        align-items: center;
        justify-content: space-around;
        width: 154px;
        .step-duration {
          margin-left: auto;
          width: 62px;
        }
      }
      .left-side {
        display: flex;

        .left-folding-part {
          width: 30px;
          display: flex;
          align-items: center;
          justify-content: center;
        }

        .step-item-icon {
          margin-top: 3px;
        }
      }

      svg {
        font-size: 15px;
        margin-right: 8px;
      }

      span {
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: -0.01em;
        color: var(--theme-color-b2baca);
      }

      .progress-bar-wrapper {
        width: 120px;
        height: 7px;
        margin: auto 0;

        #progress-bar {
          width: 120px;
          height: 7px;
          background-color: var(--theme-color-1c222a);
          border-radius: 3px;
          margin: auto 0;

          .bar {
            height: 6px;
            background-color: var(--theme-color-b2baca);
            border-radius: 3px 0px 0px 3px;
            min-width: 0px;
          }
        }
      }

      &.not-clickable:hover {
        background-color: unset;
        cursor: not-allowed;
      }

      &:hover {
        background-color: var(--theme-color-151a20);
      }

      &.opened {
        background-color: var(--theme-color-151a20);
      }
    }
  }

  .bar-container {
    display: flex;
    gap: 2px;
    transform: rotate(180deg);

    .bar-column.SUCCESS {
      background: #238636;
      width: 8px;
      max-height: 32px !important;
      transition: all 0.3s ease-in-out;
      &:hover {
        filter: brightness(1.6);
      }
    }
    .bar-column.FAILURE {
      background: #ca3005;
      width: 8px;
      max-height: 32px !important;
      &:hover {
        filter: brightness(1.6);
      }
    }
    .bar-column.EMPTY {
      background: #292b2a5d;
      width: 8px;
      height: 32px !important;
      &:hover {
        filter: brightness(1.6);
      }
    }
  }
}

.too-long-file-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px;

  .row-icon {
    padding-bottom: 12px;
  }

  .row-header {
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    letter-spacing: -0.006em;
    color: #dcdfe5;
    padding: 12px 0px;
  }

  .row-desc {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    letter-spacing: -0.004em;
    color: #dcdfe5;
    width: 400px;
  }

  .row-btn {
    padding: 16px 0px;
  }
}
