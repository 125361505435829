@import 'assets/styles/size.scss';
@import 'assets/styles/font.scss';

.error-viewer-container {
  display: flex;
  height: 100%;
  width: 100%;
  flex-direction: column;

  .field-type {
    font-style: normal;
    font-weight: normal;
    font-size: 11px;
    line-height: 16px;

    display: flex;
    align-items: center;
    text-align: center;
    color: var(--theme-color-788496);

    margin: 0px 0px 6px 0px;

    .value {
      margin-left: 6px;
      color: var(--theme-color-96a0b0);
    }

  }
}

