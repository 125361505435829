.jobs-and-steps-duration-container {
  display: flex;
  flex-direction: column;

  .jobs-and-steps-duration-header {
    padding: 6px 0px 6px 32px;
    margin-top: 12px;
    /* H5 Header */
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    /* identical to box height, or 143% */

    letter-spacing: -0.01em;

    /* Light/L50 */

    color: var(--theme-color-dcdfe5);
  }

  .jobs-and-steps-duration-chart-cont {
    display: flex;
    align-items: center;
    margin-bottom: 16px;

    .steps-jobs-chart {
      width: 65%;
      padding: 0px 32px 16px 32px;
      margin-top: 10px;
    }

    .steps-jobs-widget {
      width: 35%;
      padding: 0px 32px 16px 32px;
      margin-top: 10px;
    }
  }
}

@media (max-width: 480px) {
  .jobs-and-steps-duration-container {
    .jobs-and-steps-duration-chart-cont {
      flex-direction: column;
      .jobs-list-wrapper {
        height: auto;
      }
      .steps-jobs-chart {
        width: 100%;
        padding: 0px 32px 16px 32px;
        margin-top: 10px;
      }

      .steps-jobs-widget {
        width: 100%;
        padding: 0px 32px 16px 32px;
        margin-top: 10px;
      }
    }
  }
}
