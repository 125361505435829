.integration-instruction-container {
  margin-top: 62px;
  display: flex;
  flex-direction: column;
  align-items: center;

  .text-area {
    max-width: 440px;
    display: flex;
    flex-direction: column;
    align-items: center;

    .image-icon {
      width: 56px !important;
      margin-bottom: 24px;
    }

    .header {
      font-size: 20px;
      font-weight: 600;
      line-height: 24px;
      margin-bottom: 12px;
    }
    .troubleshoot-content {
      margin-top: 16px;
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      color: var(--theme-color-788496);
    }

    .content {
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      margin-bottom: 16px;
      color: var(--theme-color-788496);
      text-align: center;
    }

    .content-link {
      color: var(--theme-color-1e90ff);

      &:hover {
        cursor: pointer;
      }
    }
  }
}
