.circle-delimiter {
  border-radius: 50%;
  height: 8px;
  width: 8px;
  background: var(--theme-color-b2baca);
}

.vertical-line {
  width: 2px;
  background: var(--theme-color-b2baca);
  margin-top: 10px;
  margin-bottom: 10px;
  margin-left: 2.55px;

  &.custom {
    height: 32px;
  }

  &.medium {
    height: 40px;
  }

  &.large {
    height: 48px;
  }
}
