.ranged-value-main-container {
  display: flex;
  width: 124px;
  justify-content: space-between;
  position: relative;
  .first-circle {
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background: #1b232b;
    border: 1px solid #5c697c;
    z-index: 1;
    transition: 0.3s all ease;

    &:hover {
      background-color: #2dcd8a;
      filter: brightness(1.3);
      cursor: pointer;
    }
    & > span {
      position: absolute;
      bottom: 15px;
      left: 0;
      font-family: 'Inter';
      font-style: normal;
      font-weight: 700;
      font-size: 14px;
      line-height: 20px;
      letter-spacing: -0.01em;
      color: #b2baca;
    }
  }
  .second-circle {
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background: #1b232b;
    border: 1px solid #5c697c;
    z-index: 1;
    transition: 0.3s all ease;

    &:hover {
      background-color: #ff5630;
      filter: brightness(1.3);

      cursor: pointer;
    }
    & > span {
      position: absolute;
      bottom: 15px;
      right: 0px;
      font-family: 'Inter';
      font-style: normal;
      font-weight: 700;
      font-size: 14px;
      line-height: 20px;
      letter-spacing: -0.01em;
      color: #b2baca;
    }
  }
  .ranged-value-line {
    width: 100px;
    left: 12px;
    position: absolute;
    top: 6px;
    height: 1.5px;
    background: #1b232b;
  }

  .triangle,
  .triangle:before,
  .triangle:after {
    width: 9px;
    height: 9px;
    position: absolute;
    background: #5174ff;
    border-top-right-radius: 30%;
  }
  .triangle:before,
  .triangle:after {
    content: '';
    position: absolute;
    margin: 0;
  }

  .triangle {
    transform: rotate(-60deg) skewX(-30deg) scale(1, 0.866);
    transition: 0.3s all ease;

    &:hover {
      filter: brightness(1.3);
    }
  }
  .triangle:before {
    transform: rotate(-135deg) skewX(-45deg) scale(1.414, 0.707) translate(0, -50%);
  }
  .triangle:after {
    transform: rotate(135deg) skewY(-45deg) scale(0.707, 1.414) translate(50%);
  }
}

// Long AverageRangeGraph

.ranged-value-main-container-long {
  display: flex;
  width: 176px;
  justify-content: space-between;
  position: relative;
  .first-circle {
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background: #1b232b;
    border: 1px solid #5c697c;
    z-index: 1;
    transition: 0.3s all ease;

    &:hover {
      background-color: #2dcd8a;
      filter: brightness(1.3);
      cursor: pointer;
    }
    & > span {
      position: absolute;
      bottom: 15px;
      left: 0;
      font-family: 'Inter';
      font-style: normal;
      font-weight: 700;
      font-size: 14px;
      line-height: 20px;
      letter-spacing: -0.01em;
      color: #b2baca;
    }
  }
  .second-circle {
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background: #1b232b;
    border: 1px solid #5c697c;
    z-index: 1;
    transition: 0.3s all ease;

    &:hover {
      background-color: #ff5630;
      filter: brightness(1.3);
      cursor: pointer;
    }
    & > span {
      position: absolute;
      bottom: 15px;
      right: 0px;
      font-family: 'Inter';
      font-style: normal;
      font-weight: 700;
      font-size: 14px;
      line-height: 20px;
      letter-spacing: -0.01em;
      color: #b2baca;
    }
  }
  .ranged-value-line {
    width: 152px;
    left: 12px;
    position: absolute;
    top: 6px;
    height: 1.5px;
    background: #1b232b;
  }

  .triangle,
  .triangle:before,
  .triangle:after {
    width: 9px;
    height: 9px;
    position: absolute;
    background: #5174ff;
    border-top-right-radius: 30%;
  }
  .triangle:before,
  .triangle:after {
    content: '';
    position: absolute;
    margin: 0;
  }

  .triangle {
    transform: rotate(-60deg) skewX(-30deg) scale(1, 0.866);
    transition: 0.3s all ease;

    &:hover {
      filter: brightness(1.3);
    }
  }
  .triangle:before {
    transform: rotate(-135deg) skewX(-45deg) scale(1.414, 0.707) translate(0, -50%);
  }
  .triangle:after {
    transform: rotate(135deg) skewY(-45deg) scale(0.707, 1.414) translate(50%);
  }
}
