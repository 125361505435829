.foresight-tabs-main-container {
  position: relative;
  width: 100%;

  .ui.buttons.foresight-tabs-container {
    position: relative;

    .tab-info-container {
      margin-left: 3px;

      i:before {
        color: white !important;
      }
    }

    .ui.primary.button {
      background-color: transparent;
      font-family: 'Inter';
      font-weight: 500;
    }

    .ui.active.primary.button {
      border-bottom: 2px solid var(--theme-color-5174ff);
      border-radius: 0;
      z-index: 3;
    }
  }
  .long-divider {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 2px;
    opacity: 0.3;
    background-color: grey;
  }
}

@media (max-width: 480px) {
  .foresight-tabs-main-container {
    .ui.buttons {
      .ui.button {
        font-size: 14px;
      }
    }
  }
}
