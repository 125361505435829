@import 'assets/styles/size.scss';

.billing-method-container {
  background: var(--theme-color-0e1216);

  .billing-method-title {
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    letter-spacing: -0.014em;
    color: #dcdfe5;
    padding: 24px 10px;
  }

  .actions-container {
    border-top: 1px solid var(--theme-color-2f353d);
    padding-top: 32px;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
  }
}
