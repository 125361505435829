.avg-total-duration-container {
  display: flex;
  flex-direction: column;
  width: 1200px;
  height: 172px;
  background: var(--theme-color-1b232b);
  border-radius: 6px;

  .header {
    display: flex;
    padding: 6px 0px 6px 32px;
    margin-top: 12px;

    .title {
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 20px;
      color: var(--theme-color-dcdfe5);
    }
  }

  .cards-container {
    display: flex;

    .sub-avg-total-duration-card {
      margin: 8px 0px 24px 0px;
      padding: 8px 32px 8px 32px;
      width: 600px;
      display: flex;
      border-right: 1px solid rgba(120, 132, 150, 0.24);

      &:last-child {
        border-right: 1px solid transparent;
      }

      .info-part {
        width: calc(100% - 120px);
        display: flex;
        flex-direction: column;
        justify-content: center;

        .sub-title {
          margin-bottom: 4px;
          font-style: normal;
          font-weight: 600;
          font-size: 16px;
          line-height: 20px;
          display: flex;
          align-items: center;
          text-align: center;
          letter-spacing: -0.01em;
          color: var(--theme-color-96a0b0);
        }

        .sub-value {
          font-style: normal;
          font-weight: 500;
          font-size: 40px;
          line-height: 48px;
          display: flex;
          align-items: center;
          text-align: center;
          letter-spacing: -0.01em;
          gap: 10px;
          color: #dcdfe5;
        }
      }
    }
  }
}

.time-symbol {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 38px;
  height: 48px;
  display: flex;
  align-items: flex-end;
  text-align: center;
  letter-spacing: -0.01em;
  color: #dcdfe5;
}

.sub-value-container {
  display: flex;
  gap: 15px;
}

@media (max-width: 480px) {
  .avg-total-duration-container {
    width: 100%;
    height: 100%;
    .cards-container {
      .sub-avg-total-duration-card {
        .info-part {
          width: calc(100% - 5px);
          .sub-value {
            font-style: normal;
            font-weight: 500;
            font-size: 24px;
            line-height: 28px;
            display: flex;
            text-align: left;
            letter-spacing: -0.01em;
            gap: 10px;
            color: #dcdfe5;
          }
        }
      }
    }
  }
}
