.processes-tab-container {
  height: calc(100vh - 460px);
  overflow-y: auto;
  margin-top: 28px;

  .workflow-process-table {
    &.ui.table thead tr:first-child > th {
      position: sticky !important;
      top: 0;
      z-index: 2;
      background-color: var(--theme-color-0e1217);
    }

    .table-row {
      .text-ellipsis {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }

      .status-line {
        height: 16px;
        width: 6px;
        margin-right: 4px;
        margin-left: -4px;

        &.failed {
          background-color: var(--theme-color-f85149);
        }

        &.successed {
          background-color: var(--theme-color-238636);
        }
      }

      td {
        padding: 0.78571429em 0.78571429em;
      }

      .date-row-container {
        display: flex;
      }

      &:hover {
        background-color: var(--theme-color-161b22);
        cursor: pointer;
      }
    }
  }
}

.process-trace-chart-info {
  display: flex;
  align-items: center;
  margin: 16px 12px 6px 12px;
  justify-content: space-between;

  .critical-container {
    display: flex;
    align-items: center;

    .critical-line {
      width: 16px;
      height: 2px;
      background-color: var(--theme-color-ffffff);
      margin-right: 8px;
    }

    .critical-line-text {
      font-size: 12px;
      font-weight: 400;
      line-height: 16px;
      text-align: center;
      margin-right: 8px;
      color: var(--theme-color-ffffff);
    }

    i {
      font-size: 14px;
      margin-bottom: 2px;
      color: var(--theme-color-ffffff);
    }
  }

  .sort-container {
    margin: 0;
  }
}

.process-trace-chart-container {
  overflow: auto;
  font-family: 'Inter';
  background-color: #0e1216;
  color: #dcdfe5;
  .timeline-span {
    background-color: transparent !important;

    &:hover {
      background: #161b22;
    }

    &.selected-span {
      background: #161b22;
    }

    &.insignificant-span {
      opacity: 0.3;
    }

    &.erroneous-span {
      background: rgba(255, 0, 0, 0.2);
    }
  }
}
