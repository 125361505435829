.manage-plan-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0;
  margin: 0;
  width: 576px;
  height: 400px;
  padding-top: 24px;
  padding-left: 24px;
  margin-left: 24px;

  .monthly-committer-limit-container {
    .title {
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 20px;
      display: flex;
      align-items: center;
      letter-spacing: -0.012em;
      color: var(--theme-color-dcdfe5);
      margin-top: 40px;
    }

    .calculator-container {
      width: 100%;
      display: flex;
      justify-content: space-between;
      gap: 10px;
      padding: 12px 0px;
      .input-container {
        background: var(--theme-color-1c222a);
        border-radius: 24px;
        padding: 8px 24px 8px 24px;
        display: flex;
        width: 100%;
        height: 44px;
        border-radius: 24px;
        input {
          width: 100%;
        }
      }
      .increment {
        width: 96px;
        input {
          &::-webkit-outer-spin-button,
          &::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
          }
          -moz-appearance: textfield;

          background-color: var(--theme-color-1c222a);
          border: unset;
          color: var(--theme-color-ffffff);

          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 20px;

          display: flex;
          align-items: center;
          text-align: right;
          letter-spacing: -0.01em;
        }
      }
    }
  }

  .committer-and-payment-container {
    display: flex;
    justify-content: space-between;

    .payment-container {
      display: flex;
      flex-direction: column;
      padding: 16px 24px;
      width: 240px;
      height: 132px;
      justify-content: flex-start;
      align-items: flex-start;
      border: 2px solid var(--theme-color-2f353d);
      border-radius: 4px;

      .payment-title {
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: -0.01em;
        color: var(--theme-color-dcdfe5);
      }

      .payment-credit-number {
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 16px;
        letter-spacing: -0.005em;
        color: var(--theme-color-96a0b0);
        padding: 20px 0px;
      }

      .payment-change-action {
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 16px;
        display: flex;
        align-items: center;
        letter-spacing: -0.005em;
        color: var(--theme-color-1e90ff);
        cursor: pointer;
      }
    }

    .committer-info-container {
      display: flex;
      flex-direction: column;

      .committer-info-all {
        display: flex;
        flex-direction: column;
        width: 275px;

        .free-committers {
          display: flex;
          justify-content: space-between;
          padding: 4px 0px;

          .title {
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 20px;
            display: flex;
            align-items: center;
            text-align: right;
            letter-spacing: -0.01em;
            color: var(--theme-color-96a0b0);
          }

          .value {
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            line-height: 20px;
            display: flex;
            align-items: center;
            text-align: right;
            letter-spacing: -0.01em;
            color: var(--theme-color-96a0b0);
          }
        }

        .charge-committers {
          display: flex;
          justify-content: space-between;
          padding: 4px 0px;

          .title {
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 20px;
            display: flex;
            align-items: center;
            text-align: right;
            letter-spacing: -0.01em;
            color: var(--theme-color-dcdfe5);
          }

          .value {
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            line-height: 20px;
            display: flex;
            align-items: center;
            text-align: right;
            letter-spacing: -0.01em;
            color: var(--theme-color-dcdfe5);
          }
        }

        .peer-committers {
          display: flex;
          justify-content: space-between;
          padding: 4px 0px;
          border-bottom: 2px solid var(--theme-color-2f353d);

          .title {
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 20px;
            display: flex;
            align-items: center;
            text-align: right;
            letter-spacing: -0.01em;
            color: var(--theme-color-dcdfe5);
          }

          .value {
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            line-height: 20px;
            display: flex;
            align-items: center;
            text-align: right;
            letter-spacing: -0.01em;
            color: var(--theme-color-dcdfe5);
          }
        }
      }

      .committer-info-sum {
        .total-monthly {
          display: flex;
          justify-content: space-between;
          .title {
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            line-height: 20px;
            display: flex;
            align-items: center;
            text-align: right;
            letter-spacing: -0.01em;
            color: var(--theme-color-dcdfe5);
          }

          .value {
            font-weight: 600;
            font-size: 32px;
            line-height: 40px;
            display: flex;
            align-items: center;
            text-align: right;
            letter-spacing: -0.02em;
            color: var(--theme-color-dcdfe5);
          }
        }
      }
    }
  }
}
