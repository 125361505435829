.dashboard-widget-card-container {
  .recharts-wrapper {
    cursor: default !important;
  }

  .other-contents {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  // This is to hide right down icon
  .ui.dropdown {
    > i {
      display: none;
    }

    .menu {
      margin-top: 6px;
    }
  }
}

.chart-for-heatmap {
  background-color: var(--theme-color-1b232b);
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 360px;
  height: 204px;
}

.chart-for-heatmap > div > .w-heatmap {
  height: 209px;
  width: 144px;
}

.dashboard-widget-card-container-heatmap .title {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: -0.01em;
  color: var(--theme-color-dcdfe5);
}

.dashboard-widget-card-container-heatmap .header {
  display: flex;
  padding: 5px 0px 12px 0px;
}
