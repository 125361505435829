.main-workflow-runs-empty-container {
  height: calc(100% - 104px);
  display: flex;
  align-items: center;
  justify-content: center;
}

.workflow-run-list-cards-row-body {
  height: 104px;
  &:hover {
    cursor: pointer;
    background: #161b22;
  }

  .cell-container {
    min-width: 140px;
    padding: 10px;

    .failed-jobs {
      svg {
        margin: 0 7px -2px 0;

        font-size: 12px;
        width: 12px;
        height: 12px;
      }
    }

    .sub-row {
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      line-height: 15px;
      letter-spacing: -0.005em;
      color: #b2baca;
    }

    .execution-date-icon-position {
      font-size: 16px;
    }

    .elapsed-icon-position {
      font-size: 16px;
    }

    .flex-row {
      display: flex;
      height: 24px;
      align-items: center;
      gap: 9px;
    }

    .metadata-cell {
      .title {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 20px;
        color: #dcdfe5;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 350px;
        height: 24px;
      }

      .text {
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 16px;
        letter-spacing: -0.005em;
        color: #5c697c;
        height: 24px;
        display: flex;
        align-items: center;
      }

      .strong-text {
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        line-height: 16px;
        letter-spacing: -0.005em;
        color: #788496;
      }

      .section-dot {
        display: inline-block;
        border-radius: 50%;
        height: 6px;
        width: 6px;
        background: #5c697c;
        margin: 5px 13px 0 8px;
      }

      .ellipsis {
        align-items: center;
        padding: 2px 8px;
        width: 85px;
        height: 20px;
        background: rgba(110, 118, 129, 0.24);
        border-radius: 12px;

        .ellipsis-text {
          font-style: normal;
          font-weight: 600;
          font-size: 12px;
          line-height: 16px;
          letter-spacing: -0.01em;
          color: #788496;
        }
      }
    }

    .pr-sum-status-item-header-cont {
      display: flex;
      flex-direction: column;
      gap: 8px;
      justify-content: center;
      align-items: flex-start;
    }
  }

  .commit {
    .commit-wrapper {
      display: flex;
    }

    .commit-icon-position {
      margin-top: 1px;
      margin-right: 6px;
      font-size: 16px;
    }
  }

  .committer {
    .committer-wrapper {
      display: flex;
      align-items: center;
      gap: 6px;
    }

    .person-icon-position {
      font-size: 16px;
    }
  }
}

.workflow-run-item-disabled {
  border: 1px solid var(--theme-color-222b35);
  filter: brightness(0.7);

  &:hover {
    background: var(--theme-color-rgba_255_255_255_004);
    cursor: not-allowed;

    .wf-run-item-arrow-part {
      visibility: visible;
    }
  }

  &.still-progress {
    cursor: not-allowed;
  }
}

.failure_extra_step {
  // TODO Iff we need different heights for extra lines. Right now it's fixed
}
