#project-settings-popup {
  background-color: var(--theme-color-5c697c);
  color: var(--theme-color-c9d1d9);
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
}

.focus {
  background-color: var(--theme-color-b2baca);
}

.normal {
  border-radius: 3px;
}

.settings-container {
  margin-left: 10px;
  width: 32px;
  height: 32px;
  padding: 4px;
  &:hover {
    background-color: var(--theme-color-222b35);
  }
  border-radius: 3px;
}

.focused-container {
  background-color: var(--theme-color-b2baca);
  &:hover {
    background-color: var(--theme-color-b2baca);
  }
}
