.bar-container {
  display: flex;
  gap: 8px;
  transform: rotate(180deg);

  .bar-column.SUCCESS {
    background: #238636;
    width: 12px;
    max-height: 42px !important;
    transition: all 0.3s ease-in-out;
    &:hover {
      filter: brightness(1.6);
    }
  }
  .bar-column.FAILURE {
    background: #ca3005;
    width: 12px;
    max-height: 42px !important;
    &:hover {
      filter: brightness(1.6);
    }
  }
  .bar-column.EMPTY {
    background: rgba(255, 255, 255, 0.12);
    width: 12px;
    height: 42px !important;
    &:hover {
      filter: brightness(1.6);
    }
  }
}
