.filter-label-group-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 32px;
  gap: 12px;
  .filter-clear-btn {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    color: #96a0b0;
    padding: 8px 12px;

    &:hover {
      cursor: pointer;
      background: #1b232b;
      border-radius: 3px;
      transition: all 0.3s linear;
      padding: 8px 12px;
    }
  }
  .vertical-line {
    border-right: 1px solid #2f353d;
    height: 21px;
    background-color: #2f353d;
    margin: 0 12px 0 12px;
  }
}
