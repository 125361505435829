.log-stack-flex-container {
  display: flex;
  height: 100%;
  background-color: var(--theme-color-070a0c);
  margin-left: 48px;

  .log-stack-row-list {
    max-height: 500px;
    height: 100%;
    width: 100%;

    overflow: auto;

    margin-right: -30px;
    padding-right: 40px;

    .log-stack-row {
      display: flex;
      width: 100%;
      letter-spacing: 0;

      font-family: IBM Plex Mono;
      font-style: normal;
      font-weight: 500;
      font-size: 12px;

      color: var(--theme-color-b2baca);

      svg {
        font-size: 16px;
      }

      span {
        font-size: 12px !important;
        line-height: 16px !important;
      }

      .line-number {
        color: var(--theme-color-71a7ff);
        margin-left: 12px;

        span {
          width: 24px;
        }
      }

      .log-content-line {
        width: calc(100% - 35px);
        margin-left: 12px;
        display: flex;
        align-items: center;

        svg {
          margin-right: 4px;
        }

        span {
          white-space: pre;
        }

        .code-line-number {
          color: var(--theme-color-57d9a3);
        }
      }
    }

    .display-none {
      display: none;
    }
  }
}
