.page-container {
  display: table;

  margin: 0 auto;
  margin-top: 68px;
  justify-content: center;

  .github-app-installation-container {
    .not-admin-info-container {
      margin-top: 40px;
      background-color: var(--theme-color-1c222a);
      padding: 16px;
      width: 664px;

      .title {
        font-size: 14px;
        font-weight: 700;
        line-height: 20px;
        color: var(--theme-color-dcdfe5);

        i {
          margin-right: 8px;
          font-size: 20px;
        }
      }

      .content {
        margin-top: 4px;
        color: var(--theme-color-788496);
        margin-left: 28px;
        font-size: 12px;
        font-weight: 500;
        line-height: 16px;

        .user-invitation-link {
          cursor: pointer;
        }
      }
    }

    .install-foresight-github-app-explanation {
      display: flex;
      margin: 32px 0 0 0;

      .foresight-info-container {
        .step {
          margin-bottom: 8px;
          display: flex;

          .section-dot {
            display: inline-block;
            border-radius: 50%;
            height: 6px;
            width: 6px;
            background: var(--theme-color-b2baca);
            margin: 5px 13px 0 8px;
          }

          .text {
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 16px;
            display: flex;
            align-items: center;
            color: var(--theme-color-b2baca);
          }
        }

        .foresight-info {
          width: 664px;
          margin-right: 40px;
          font-size: 14px;
          font-weight: 500;
          line-height: 16px;
          margin-bottom: 8px;
          color: var(--theme-color-b2baca);

          &.two {
            margin-top: 40px;
          }
        }
      }

      .github-info {
        margin-left: 20px;
        padding: 16px 16px 0 16px;
        width: 256px;
        height: 108px;
        background: #262b31;
        border-radius: 4px;
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        letter-spacing: -0.017em;

        .foresight-data-policy {
          color: #1e90ff;
        }

        .github-verified-icon {
          height: 16px;
          width: 16px;
          margin-right: 4px;
        }
        .github-approvement-explanation {
          color: #b2baca;
          margin-bottom: 10px;
          display: flex;
          .text-group {
            .first-indentationed-line {
              margin-left: -20px;
              font-family: 'Inter';
              font-style: normal;
              font-weight: 400;
              font-size: 12px;
              line-height: 16px;
              align-items: center;
            }
          }
        }
      }
    }

    .installation-steps {
      .install-button {
        margin-top: 32px;
        padding: 6px 24px;
        font-weight: 500;
        line-height: 20px;
        font-size: 14px;
      }
    }
  }
}
