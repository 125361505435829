.list-cards-wf-main-container-parent {
  margin-top: 50px;
  border-collapse: collapse;
  td {
    border-top: thin solid #1b232b;
  }

  th {
    padding-bottom: 10px;
    padding-left: 10px;
    text-align: left;
  }

  .list-cards-header-items {
    .text {
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 20px;
      align-items: center;
      letter-spacing: -0.01em;
      color: #788496;
    }
  }

  .workflow-view-list-cards-row-body {
    height: 120px;
    &:hover {
      background: #161b22;
    }

    .cell-container {
      min-width: 70px;
      padding: 10px;

      .workflow-name {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 20px;
        color: #dcdfe5;
      }

      .repo-section {
        margin-top: 6px;
        .icon-bookmark {
          font-size: 16px;
          margin-top: 1px;
        }

        .repo-text {
          font-family: 'Inter';
          font-style: normal;
          font-weight: 600;
          font-size: 12px;
          line-height: 15px;
          letter-spacing: -0.005em;
          color: #5c697c;
        }
      }

      .common-text-style {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: -0.004em;
        color: #b2baca;
      }

      .flex-row {
        display: flex;

        .latest-workflows {
          margin-right: 8px;
        }
      }

      .metadata-cell {
        .title {
          margin-bottom: 2px;
          max-width: 346px;

          .title-text {
            font-family: 'Inter';
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            line-height: 20px;
            color: #dcdfe5;

            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }

          .mergable-state {
            margin-left: 6px;
          }
        }

        .text {
          padding-bottom: 5px;
          font-style: normal;
          font-weight: 500;
          font-size: 12px;
          line-height: 16px;
          letter-spacing: -0.005em;
          color: #5c697c;
        }

        .strong-text {
          font-style: normal;
          font-weight: 600;
          font-size: 12px;
          line-height: 16px;
          letter-spacing: -0.005em;
          color: #788496;
        }

        .section-dot {
          display: inline-block;
          border-radius: 50%;
          height: 6px;
          width: 6px;
          background: #5c697c;
          margin: 5px 13px 0 8px;
        }

        .ellipsis {
          align-items: center;
          padding: 2px 8px;
          width: 85px;
          height: 20px;
          background: rgba(110, 118, 129, 0.24);
          border-radius: 12px;

          .ellipsis-text {
            font-style: normal;
            font-weight: 600;
            font-size: 12px;
            line-height: 16px;
            letter-spacing: -0.01em;
            color: #788496;
          }
        }
      }

      .pr-sum-status-item-header-cont {
        display: flex;
        flex-direction: column;
        gap: 8px;
        justify-content: center;
        align-items: flex-start;

        .pr-sum-status-item-header {
          font-family: 'Inter';
          font-style: normal;
          font-weight: 600;
          font-size: 12px;
          line-height: 16px;
          color: #788496;
        }

        .pr-summary-status-icon {
          display: flex;
          flex-direction: row;
          align-items: center;
          gap: 12px;
          .pr-summary-icon-text {
            font-family: 'Inter';
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            line-height: 20px;
            color: #dcdfe5;
          }
          .icon-icons-summary-cost {
            font-size: 40px;
            &::before {
              color: #5db95c;
            }
          }
          .icon-icons-summary-duration {
            font-size: 40px;
            &::before {
              color: #5fb1c6;
            }
          }
        }
      }
    }
  }

  .icon-info-circle-project-integration:before {
    color: #788496;
    font-size: 9px;
  }
}

#pull-requests-scrollable {
  height: calc(100% - 35px);
  overflow-y: auto;
}

.header-icon-style {
  margin-top: -3px;
  margin-left: 6px;
}

.ci-conclusion-icon-style {
  margin: 0 8px 2px 8px;
}

.failed-workflows {
  display: flex;
  .failed-workflows-icon {
    margin-right: 4px;
    padding-top: 2px;
  }
}

.four-items {
  padding-top: 26px !important;
}

.workflow-search-section-wrapper {
  display: flex;
  justify-content: space-between;

  .workflow-search-section {
    display: flex;
  }

  .search-button {
    margin-left: 8px;
  }

  .head-branch-radio-text {
    margin-top: 3px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: -0.004em;
    color: #b2baca;
  }
}

#workflows-filter-btn {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 6px 12px 6px 8px;
  gap: 4px;

  width: 170px;
  height: 32px;
  background: #1c222a;
  border-radius: 3px;

  .icon-filter-icon {
    height: 18px;
    font-size: 16px;
    margin: 2px;
  }
}

.list-cards-wf-main-container-parent .workflow-view-list-cards-row-body {
  height: 70px;
}

.stats-cell {
  min-width: 70px;
}

.stats-icons {
  font-size: 16px;
  margin-right: 4px;
  margin-top: 2px;
}

.stats-wrapper {
  max-width: 220px;
}

.average-duration-wrapper {
  max-width: 30px;

  .more-icon-wrapper {
    padding-top: 3px;
    width: 24px;
    height: 30px;
    &:hover {
      cursor: pointer;
      background: #1c222a;
      border-radius: 3px;
    }
  }

  .icon-more-vertical {
    font-size: 24px;
  }
  .ui.bottom.center.popup {
    padding: 0;
  }
  .ui.popup {
    padding: 0;
  }

  .ui.bottom.center.popup {
    padding: 0;
  }
}

.foresight-calendar-component {
  & > input {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px 16px;
    gap: 8px;
    height: 32px;
    background: #0e1216;
    min-width: 320px;

    border: 2px solid #1d43dd;
    border-radius: 3px;
  }
}

.org-name {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  align-items: center;
  letter-spacing: -0.004em;
  color: #788496;
}

.filter-group {
  display: flex;

  .ui.button {
    margin: 0;
  }

  gap: 16px;
  .scale {
    padding: 5.8px;
    font-size: 20px;
    background: #1c222a;
    border-radius: 3px;
    &:hover {
      background: #2f353d;
    }
  }

  .settings-icon-wrapper {
    margin-top: 1.3px;
  }

  .GrayBorderButton.ui.button {
    border: 1px solid #1c222a;
  }

  a.ALink {
    &:hover {
      color: #2f353d;
    }
  }

  .fly-loading-container {
    width: 230px;
    height: 50px;
  }
}

.default-branches {
  margin-right: 12px;

  align-items: center;
  padding: 6px 0 0 14px;
  width: 150px;
  height: 32px;
  border: 1px solid #2f353d;
  border-radius: 16px;

  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: -0.005em;
  color: #96a0b0;

  .count {
    padding: 0px 8px;
    color: #09152f;
    width: 24px;
    height: 16px;
    background: #dcdfe5;
    border-radius: 8px;
  }
}
.see-detail-wrapper {
  .see-detail {
    padding: 4px 12px 4px 12px;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    align-items: center;
    letter-spacing: -0.004em;
    color: #c9d1d9;
    &:hover {
      background: #222b35;
      cursor: pointer;
    }
  }
}

#workflow-settings-link {
  margin: 5px 0 0 0;
}

.tooltip-icons-scale {
  font-size: 18px;
  margin-left: -2px;
}

#organization-dropdown-parent {
  align-content: center;
  background-color: var(--theme-color-151a20);
  font-family: 'Inter';

  padding: 0;
  border: 1px solid var(--theme-color-292f38);
  box-shadow: 1px 2px 8px var(--theme-color-rgba_10_13_16_32), 2px 4px 16px var(--theme-color-rgba_10_13_16_24),
    4px 8px 16px var(--theme-color-rgba_10_13_16_16);
  border-radius: 3px;
  .organization-action-dropdown {
    .title {
      padding: 20px 0 0 16px;
      span {
        font-style: normal;
        font-family: 'Inter';
        font-weight: bold;
        font-size: 11px;
        line-height: 16px;
        color: var(--theme-color-6a7689);
      }
    }

    .item-delimiter {
      border-bottom: 2px solid var(--theme-color-30363d);
      margin: 6px 0;
    }

    .item {
      padding: 10px 8px 10px 16px;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      letter-spacing: -0.01em;
      color: var(--theme-color-b2baca);

      &.item.disabled {
        cursor: not-allowed;
        color: var(--theme-color-48576a);

        &:hover {
          background-color: var(--theme-color-151a20);
        }
      }

      &.item-last {
        margin-bottom: 6px;
      }

      &.item-first {
        margin-top: 6px;
      }

      &:hover {
        cursor: pointer;
        background: var(--theme-color-rgba_220_223_229_008);
      }
    }
  }
}
