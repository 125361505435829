.workflow-runs-filter-container {
  width: 250px;
  height: calc(100%);
  overflow-y: auto;
  overflow-x: hidden;

  .workflow-runs-filter-head-container {
    display: flex;
    .filter-by-title {
      flex-grow: 1;
      font-family: Inter;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 20px;
      display: flex;
      align-items: center;
      letter-spacing: -0.01em;
      color: var(--theme-color-788496);
    }
    .filter-by-checkbox {
      flex-grow: 0;
      margin-right: 10px;
    }
  }

  .workflow-title {
    font-family: Inter;
    font-style: normal;
    font-weight: bold;
    font-size: 11px;
    line-height: 16px;

    display: flex;
    align-items: center;
    letter-spacing: -0.01em;

    color: var(--theme-color-6a7689);
    padding: 12px 0;
  }

  .workflow-filters {
    .workflow-name {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .title {
        padding: 4px 0px;
        font-family: Inter;
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 16px;

        display: flex;
        align-items: center;

        color: var(--theme-color-dcdfe5);
      }

      .square {
        min-width: 12px;
        width: 12px;
        height: 12px;
        border-radius: 3px;
      }
    }
  }
}
#workflows-filter-group {
  border-top: 0;
  border-bottom: 0;
}

@media (max-width: 480px) {
  .workflow-runs-filter-container {
    width: 100%;
    height: calc(100%);
    overflow-y: auto;
    overflow-x: hidden;
  }
}
