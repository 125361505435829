.table-container {
  background-color: var(--theme-color-0e1216);
  margin-bottom: 25px;
}

.content-tags-test-run-container {
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: flex-start;
  width: 320px;
  justify-content: flex-start;
  .test-run-content-tags {
    gap: 4px;
    height: 20px;
    padding: 0 8px;
    background: #21262d;
    border: 1px solid #2f353d;
    border-radius: 10px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 11px;
    line-height: 16px;
    color: #b2baca;
    max-width: 40ch;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

.suite-title {
  padding-left: 6px;
  font-size: 16px;
  color: var(--theme-color-b2baca);
  font-weight: 500;
}

.suites-desc {
  padding-left: 22px;
  padding-top: 7px;
  font-size: 11px;
  color: var(--theme-color-788496);
  font-weight: 500;
  display: flex;
  align-items: center;

  .delimiter-icon {
    padding: 0 12px 0 10px;
  }
}

i.test-suites-time-icon {
  font-size: 16px;
  padding-right: 6px;
  padding-top: 2px;
  margin-bottom: 4px;
}

.suites-datetimes {
  font-size: 11px;
  color: var(--theme-color-b2baca);
  display: flex;
  align-items: center;

  span {
    font-weight: 500;
  }

  i {
    color: var(--theme-color-5c697c);
  }
}

.ui.card.list-item-card:not(:first-child) {
  border-top: 0px;
}

.single-line {
  margin: auto 0 !important;
}

.ui.grid.infinite-table-grid:hover {
  .testrun-detail-navigation-icon {
    visibility: visible;
    color: var(--theme-color-8993a4);
  }
}

.testrun-detail-navigation-icon {
  text-align: right;
  visibility: hidden;
  margin: auto;
  margin-right: 23px;
  width: 16px;

  i {
    font-size: 16px;
  }
}

.ellipsis-text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: var(--theme-color-788496);
}

.no-test-runs-container {
  text-align: center;

  .empty-result-icon-container {
    margin-bottom: 16px;
  }

  .description-title {
    color: var(--theme-color-788496);
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 8px;
  }

  .description-content {
    color: var(--theme-color-788496);
    font-size: 11px;
    margin-bottom: 8px;
    max-width: 320px;
    display: inline-block;
  }

  .url-container {
    display: inline-block;
    margin-right: 8px;
    margin-left: 8px;
    cursor: pointer;
  }

  .integrate-testruns {
    color: var(--theme-color-788496);
    margin-top: 16px;
    font-size: 11px;
    max-width: 320px;
    display: inline-block;
  }

  .integration-info-message {
    text-align: start;
    color: var(--theme-color-788496);

    i {
      color: var(--theme-color-1d43dd);
      margin-left: 6px;
    }

    &.align-center {
      text-align: center;
    }
  }

  .show-integration-methods {
    cursor: pointer;
    color: var(--theme-color-5174ff);

    &:hover {
      text-decoration: underline;
      color: var(--theme-color-5599ff);
    }
  }

  .instrument-project-options-accordion {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 32px;
  }

  .item-group-title {
    margin-bottom: 4px;
    color: var(--theme-color-788496);
  }

  .footer-supported-project-text {
    margin-top: 32px;
    margin-bottom: 24px;
    color: var(--theme-color-5c697c);

    .project-help-us-link {
      cursor: pointer;
    }
  }

  .supported-project-logos {
    height: 24px;
    width: 100%;
    margin-bottom: 24px;

    .project-logo.ui.image {
      display: inline-block;
      margin-right: 24px;
    }
  }

  .integration-info-message {
    text-align: start;
    color: var(--theme-color-788496);

    i {
      color: var(--theme-color-1d43dd);
      margin-left: 6px;
    }

    &.align-center {
      text-align: center;
    }
  }

  .ui.vertical.accordion.menu {
    min-width: 480px;
    border: none;
    text-align: left;

    .item-group-title {
      margin-bottom: 4px;
      color: var(--theme-color-788496);
    }

    .manuel-integration {
      margin-top: 16px;
    }

    .item {
      width: 100%;
      border: 1px solid var(--theme-color-222b35);
      padding-left: 24px;
      padding-top: 12px;
    }
  }
}

.ui.popup.visible.card-in-progress-popup {
  background-color: var(--theme-color-5c697c);
  color: var(--theme-color-ecf4fa);
  padding: 4px 8px;

  &::before {
    content: unset;
  }
}

.testruns-list-container {
  height: calc(100vh - 250px);
  thead {
    position: sticky;
    z-index: 30;
    top: 0;
  }
  .pr-list-header {
    display: flex;
    gap: 80px;
    border-bottom: 2px solid #1b232b;
    background-color: #0e1216;

    & > :nth-child(1) {
      margin-left: 0;
    }
    & > :nth-child(2) {
      margin-left: 15px;
    }
    :nth-child(3) {
      display: flex;
      margin-left: auto;
      margin-right: 20px;
      gap: 10px;
    }
  }

  .test-run-accordion-section-container {
    display: flex;
    gap: 36px;

    .test-run-accordion-first-section-header-main-container {
      display: flex;
      flex-direction: column;
      min-width: 110px;
      gap: 9px;
      .test-run-accordion-header-container {
        display: flex;
        align-items: center;
        gap: 5px;
        .test-run-accordion-header-icon {
          font-size: 14px;
        }
        .test-run-accordion-header-text {
          font-family: 'Inter';
          font-style: normal;
          font-weight: 600;
          font-size: 12px;
          line-height: 15px;
          color: #b2baca;
        }
      }
    }
    .test-run-accordion-second-section-header-main-container {
      display: flex;
      flex-direction: column;
      gap: 9px;

      .test-run-accordion-header-container {
        display: flex;
        align-items: center;
        gap: 5px;
        .test-run-accordion-header-icon {
          font-size: 14px;
          .icon-icons-check {
            &::before {
              color: #2dcd8a;
            }
          }
        }
        .test-run-accordion-header-main-text {
          font-family: 'Inter';
          font-style: normal;
          font-weight: 600;
          font-size: 16px;
          line-height: 20px;
          color: #dcdfe5;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          max-width: 500px;
        }
        .test-run-accordion-header-text {
          font-family: 'Inter';
          font-style: normal;
          font-weight: 600;
          font-size: 12px;
          line-height: 15px;
          color: #b2baca;
        }
        .test-run-accordion-header-footer-text {
          font-family: 'Inter';
          font-style: normal;
          font-weight: 500;
          font-size: 12px;
          line-height: 16px;
          color: #5c697c;
          & > .successful-span {
            font-family: 'Inter';
            font-style: normal;
            font-weight: 600;
            font-size: 12px;
            line-height: 16px;
            color: #3a9f47;
          }
          & > .failed-span {
            font-family: 'Inter';
            font-style: normal;
            font-weight: 600;
            font-size: 12px;
            line-height: 16px;
            color: #e45f5ffa;
          }
        }
        .accordion-content-test-left-part-items {
          display: flex;
          gap: 5px;
          width: 60px;
          align-items: center;
          .test-run-accordion-content-value {
            font-family: 'Inter';
            font-style: normal;
            font-weight: 500;
            font-size: 12px;
            line-height: 16px;
          }
          .test-run-accordion-content-icon {
            font-size: 12px;
            margin-top: 2px;
            .icon-icons-check {
              &::before {
                color: #2dcd8a;
              }
            }
          }
        }
      }
    }
  }

  a {
    width: 100%;
    color: #dcdfe5;
    &:hover {
      text-decoration: none;
    }
  }
  .accordion-test-content-main-container {
    display: flex;
    padding: 10px 58px 10px 100px;
    border-radius: 3px;
    justify-content: space-between;
    height: 64px;
    &:hover {
      transition: background-color 0.2s ease-in;
      background-color: #222b35;
    }
    .accordion-content-test-first-part {
      display: flex;
      gap: 28px;
      align-items: center;
      .test-run-accordion-content-icon {
        font-size: 16px;
        margin-top: 3px;
      }
      .test-run-accordion-content-value {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 20px;
        overflow: hidden;
        white-space: nowrap;
        max-width: 370px;
        text-overflow: ellipsis;
      }
      .test-run-accordion-content-value-and-tags-container {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        .tags-container-for-test-run {
          display: flex;
          gap: 5px;
          .test-run-content-tags {
            gap: 4px;
            height: 20px;
            margin: 6px auto;
            padding: 0 8px;
            background: #21262d;
            border: 1px solid #2f353d;
            border-radius: 10px;
            font-family: 'Inter';
            font-style: normal;
            font-weight: 500;
            font-size: 11px;
            line-height: 16px;
            color: #b2baca;
            max-width: 18ch;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
          .show-more-icon-tags {
            font-family: 'Inter';
            font-style: normal;
            font-weight: 500;
            font-size: 11px;
            display: flex;
            line-height: 16px;
            align-items: center;
            color: #b2baca;
            padding: 4px 8px;
            gap: 6px;
            &:hover {
              background: #21262d;
              border-radius: 12px;
            }
          }
        }
      }
    }
    .accordion-content-test-left-part {
      display: flex;
      gap: 25px;
      .accordion-content-test-left-part-items {
        display: flex;
        gap: 8px;
        width: 50px;
        align-items: center;
        .test-run-accordion-content-value {
          font-family: 'Inter';
          font-style: normal;
          font-weight: 500;
          font-size: 12px;
          line-height: 16px;
        }
        .test-run-accordion-content-icon {
          font-size: 12px;
          margin-top: 2px;
          .icon-icons-check {
            &::before {
              color: #2dcd8a;
            }
          }
        }
      }
    }
  }
}
