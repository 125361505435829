.total-cost-container {
  display: flex;
  flex-direction: column;
  width: 1200px;
  height: 172px;
  background: #1b232b;
  border-radius: 6px;

  .header {
    display: flex;
    padding: 6px 0px 6px 32px;
    margin-top: 12px;

    .title {
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 20px;
      /* identical to box height, or 143% */

      letter-spacing: -0.01em;

      /* Light/L50 */

      color: #dcdfe5;
    }
  }

  .cards-container {
    display: flex;

    .sub-total-cost-card {
      margin: 8px 0px 24px 0px;
      padding: 8px 32px 8px 32px;
      width: 400px;
      display: flex;
      border-right: 1px solid rgba(120, 132, 150, 0.24);

      &:last-child {
        border-right: 1px solid transparent;
      }

      .info-part {
        width: calc(100% - 120px);
        display: flex;
        flex-direction: column;
        justify-content: center;

        .sub-title {
          margin-bottom: 4px;
          font-style: normal;
          font-weight: 600;
          font-size: 16px;
          line-height: 20px;
          /* identical to box height, or 143% */

          display: flex;
          align-items: center;
          text-align: center;
          letter-spacing: -0.01em;

          /* Gray/G80 */

          color: var(--theme-color-96a0b0);
        }

        .sub-value {
          font-style: normal;
          font-weight: 500;
          font-size: 40px;
          line-height: 48px;
          /* identical to box height, or 120% */

          display: flex;
          align-items: center;
          text-align: center;
          letter-spacing: -0.01em;

          /* Light/L50 */

          color: #dcdfe5;
        }
      }
    }
  }
}

.dolar-symbol {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 38px;
  height: 48px;
  /* identical to box height, or 200% */

  display: flex;
  align-items: flex-end;
  text-align: center;
  letter-spacing: -0.01em;

  /* Light/L50 */

  color: #dcdfe5;
}

@media (max-width: 480px) {
  .total-cost-container {
    width: 100%;
    .cards-container {
      display: block;
      .sub-total-cost-card {
        width: calc(100% - 10px);
        .info-part {
          width: calc(100% - 5px);
          display: flex;
          flex-direction: column;
          justify-content: center;
        }
      }
    }
  }
}
