.test-run-test-tabs-container {
  height: 27px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  margin: 24px 0px 20px 0px;

  .tb-btn {
    background-color: transparent !important;
    border-radius: 0px !important;
    font-size: 12px;
    line-height: 16px;
    align-items: center;
    color: var(--theme-color-788496);
    font-style: normal;
    font-weight: 500;
    border-bottom: 3px solid transparent !important;
    margin-right: 16px;
    padding-bottom: 5px;
    cursor: pointer;

    &:hover {
      color: var(--theme-color-96a0b0);
      border-bottom: 3px solid transparent !important;
    }

    &.selected-btn {
      color: var(--theme-color-96a0b0);
      border-bottom: 3px solid var(--theme-color-96a0b0) !important;
    }
  }
}
