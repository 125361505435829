// Modal Css
.billing-user-custom-modal-container {
  display: flex;
  flex-direction: column;
  .billing-user-modal-text {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #dcdfe5;
  }
  .billing-user-input-container {
    display: flex;
    justify-content: left;
    align-items: center;
    margin-bottom: 16px;

    .minus-line-container {
      background-color: transparent;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 32px;
      width: 32px;
      margin-left: 16px;

      .minus-line {
        height: 2px;
        width: 12px;
        background-color: var(--theme-color-ffffff);
      }

      &:hover {
        border-radius: 3px;
        background-color: var(--theme-color-2f353d);
      }
    }

    .billing-user-input {
      width: 100%;
      height: 40px;

      input {
        background-color: var(--theme-color-151a20);
        border: 2px solid var(--theme-color-2f353d);
        border-radius: 3px;
        padding: 0 8px;
        font-size: 14px;

        &::placeholder {
          color: var(--theme-color-3e4d61);
          font-size: 14px;
        }
      }

      &.error-email {
        input {
          border: 2px solid red;
        }
      }
    }
  }
  .billing-user-custom-modal-footer {
    display: flex;
    justify-content: flex-end;
  }
}

// Page Css

.billing-user-main-container {
  .add-user-button {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: var(--theme-color-1e90ff);
    width: 150px;

    &:hover {
      color: var(--theme-color-73baff);
      text-decoration: underline;
      cursor: pointer;
    }
  }

  .billing-user-dropdown-container {
    display: flex;
    flex-direction: column;
    margin-bottom: 16px;
    gap: 4px;
    label {
      font-family: 'Inter';
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      line-height: 16px;
      display: flex;
      align-items: center;
      letter-spacing: -0.01em;
      color: #8792a3;
    }

    .ui.selection.active.dropdown {
      border-color: #1d43dd;
      border-width: 2px;
    }

    .minus-line-container {
      background-color: transparent;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 32px;
      width: 32px;
      margin-left: 16px;

      .minus-line {
        height: 2px;
        width: 12px;
        background-color: var(--theme-color-ffffff);
      }

      &:hover {
        border-radius: 3px;
        background-color: var(--theme-color-2f353d);
      }
    }
  }
}
