@import 'assets/styles/size.scss';

$left-option-bar-width: 250px;

.left-option-bar {
  height: 100%;
  position: absolute;
  padding: $spacing;
  width: $left-option-bar-width;
  display: flex;
  flex-direction: column;
  overflow: auto;
}

.left-option-bar-4queries {
  height: 100%;
  position: absolute;
  padding: $spacing 0;
  width: $left-option-bar-width;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  overflow-x: hidden;
}

.list-layout-content {
  // TODO: add overflow to layout-content, then update functions-list, onboarding  etc.
  // display: flex;
  // flex-direction: column;
  // overflow-y: overlay;

  // width: 100%;
  height: 100%;
  padding: $spacing;
  background-color: var(--theme-color-f7f7f7);
  margin-left: $left-option-bar-width;
  // margin-right: 60px; // 60px is the SUIR sidebar's 'very thin' size.

  // overflow-y: auto;
  // overflow-x: hidden;

  // To disable scroll background
  ::-webkit-scrollbar-track {
    background: none;
  }
}
