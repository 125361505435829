.action-rect {
  font-family: 'Inter';

  .auth0-lock {
    .auth0-lock-center {
      vertical-align: unset;
      padding-top: 40px;
      padding-right: 20px;
      padding-bottom: 0px;

      form.auth0-lock-widget {
        .auth0-lock-content {
          padding-left: 0;
          padding-right: 0;
          width: 360px;
          margin-left: -40px;
          .auth0-lock-form {
            .auth0-lock-tabs-container {
              // these are to decrease width of login/signup navigations
              margin-right: 0;
              margin-bottom: 40px;

              .auth0-lock-tabs {
                background: none;

                width: 260px;
                margin-left: 50px;
                margin-right: 50px;

                box-shadow: 0 1px 0 0 var(--theme-color-39495d);
                .auth0-lock-tabs-current {
                  box-shadow: 0 3px 0 0 var(--theme-color-5c697c);
                }
              }

              a,
              span {
                color: var(--theme-color-5c697c);
                font-size: 20px;
                font-family: 'Inter';
                font-weight: 600;
                letter-spacing: 0;
                line-height: 24px;
              }

              span {
                // current tab color
                color: var(--theme-color-5c697c);
                font-family: 'Inter';
              }
            }

            // Parent div cant be selected, no class!
            .auth0-lock-input-block {
              .auth0-lock-input-wrap {
                height: 40px;
                border: 2px solid var(--theme-color-2a3b50);
                border-radius: 4px;
                width: 100%;
                background-color: var(--theme-color-151a20);

                // These are to increase width of inputs
                margin-left: -20px;
                margin-right: -20px;

                &.auth0-lock-focused {
                  border: 2px solid var(--theme-color-254be2);
                }

                .auth0-lock-input {
                  background-color: var(--theme-color-0e1216);
                  color: var(--theme-color-d4d4d4);
                  font-family: 'Inter';
                  font-size: 14px;
                  font-weight: 500;
                  line-height: 19px;
                  width: 320px;

                  &::placeholder {
                    color: var(--theme-color-2a3b50);
                  }
                  &:hover {
                    background-color: var(--theme-color-151a20);
                  }
                }
              }
            }

            .auth0-lock-alternative {
              margin: 10px -20px;
              margin-bottom: 0;
              text-align: left;

              .auth0-lock-alternative-link {
                color: var(--theme-color-1d43dd) !important;
                font-family: 'Inter';
                font-size: 12px;
                font-weight: 500;
                line-height: 16px;
              }
            }

            p > span {
              color: var(--theme-color-d4d4d4);
              font-family: 'Inter';
            }
          }
        }

        .auth0-lock-terms {
          span a {
            color: var(--theme-color-4666e6);
          }
        }

        // login/signup button style
        .auth0-lock-submit {
          height: unset;
          padding: 0;
          background-color: var(--theme-color-1d43dd) !important;
          font-family: 'Inter';
          border-radius: 4px;
          font-size: 16px;
          font-weight: 600;
          margin-top: 5px;
          width: 360px;
          margin-left: -40px;
          // clear '>' after LOG IN label
          .auth0-label-submit {
            > span {
              display: none;
            }
          }
        }

        // signup terms style
        .auth0-lock-terms {
          background: none;
          padding: 0;
          text-align: left;
          color: var(--theme-color-5c697c);
          font-size: 12px;
          font-weight: 500;
          line-height: 0px;
          margin-left: -40px;
          margin-top: -16px;
          margin-bottom: 10px;

          a {
            color: var(--theme-color-1d43dd) !important;
          }
        }

        // remove lock header
        .auth0-lock-cred-pane {
          background-color: transparent;
          .auth0-lock-header {
            display: none;
          }
        }
      }
    }
  }

  .auth0-lock.auth0-lock .auth0-lock-social-button.auth0-lock-social-big-button .auth0-lock-social-button-text {
    //Github/Google button text color
    color: var(--theme-color-788496) !important;
    text-align: center;
    font: optional;
    font-family: 'Inter';
  }

  .auth0-lock.auth0-lock .auth0-lock-social-button.auth0-lock-social-big-button {
    // Github/Google button
    background-color: var(--theme-color-151a20) !important;
    width: 360px;

    left: -18px;
    color: var(--theme-color-788496);
    border: none !important;
    border-radius: 3px;
  }
}

.auth0-lock.auth0-lock
  .auth-lock-social-buttons-pane
  .auth0-lock-social-button.auth0-lock-social-big-button:hover:not([disabled])
  .auth0-lock-social-button-text,
.auth0-lock.auth0-lock
  .auth-lock-social-buttons-pane
  .auth0-lock-social-button.auth0-lock-social-big-button:focus:not([disabled])
  .auth0-lock-social-button-text {
  // this need to be different than 151a20 but as close as possible
  background-color: var(--theme-color-1c222a) !important;
}

.auth0-lock.auth0-lock
  .auth-lock-social-buttons-pane
  .auth0-lock-social-button[data-provider^='google']:hover:not([disabled])
  .auth0-lock-social-button-icon,
.auth0-lock.auth0-lock
  .auth-lock-social-buttons-pane
  .auth0-lock-social-button[data-provider^='google']:focus:not([disabled])
  .auth0-lock-social-button-icon,
.auth0-lock.auth0-lock
  .auth-lock-social-buttons-pane
  .auth0-lock-social-button[data-provider^='google']:hover:not([disabled])
  .auth0-lock-social-button-text,
.auth0-lock.auth0-lock
  .auth-lock-social-buttons-pane
  .auth0-lock-social-button[data-provider^='google']:focus:not([disabled])
  .auth0-lock-social-button-text {
  background-color: var(--theme-color-1c222a) !important;
}

.auth0-lock-social-button-icon {
  width: 40px !important;
  height: 40px !important;
  margin-left: 90px;
}

input:-webkit-autofill,
textarea:-webkit-autofill,
select:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 1000px var(--theme-color-0e1216) inset !important;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  -webkit-text-fill-color: var(--theme-color-6a7689) !important;
  font-family: 'Inter';
  border: 2px solid var(--theme-color-2a3b50);
  box-sizing: border-box;
  border-radius: 4px;
}

.auth0-lock.auth0-lock .auth0-lock-content-body-wrapper {
  margin-top: -50px !important;
}
