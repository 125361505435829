.status-filter-container {
  display: flex;
  align-items: center;
  background-color: var(--theme-color-151a20);

  border-radius: 3px;

  width: 120px;
  margin-right: 12px;

  .ui.selection.dropdown {
    max-height: 24px;
    height: 24px;
    min-height: 24px;

    min-width: 120px;
    padding: 4px 13px;
    border-color: var(--theme-color-2f353d) !important;

    font-style: normal;
    font-weight: 500;
    font-size: 11px;
    line-height: 16px;
    /* identical to box height, or 145% */

    display: flex;
    align-items: center;
    justify-content: flex-start;
    background-color: var(--theme-color-070a0c);

    /* Gray/G100 */

    color: var(--theme-color-b2baca);


    &:focus {
      border: 1px solid var(--theme-color-254be2) !important;
    }

    &:hover {
      cursor: pointer;
      background-color: var(--theme-color-202328) !important;
    }


    &.visible {

      .text {
        font-weight: 500;
        color: var(--theme-color-b2baca);
      }


      &:hover {
        cursor: default;
        background-color: var(--theme-color-070a0c) !important;
      }
    }


  }

  .ui.selection.active.dropdown .menu {
    background-color: var(--theme-color-070a0c);
    border-left: 1px solid var(--theme-color-254be2) !important;
    border-bottom: 1px solid var(--theme-color-254be2) !important;
    border-right: 1px solid var(--theme-color-254be2) !important;
    font-weight: 500;
  }

  .ui.selection.dropdown .menu > .item {
    text-align: left;
    height: 24px;
    font-style: normal;
    font-weight: 500;
    font-size: 11px;
    line-height: 16px;
    /* identical to box height, or 145% */

    display: flex;
    align-items: center;
    justify-content: flex-start;
    background-color: var(--theme-color-070a0c);

    /* Gray/G100 */

    color: var(--theme-color-b2baca);

    &.active.selected {
      background-color: var(--theme-color-1d43dd);

      .text {
        color: var(--theme-color-ffffff);
      }
    }

    &:hover {
      background-color: var(--theme-color-1c222a);
      color: var(--theme-color-ffffff);
    }
  }
}
