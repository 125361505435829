.filter-label-component-container {
  width: fit-content;
  display: flex;
  flex-direction: row;
  align-items: center;
  border: 1px solid #2f353d;
  padding: 8px 12px;
  border-radius: 16px;
  gap: 5px;
  &:hover {
    cursor: pointer;
    background: #2f353d;
    border: 1px solid #2f353d;
    border-radius: 16px;
    transition: all 0.3s linear;
  }

  .filter-show-wrapper {
    display: block;
    width: auto;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    color: #96a0b0;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .number-circle {
    border-radius: 8px;
    height: 16px;
    background-color: #dcdfe5;
    display: block;
    padding: 0px 8px;
    margin: auto 0;
    color: #09152f;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
  }
}
.filter-label-tooltip-list-item {
  text-decoration: none;
  list-style-type: none;
  margin-bottom: 5px;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 80ch;
  white-space: nowrap;
}
