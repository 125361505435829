@keyframes loading-ellipsis {
  0% {
    content: '';
  }
  20% {
    content: '.';
  }
  40% {
    content: '..';
  }
  60% {
    content: '...';
  }
  80%,
  100% {
    content: '....';
  }
}

.foresight-toggle-container {
  .ui.checked.fitted.toggle.radio-button-loading-before {
    ::before {
      content: '';
      width: 3px;
      background: linear-gradient(45deg, #163dd4, rgb(183, 171, 252));
      margin-left: 10px;
      animation: wave 1s infinite;
      border-radius: 20px;
    }
  }
  .ui.fitted.toggle.checkbox.radio-button-loading-after {
    ::before {
      content: '';
      width: 3px;
      background: linear-gradient(45deg, #163dd4, rgb(183, 171, 252));
      animation: wave 1s infinite;
      border-radius: 20px;
      margin-left: 35px;
      border-radius: 20px;
    }
  }

  .ui.checked.fitted.toggle.checkbox.radio-button {
    label:after {
      background-color: var(--theme-color-ffffff);
      border: 1px solid #163dd4;
    }
    label:before {
      background-color: var(--theme-color-163dd4) !important;
      border: 1px solid #163dd4;
    }
  }
  .ui.fitted.toggle.checkbox.radio-button {
    label:after {
      background-color: var(--theme-color-1b232b);
      border: 1px solid #4d5b6e;
    }
    label:before {
      background-color: transparent !important;
      border: 1px solid #4d5b6e;
    }
  }

  .ui.checked.toggle.checkbox.radio-button {
    & > label {
      font-family: 'Inter';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: #dcdfe5  !important;
    }
    label:after {
      background-color: var(--theme-color-ffffff);
      border: 1px solid #163dd4;
    }
    label:before {
      background-color: var(--theme-color-163dd4) !important;
      border: 1px solid #163dd4;
    }
  }
  .ui.toggle.checkbox.radio-button {
   & > label {
      font-family: 'Inter';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: #dcdfe5 !important;
    }
    label:after {
      background-color: var(--theme-color-1b232b);
      border: 1px solid #4d5b6e;
    }
    label:before {
      background-color: transparent !important;
      border: 1px solid #4d5b6e;
    }
  }
  
}

@keyframes wave {
  0% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(180deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
