.info-tooltip-container {
  text-align: start;

  .info-tooltip-title {
    font-size: 14px;
    font-weight: 700;
    line-height: 20px;
    text-align: left;
    margin-bottom: 4px;
  }
}
