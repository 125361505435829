.wf-edit-label-panel {
  .label-title-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 4px;

    width: 38px;
    height: 20px;

    .label-title {
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      line-height: 16px;
      display: flex;
      align-items: center;
      letter-spacing: -0.01em;
      color: var(--theme-color-8792a3);
    }
  }

  .label-creation-part,
  .label-container {
    display: flex;
    flex-direction: column;
    min-height: 40px;
    max-height: 200px;
    overflow-y: auto;

    &.hasScroll {
      border-bottom: 1px solid var(--theme-color-2f353d);
    }

    .label-item {
      display: flex;
      align-items: center;
      height: 40px;
      min-height: 40px;
      gap: 4px;

      .workflow-label {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: flex-start;
        padding: 2px 8px;
        gap: 4px;

        height: 20px;
        background: var(--theme-color-1c222a);
        border: 1px solid var(--theme-color-2f353d);
        border-radius: 12px;
        margin-right: 4px;

        .workflow-label-text {
          max-width: 160px;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
          font-style: normal;
          font-weight: 600;
          font-size: 11px;
          line-height: 16px;
          color: var(--theme-color-b2baca);
        }
      }

      i {
        font-size: 20px;
        cursor: pointer;

        &:hover {
          &::before {
            color: var(--theme-color-dcdfe5);
          }
        }
      }
    }

    .label-update-part {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .left-part {
        width: 354px;
      }

      .right-part {
        display: flex;
        justify-content: flex-end;
        align-items: center;
      }
    }

    .label-delete-part {
      display: flex;
      justify-content: space-between;
      align-items: center;
      border: 1px solid var(--theme-color-fb4932);
      border-radius: 6px;
      padding: 2px 2px;

      .left-part {
        span {
          margin-left: 8px;
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 20px;
          color: var(--theme-color-c9d1d9);
        }
      }

      .right-part {
        display: flex;
        gap: 8px;

        .RedButton {
          margin: 0px !important;
        }
      }
    }
  }

  .action-buttons-container {
    display: flex;
    justify-content: flex-end;
    margin-top: 16px;
  }

  .error-input-message {
    color: var(--theme-color-ff5630);

    i {
      margin-right: 2px;
    }
  }
}
