.glb-not-container {
  width: 100%;
  height: 52px;
  display: flex;
  align-items: center;
  background-color: #ff9214;
  justify-content: center;
  gap: 4px;

  .glb-not-first-content {
    display: flex;
    align-items: center;
    .icon-warning {
      font-size: 24px;
      &::before {
        color: #363636;
      }
    }
    .first-content-text {
      font-family: 'Inter';
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 24px;
      color: #363636;
    }
  }
  .second-content-text {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    color: #0e1216;
    &:hover {
      cursor: pointer;
      user-select: none;
      text-decoration: underline;
      color: #363636;
    }
  }
}
