.list-cards-2-main-container-parent {
  //border-collapse: separate !important;
  border-collapse: collapse;
  border-spacing: 0;

  width: 100%;
  &:has(.list-cards-2-body-loading) {
    height: 100%;
  }

  //Header Part
  th {
    position: sticky;
    top: 0;
    background: #0e1216;
    z-index: 30;
    height: 40px;
    border-bottom: 2px solid #1b232b;
  }

  //Header and Body Padding Setting
  th:first-child {
    padding-left: 16px;
  }

  th:last-child {
    padding-right: 16px;
  }

  .list-cards-2-header-column-wrapper {
    display: flex;
  }
  .list-cards-2-header-items {
    display: flex;
    gap: 5px;
    &:has(.sortable-icon-container) {
      &:hover {
        cursor: pointer;
        filter: brightness(1.2);
      }
    }
    .text {
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 20px;
      align-items: center;
      letter-spacing: -0.01em;
      color: #788496;
      user-select: none;
    }
    .sortable-icon-container {
      display: flex;
      justify-content: center;
      flex-direction: column;
      gap: 3px;
      .icon-up {
        font-size: 5px;
      }
      .icon-up.selected {
        font-size: 5px;
        filter: brightness(1.8);
      }
      .icon-down {
        font-size: 5px;
      }
      .icon-down.selected {
        font-size: 5px;
        filter: brightness(1.8);
      }
    }
  }

  //Without Data
  .without-data-container {
    width: 100%;
    height: 100%;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .list-cards-2-cell-container:first-child {
    padding-left: 16px;
  }

  .list-cards-2-cell-container:last-child {
    padding-right: 16px;
  }

  //Body
  .list-cards-2-body {
    .fly-loading-container {
      display: flex;
      flex-direction: column;
      width: 100%;
      height: 50px;
      justify-content: center;
      align-items: center;
      margin: 50px 0;
    }

    .list-cards-2-body-row {
      position: relative;
      transition: 0.3s all ease-in;
      border-bottom: 1px solid #1b232b;

      .with-click-icon {
        position: absolute;
        opacity: 0;
        font-size: 24px;
        top: 0;
        height: 24px;
        bottom: 0;
        margin: auto 0;
        right: 10px;
        transition: 0.3s all ease-in;
      }

      &:hover {
        //Row Background Transition Change
        background-color: #161b22;
        cursor: pointer;
        transition: 0.3s all ease-in;

        //With Icon Display Change
        .with-click-icon {
          opacity: 1;
          right: 5px;
        }
      }
    }
  }
}
