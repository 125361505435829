.io-metrics-container {
  background: var(--theme-color-1b232b);
  border-radius: 6px;
  width: 100%;
  height: 328px;
  display: flex;
  flex-direction: column;
  margin-top: 20px;

  .header-metrics {
    margin: 10px 5.5px 10px 40px;
    font-size: 16px;
  }
  .header-metrics-content {
    display: flex;
    align-items: center;
    line-height: 20px;
    margin-top: 10px;
  }
  .io-chart-container {
    margin: 0 32px;
  }
}

.io-content-container {
  .io-content-header {
    display: flex;
    align-self: flex-start;
    .io-content-header-text {
      font-family: 'Inter';
      font-style: normal;
      font-weight: 700;
      font-size: 14px;
      line-height: 20px;
    }
  }

  .io-info-text {
    text-align: left;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    align-self: flex-start;
  }

  .io-content-body {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    padding: 0;
    margin: 0;

    .y-axis-and-graph-cont {
      display: flex;
      gap: 0px;
      align-items: center;
      width: 250px;
    }

    .io-info-graph {
      width: 175px;
      height: 75px;
      border-bottom: 1px solid var(--theme-color-ffffff);
      border-left: 1px solid var(--theme-color-ffffff);
      display: flex;
      justify-content: center;
      margin-left: -30px;
    }
    .y-axis-text {
      font-size: 10px;
      transform: rotate(270deg);
      height: 12px;
      width: 80px;
    }
  }
}
