.project-selection-container {
  .first-project-input-in-box {
    width: 100%;
    max-width: 400px;
    height: 36px;
    border-radius: 3px;
    margin-bottom: 4px;

    input {
      background-color: var(--theme-color-151a20);
      font-size: 14px;
      color: var(--theme-color-dcdfe5);
      border: 2px solid var(--theme-color-2f353d);

      &::placeholder {
        color: var(--theme-color-3e4d61);
        font-size: 14px;
      }

      &:focus {
        border: 2px solid var(--theme-color-1d43dd);
      }

      &:hover {
        background: var(--theme-color-21262d);
      }
    }
  }

  .project-actions-container {
    margin-right: 8px;
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;

    .action {
      display: inline-block;
      padding: 8px;

      &.create {
        color: var(--theme-color-1e90ff);
        font-size: 12px;
        font-weight: 500;
        line-height: 16px;

        &:hover {
          color: var(--theme-color-73baff);
          text-decoration: underline;
          cursor: pointer;
        }
      }

      .button {
        font-weight: 500;
      }

      .change {
        background-color: var(--theme-color-1b232b);
        color: var(--theme-color-b2baca);
        font-family: 'Inter';
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 16px;
        align-items: center;
        text-align: center;
        letter-spacing: -0.005em;
        margin: 0;

        &.disabled {
          background-color: var(--theme-color-151a20);
          color: var(--theme-color-48576a);
        }

        &:hover {
          background-color: var(--theme-color-222b35);
        }
      }
    }
  }

  .error-input-message {
    color: var(--theme-color-ff5630);

    i {
      margin-right: 2px;
    }
  }

  .first-project-input-label {
    color: var(--theme-color-8792a3);
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    margin-bottom: 4px;
  }

  .connect-repository-create-project-form {
    min-width: 600px;
    &.ui.form {
      .fields {
        margin: 0;

        label {
          font-size: 12px;
          color: var(--theme-color-788496);
          font-weight: 600;
          height: 16px;
          margin-bottom: 4px;
        }

        .error-input {
          border: 1px solid var(--theme-color-ff5630);
          border-radius: 3px;
        }

        .first .ui.input input {
          background-color: var(--theme-color-151a20);

          &:hover {
            background-color: var(--theme-color-151a20);
          }
        }

        .ui.input input {
          width: 480px;
          background-color: var(--theme-color-0e1216);
          border: 2px solid var(--theme-color-2f353d);
          height: 36px;
          color: var(--theme-color-dcdfe5) !important;
          font-size: 14px;
          padding: 8px;

          &::placeholder {
            color: var(--theme-color-3e4d61);
            font-size: 14px;
          }

          &:focus {
            border: 2px solid var(--theme-color-1d43dd);
          }
        }
      }

      .error-message {
        color: var(--theme-color-ff5630);
        font-size: 10px;
        font-weight: 500;
        line-height: 16px;

        &.hidden {
          visibility: hidden;
        }

        &.visible {
          visibility: visible;
        }
      }
    }
  }

  .create-non-first-project-container {
    border-radius: 3px;
    background-color: var(--theme-color-181b1f);
    padding: 16px 0 16px 16px;
    max-width: 880px;
  }

  .created-project-name-card-container {
    .project-actions-container {
      margin-right: 8px;
      width: 100%;
      display: flex;
      justify-content: flex-end;
      align-items: center;

      .action {
        display: inline-block;
        padding: 8px;

        &.create {
          color: var(--theme-color-1e90ff);
          font-size: 12px;
          font-weight: 500;
          line-height: 16px;

          &:hover {
            color: var(--theme-color-73baff);
            text-decoration: underline;
            cursor: pointer;
          }
        }

        .button {
          font-weight: 500;
        }

        .change {
          background-color: var(--theme-color-1b232b);
          color: var(--theme-color-b2baca);
          margin: 0;

          &.disabled {
            background-color: var(--theme-color-151a20);
            color: var(--theme-color-48576a);
          }

          &:hover {
            background-color: var(--theme-color-222b35);
          }
        }
      }
    }

    .created-project-name-card {
      height: 40px;
      padding-left: 16px;
      display: flex;
      align-items: center;
      max-width: 880px;
      border-radius: 3px;
      background: #181b1f;

      &:hover {
        box-shadow: 0px 8px 16px -2px rgba(9, 10, 12, 0.56), 0px 0px 1px rgba(9, 10, 12, 0.4);
        border-radius: 3px;
      }

      &:focus {
        border: 2px solid var(--theme-color-1d43dd);
      }

      .created-project-name {
        font-size: 14px;
        min-width: 370px;
      }
    }
  }

  .select-project-card {
    background-color: var(--theme-color-262b31);
    height: 40px;
    border-radius: 3px;
    box-shadow: 0px 1px 1px var(--theme-color-rgba_7_10_12_024), 0px 0px 1px var(--theme-color-rgba_7_10_12_04);
    padding: 8px 14px;
    display: flex;
    align-items: center;
    cursor: pointer;
    border: 2px solid transparent;

    span {
      font-size: 14px;
      line-height: 20px;
      width: 100%;
    }

    &:hover {
      border: 2px solid var(--theme-color-1d43dd);
    }
  }

  .select-project-dropdown-container {
    background-color: var(--theme-color-181b1f);
    padding: 16px 16px 24px 16px;
    padding-right: 160px;
    max-width: 880px;
    border-radius: 3px;

    .ui.dropdown .menu {
      padding-top: 10px;
    }

    .project-list-dropdown {
      width: 100%;
      border-radius: 3px;
      background-color: var(--theme-color-181b1f);
      border: 2px solid var(--theme-color-254be2);
      padding-left: 0;
      padding-right: 0;
      margin-bottom: 16px;
      max-width: 688px;

      .text {
        padding-left: 4px;
        color: var(--theme-color-dcdfe5) !important;
        font-size: 14px;
        line-height: 20px;
      }

      .dropdown.icon {
        color: var(--theme-color-b2baca);
        font-size: 14px;
      }

      &:hover {
        border: 2px solid var(--theme-color-254be2);
      }
    }

    .ui.dropdown .menu {
      position: unset;
      background-color: transparent;
      border: 0;
      z-index: unset;
      padding-left: 2px;
    }

    .ui.selection.dropdown {
      padding-bottom: 10px;
      border: 2px solid var(--theme-color-254be2);

      .visible.menu {
        max-height: 74px;
        margin: 0;
        padding-left: 0;
        padding-right: 3px;

        & > .item {
          padding: 6px 0 !important;

          &.active {
            font-weight: normal;

            &.selected.item {
              background-color: var(--theme-color-1d43dd);

              .text {
                color: var(--theme-color-ffffff);
              }
            }
          }

          &:hover {
            background-color: var(--theme-color-1c222a);
          }
        }
      }
    }
  }

  .connect-repositories-page-button-wrapper {
    .cancel-button {
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
      text-align: center;
      background-color: var(--theme-color-1b232b);
      color: var(--theme-color-b2baca);
      border-radius: 3px;
      margin-right: 8px;

      &:hover {
        background-color: var(--theme-color-222b35);
      }
    }

    .create-project-button {
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
      text-align: center;
      background-color: var(--theme-color-1d43dd);
      color: var(--theme-color-ffffff);
      border-radius: 3px;

      &:hover {
        background-color: var(--theme-color-4367fd);
      }
    }
  }
}
