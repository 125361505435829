.wf-run-summary-container {
  .wf-run-job-summary-name-cont {
    padding: 8px 16px;
    border: 1px solid #2f353d;
    border-radius: 6px;
    gap: 8px;
    display: flex;
    flex-direction: row;
    align-items: center;
    .icon-status-success {
      font-size: 16px;
    }
    .icon-status-timed-out {
      font-size: 16px;
    }
    .text-cont {
      font-size: 16px;
      line-height: 20px;
      align-items: center;
      color: #dcdfe5;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      width: 165px;
    }
  }

  .mini-card-container {
    .wf-run-job-test-summary-info-cont {
      display: flex;
      gap: 16px;
      .wf-run-job-test-summary-info {
        display: flex;
        align-items: center;
        gap: 5px;
        margin-top: 5px;
        .wf-run-job-test-count {
          font-family: 'Inter';
          font-style: normal;
          font-weight: 600;
          font-size: 16px;
          line-height: 20px;
          color: #dcdfe5;
        }
      }
      .test-run-check {
        font-size: 16px;
        &::before {
          color: #2dcd8a;
        }
      }
      .test-run-failed {
        font-size: 16px;
        &::before {
          color: #ff5630;
        }
      }
    }
    .wf-run-job-sm-btn {
      width: 100%;
      align-items: center;
      display: flex;
      justify-content: center;
      gap: 5px;
    }
  }
  .job-metric-container {
    display: flex;
    flex-direction: column;
    gap: 10px;
    .memory-usage-active-job {
      .value {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 20px;
        color: #dcdfe5;
      }
      .sub-text {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 16px;
        letter-spacing: -0.005em;
        color: #5c697c;
      }
    }
  }
  .step-process {
    margin-top: 14px;
    .link-text {
      font-family: 'Inter';
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 16px;
      display: flex;
      align-items: center;
      letter-spacing: -0.005em;
      color: #1e90ff;
      &:hover {
        filter: opacity(1.3);
        cursor: pointer;
        text-decoration: underline;
      }
    }
  }
}
