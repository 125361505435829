.latest-workflows {
  margin: 10px 10px 0 0;
  cursor: pointer;
}

.still-progress {
  cursor: not-allowed;
}

.link-wrapper-hover {
  &:hover {
    text-decoration: none;
  }
}
