.single-cost-chart-title {
  margin-bottom: 20px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: -0.012em;
  color: var(--theme-color-dcdfe5);
  padding: 0px 0px 0px 22px;
}
