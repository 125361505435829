.invite-modal {
  .invite-modal-header {
    font-size: 20px;
    font-weight: 500;
    line-height: 24px;
    color: var(--theme-color-dcdfe5);
    margin-bottom: 24px;
  }

  .invite-modal-text {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: var(--theme-color-b2baca);
    margin-bottom: 24px;
  }

  .add-user-button {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: var(--theme-color-1e90ff);

    &:hover {
      color: var(--theme-color-73baff);
      text-decoration: underline;
    }
  }

  .invite-input-container {
    display: flex;
    justify-content: left;
    align-items: center;
    margin-bottom: 16px;

    .minus-line-container {
      background-color: transparent;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 32px;
      width: 32px;
      margin-left: 16px;

      .minus-line {
        height: 2px;
        width: 12px;
        background-color: var(--theme-color-ffffff);
      }

      &:hover {
        border-radius: 3px;
        background-color: var(--theme-color-2f353d);
      }
    }

    .invite-input {
      width: 480px;
      height: 40px;

      input {
        background-color: var(--theme-color-151a20);
        border: 2px solid var(--theme-color-2f353d);
        border-radius: 3px;
        padding: 0 8px;
        font-size: 14px;

        &::placeholder {
          color: var(--theme-color-3e4d61);
          font-size: 14px;
        }
      }

      &.error-email {
        input {
          border: 2px solid red;
        }
      }
    }
  }

  .action-container {
    text-align: end;
    margin-top: 24px;

    .cancel-button {
      background-color: transparent;
      color: var(--theme-color-96a0b0);
      font-weight: 500;
      padding: 6px 12px;
      font-size: 14px;

      &:hover {
        background-color: var(--theme-color-1b232b);
      }
    }

    .submit-button {
      margin-left: 8px;
      background: var(--theme-color-1d43dd);
      color: var(--theme-color-ffffff);
      border-radius: 3px;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 21px;
      align-items: center;
      text-align: center;

      &:hover {
        background-color: var(--theme-color-4367fd);
      }
    }
  }
}
