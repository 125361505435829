.branch-tooltip-list-item {
  text-decoration: none;
  list-style-type: none;
  margin-bottom: 5px;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 150px;
  white-space: nowrap;
}
.main-header-cont-hg {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
  width: 100%;
  gap: 0px;

  .main-header-sub-wrapper {
    max-width: 1200px;
    width: 100%;
  }

  .breadcrumb-menu-cont {
    width: 1200px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .ui.breadcrumb.menu-breadcrumb {
      margin: 0;
    }

    .highlights-filter-container {
      display: flex;
      align-items: center;
      gap: 15px;
      .filter-clear-btn {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 16px;
        color: #96a0b0;
        padding: 8px 12px;

        &:hover {
          cursor: pointer;
          background: #1b232b;
          border-radius: 3px;
          transition: all 0.3s linear;
          padding: 8px 12px;
        }
      }
      .vertical-line {
        border-right: 1px solid #2f353d;
        height: 21px;
        background-color: #2f353d;
      }
    }

    .highlights-filter-btn {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 8px 12px;
      gap: 4px;
      width: 74px;
      height: 32px;
      .icon-filter-icon {
        height: 18px;
        font-size: 16px;
        margin: 2px;
      }
    }
    .ui.primary.button {
      background: #222b35;
      border-radius: 3px;
      font-family: 'Inter';
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      line-height: 16px;
      margin-right: 8px;
      &:hover {
        filter: brightness(1.2);
      }
    }
  }
}

.highlights-page-row-container {
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  height: calc(100vh - 185px);

  .highlights-page-first-row {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-bottom: 20px;
    width: 100%;
    gap: 0px;
  }

  .summaryOfLatest10Workflows {
    width: 840px;
  }

  .button-group-main-container {
    display: flex;
    justify-content: center;
    position: sticky;
    top: -16px;
    z-index: 1;
    background-color: var(--theme-color-0e1217);
  }

  .button-subgroup-container {
    width: 1200px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid var(--theme-color-1b232b);
  }

  .ui.buttons {
    > .ui.button {
      background-color: var(--theme-color-1b232b);
      color: #b2baca;
      transition: background-color 0.2s ease-in-out;
      font-size: 16px;
    }
    .ui.button:hover {
      background-color: var(--theme-color-1b232b);
      transition: background-color 0.2s ease-in-out;
    }
    .ui.button:focus {
      background-color: var(--theme-color-163dd4);
      color: #fff;
      transition: background-color 0.2s ease-in-out;
    }

    .ui.active.button {
      background-color: var(--theme-color-163dd4);
      color: #fff;
      transition: background-color 0.2s ease-in-out;
    }
  }

  #status,
  #cost,
  #duration {
    background-color: transparent;
    color: #dcdfe5;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    border-bottom: 2px solid transparent;
    line-height: 25px;
  }

  #cost.active,
  #duration.active {
    border-bottom: 2px solid var(--theme-color-4367fd);
    border-radius: 0;
  }
  #status.active {
    border-bottom: 2px solid var(--theme-color-4367fd);
    border-radius: 0;
  }

  #last24hours,
  #sevenDays,
  #last30Days {
    font-size: 12px;
  }

  .button-time-groups-container {
    margin-bottom: -13px;
  }

  .countOf30DaysWorkflows {
    width: 360px;
  }

  .title-of-no-data {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: -0.01em;
    color: #dcdfe5;
  }
}

.main-header-hg {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  color: var(--theme-color-dcdfe5);
  width: 1200px;
}

@media (max-width: 480px) {
  .main-header-cont-hg {
    display: flex !important;
    flex-direction: column !important;
    align-items: flex-start !important;
    .main-header-sub-wrapper {
      max-width: 100%;
      width: 100%;
    }
    .breadcrumb-menu-cont {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .ui.breadcrumb.menu-breadcrumb {
        margin: 0;
      }
    }
  }
  .highlights-page-row-container {
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    overflow-x: hidden;
    height: calc(100vh - 185px);
    .summaryOfLatest10Workflows {
      width: 100%;
    }
    .button-group-main-container {
      .button-subgroup-container {
        width: 100%;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        border-bottom: none;
        gap: 5px;
      }
      .button-time-groups-container {
        margin-bottom: 10px;
      }
    }

    .highlights-page-first-row {
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin-bottom: 20px;
      width: 100%;
      gap: 0px;
    }
  }
}
