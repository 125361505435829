.team-organization-lists-wrapper {
  .team-organization-list-tabs {
    .about-organization-wrapper {
      display: flex;
      flex-direction: column;
      .input-cont-team-org {
        width: 35%;
      }
    }

    .table-header-wrapper {
      display: flex;
      justify-content: space-between;

      .table-header {
        font-size: 16px;
        line-height: 20px;
        font-weight: 600;
      }
    }

    .organization-tab-pane {
      .tab-header {
        margin-bottom: 18px;
      }

      .action-wrapper {
        margin-top: 16px;
      }
    }
  }

  .foresight-table {
    .icons-wrapper {
      height: 32px;
      width: 32px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 3px;

      i {
        font-size: 18px;
      }

      .icon-more-vertical {
        display: inline;
      }

      .icon-more-vertical-white {
        display: none;
      }

      &:hover {
        .icon-more-vertical {
          display: none;
        }

        .icon-more-vertical-white {
          display: inline;
        }

        background-color: var(--theme-color-rgba_120_132_150_032);
        cursor: pointer;
      }
    }

    .user-table-fullname-cell {
      display: flex;
      align-items: center;

      .text-cell {
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        color: var(--theme-color-dcdfe5);
        display: flex;
        align-items: center;

        .avatar-owner-img {
          height: 12px;
          width: 12px;
          margin-left: 8px;
        }
      }

      .user-letter-avatar {
        border-radius: 50%;
        background-color: var(--theme-color-b2baca);
        height: 24px;
        width: 24px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 20px;
        color: var(--theme-color-0e1216);
      }

      .avatar-icon {
        font-size: 24px;
        margin-right: 20px;
      }

      .user-invited-tag {
        background-color: var(--theme-color-rgba_120_132_150_024);
        color: var(--theme-color-d9d9d9);
        padding: 2px 4px;
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        margin-left: 16px;
      }
    }
  }
}

#team-organization-dropdown-parent {
  align-content: center;
  background-color: var(--theme-color-151a20);
  width: 508px;
  padding: 0;
  border: 1px solid var(--theme-color-292f38);
  box-shadow: 1px 2px 8px var(--theme-color-rgba_10_13_16_32), 2px 4px 16px var(--theme-color-rgba_10_13_16_24),
    4px 8px 16px var(--theme-color-rgba_10_13_16_16);
  border-radius: 3px;
  .team-organization-dropdown {
    .title {
      padding: 20px 0 0 16px;
      span {
        font-style: normal;
        font-weight: bold;
        font-size: 11px;
        line-height: 16px;
        color: var(--theme-color-6a7689);
      }
    }

    .item-delimiter {
      border-bottom: 2px solid var(--theme-color-30363d);
      margin: 6px 0;
    }

    .item {
      padding: 10px 8px 10px 16px;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      letter-spacing: -0.01em;
      color: var(--theme-color-b2baca);

      &.item-last {
        margin-bottom: 6px;
      }

      &:hover {
        cursor: pointer;
        background: var(--theme-color-rgba_220_223_229_008);
      }
    }
  }
}

@media (max-width: 480px) {
  .team-organization-lists-wrapper {
    .team-organization-list-tabs {
      .about-organization-wrapper {
        .input-cont-team-org {
          width: 100%;
        }
      }
    }
  }
}
