.playground-page-wrapper {
  width: 100%;
  height: 100%;
  overflow: auto;

  background-color: var(--theme-color-404040f);
  // color: var(--theme-color-d4d4d4);
}

.foresight-icons-wrapper {
  font-size: 20px;
}
