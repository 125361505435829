.overview-info-wrapper {
  background: transparent;
  display: flex;
  padding-left: 20px;
  width: 50%;

  .info-row {
    display: flex;
    width: 100%;

    .cursor {
      cursor: pointer;
    }

    .no-link {
      cursor: default;
      text-decoration: none;
      pointer-events: none;
    }

    .info-cell {
      width: 100%;
      margin-right: 8px;
      display: flex;
      flex-direction: column;
      text-decoration: none;

      span.value {
        display: inline-flex;
        align-items: center;

        i.icon {
          color: var(--theme-color-788496);
        }

        i {
          font-size: 16px;
          margin-right: 4px;

          &::before {
            color: var(--theme-color-788496);
          }
        }

        .link-icon-style {
          margin-top: -12px;

          &::before {
            color: var(--theme-color-b2baca);
            margin-left: 10px;
            font-size: 12px;
          }
        }

        span {
          font-weight: 500;
          font-size: 14px;
          line-height: 24px;
          display: flex;
          align-items: center;
          letter-spacing: -0.01em;
          color: var(--theme-color-b2baca);
          display: -webkit-box;
          white-space: normal;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }

      span.value-sub-title {
        font-weight: 600;
        font-size: 12px;
        line-height: 14px;
        color: var(--theme-color-5c697c);
        min-width: 60px;
        &.date {
          min-width: 120px;
        }
      }

      span.link-to-highlights,
      span.link-to-details {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 16px;
        /* identical to box height, or 133% */

        display: flex;
        align-items: center;
        letter-spacing: -0.005em;

        /* Blue/B50 */

        color: #1e90ff;
        cursor: pointer;

        &:hover {
          color: var(--theme-color-73baff);
          text-decoration: underline;
        }
      }

      span.link-to-highlights-disabled {
        visibility: hidden;
      }

      .ghost-value {
        display: none !important;
      }
    }
  }
}

@media (max-width: 480px) {
  .overview-info-wrapper {
    .info-row {
      display: flex;
      flex-wrap: wrap;
      width: 100%;
    }
  }
}
