.no-resource-available-container {
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;

  .image-and-text-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    &.horizontal {
      margin-top: 18px;
      flex-direction: row;
    }

    .text-part {
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      /* identical to box height, or 150% */

      text-align: center;

      &.horizontal {
        display: inline-block;
        margin-left: 12px;
      }

      /* Gray/G100 */

      color: var(--theme-color-788496);
    }

    .image-part {
      &.horizontal {
        display: inline-block;
      }
    }
  }
}

.fly-loading-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;

  .loading-lines {
    margin: 10px auto 0;
    width: 80%;
    height: 4px;
    text-align: center;

    .load-base {
      display: inline-block;
      width: 200px;
      height: 6px;
      border-radius: 6px;
      overflow: hidden;
      position: relative;
      background-color: var(--theme-color-39495d);

      .load-in {
        position: absolute;
        top: 0;
        left: 10px;
        width: 90px;
        height: 6px;
        border-radius: 6px;
        background-color: var(--theme-color-788496);
        -webkit-animation: line-loading 2s infinite;
        -moz-animation: line-loading 2s infinite;
        -o-animation: line-loading 2s infinite;
        animation: line-loading 2s infinite;
      }
    }
  }

  .animated-bird {
    animation: col 4s linear infinite;

    @keyframes col {
      0% {
        fill: linear-gradient(
          90deg,
          rgba(220, 223, 229, 0.48) 0%,
          rgba(220, 223, 229, 0.1536) 44.73%,
          rgba(220, 223, 229, 0.1536) 71.76%,
          rgba(220, 223, 229, 0.0384) 100%
        );
      }
      50% {
        fill: var(--theme-color-232323);
      }
      100% {
        fill: linear-gradient(
          90deg,
          rgba(220, 223, 229, 0.48) 0%,
          rgba(220, 223, 229, 0.1536) 44.73%,
          rgba(220, 223, 229, 0.1536) 71.76%,
          rgba(220, 223, 229, 0.0384) 100%
        );
      }
    }
  }

  .image-and-text-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .text-part {
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      /* identical to box height, or 150% */

      text-align: center;

      /* Gray/G100 */

      color: var(--theme-color-788496);
    }
  }
}

.gain-more-visibility-test-container {
  display: flex;
  flex-direction: row;
  height: 80px;
  width: 100%;
  padding: 12px 10px;
  background: var(--theme-color-151a20);
  border-radius: 3px;
  justify-content: space-between;

  .left-part {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;

    .icon-part {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;

      i {
        color: var(--theme-color-8792a3);
        font-size: 12px;
        margin: 2px 10px;
      }
    }

    .text-part {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;

      .text-part-row-1 {
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 16px;
        color: var(--theme-color-788496);
        margin-bottom: 0px;
      }

      .text-part-row-2 {
        font-style: normal;
        font-weight: normal;
        font-size: 11px;
        line-height: 16px;
        color: var(--theme-color-5c697c);
        margin-bottom: 6px;
      }

      .text-part-row-3 {
        font-style: normal;
        font-weight: normal;
        font-size: 11px;
        line-height: 16px;
        display: flex;
        align-items: center;
        text-decoration-line: underline;
        color: var(--theme-color-4367fd);
      }
    }
  }

  .right-part {
    font-style: normal;
    font-weight: normal;
    font-size: 11px;
    line-height: 16px;
    /* identical to box height, or 145% */

    display: flex;
    align-items: flex-end;
    text-align: right;

    /* Gray/G300 */

    color: var(--theme-color-5c697c);
  }
}

.list-nodata-wrapper {
  .list-nodata-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 24px 0px;
    gap: 24px;
    width: 100%;
    height: 100%;
    overflow-y: scroll;
    flex: none;
    order: 1;
    align-self: stretch;
    flex-grow: 0;

    .list-nodata-center-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 32px;
      width: 600px;
      height: 368px;
      border-radius: 3px;
      order: 0;
      flex-grow: 0;

      .img-container {
        width: 80px;
        height: 80px;
      }

      .content-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 16px 0px 0px;
        width: 536px;
        height: 144px;
        flex: none;
        order: 1;
        align-self: stretch;
        flex-grow: 0;

        .header {
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          padding: 16px 0px;
          width: 536px;
          height: 56px;

          font-style: normal;
          font-weight: 600;
          font-size: 20px;
          line-height: 24px;
          letter-spacing: -0.006em;
          color: #dcdfe5;
        }

        .text-container {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          padding: 0px;
          gap: 12px;

          .text2 {
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 20px;
            text-align: center;
            letter-spacing: -0.004em;
            color: #dcdfe5;
          }

          .text {
            display: flex;
            flex-direction: column;
            align-items: center;

            div {
              font-style: normal;
              font-weight: 500;
              font-size: 14px;
              line-height: 20px;
              text-align: center;
              letter-spacing: -0.004em;
              color: #dcdfe5;
            }

            ul {
              text-align: center;
              list-style-position: inside;
              li {
                font-style: normal;
                font-weight: 500;
                font-size: 14px;
                line-height: 20px;
                text-align: center;
                letter-spacing: -0.004em;
                color: #dcdfe5;
              }
            }
          }
        }
      }
    }
  }
}
