.job-list-wrapper {
  .job-item {
    padding: 8px 16px;
    margin-bottom: 4px;
    border-radius: 3px;
    color: var(--theme-color-b2baca);
    display: flex;
    align-items: center;

    span {
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      letter-spacing: -0.01em;
    }

    svg {
      width: 15px;
      min-width: 15px;
      height: 15px;
      margin-right: 8px;
    }

    .job-name {
      max-width: 280px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .job-duration {
      margin-left: auto;
      min-width: 60px;
      text-align: right;
    }

    &.selected {
      background-color: var(--theme-color-1c222a);
      color: var(--theme-color-ffffff);
    }

    &:hover {
      background-color: var(--theme-color-1c222a);
      cursor: pointer;
    }
  }
}
