@import 'assets/styles/size.scss';

$topBarHeight: 56px;
$topBarHeightWithWarning: 108px;
$screenSizeMessageHeight: 39px;
$topBarDemoEnvHeight: 66px;

.main-layout-page-container {
  height: calc(100% - #{$topBarHeight});
  padding: 16px 60px;
  overflow: auto;

  &.fullscreen-sticky-table-header {
    padding: 0px 60px;
  }
}

.main-layout-page-container-with-warning {
  height: calc(100% - #{$topBarHeightWithWarning});
  padding: 16px 60px;
  overflow: auto;

  &.fullscreen-sticky-table-header {
    padding: 0px 60px;
  }
}

.main-layout-page-container-demo {
  height: calc(100% - #{$topBarDemoEnvHeight});
  padding: 16px 60px;
  overflow: auto;

  &.fullscreen-sticky-table-header {
    padding: 0px 60px;
  }
}

.screen-size-message {
  display: none;
}

@media (max-width: 900px) {
  .screen-size-message {
    height: 39px;
    display: block;
    padding: $spacing;
    color: var(--theme-color-9f3a38);
    background-color: var(--theme-color-fff6f6);
  }

  .main-layout-page-container {
    height: calc(100% - #{$topBarHeight + $screenSizeMessageHeight});
    padding: 16px 60px;
  }
}

.toast-content {
  .toast-image-wrapper {
    display: flex;
    justify-content: flex-start;
    align-items: center;

    img {
      margin-right: 10px;
    }
  }

  &.Toastify__toast {
    padding: 12px;
    text-align: left;
    border-radius: 4px;

    &.Toastify__toast--success {
      border: solid var(--theme-color-00764f) 1px !important;
      background-color: var(--theme-color-00764f) !important;
      color: white !important;
    }

    &.Toastify__toast--warning {
      border: solid var(--theme-color-d07002) 1px;
      background-color: var(--theme-color-e98715);
      color: white;
    }

    &.Toastify__toast--error {
      border: solid var(--theme-color-e63c3c) 1px;
      background-color: var(--theme-color-f26c6c);
      color: white;
    }

    .Toastify__toast-body {
      &::before {
        content: none;
      }
    }
  }

  // We remove the x char from the toast.
  .Toastify__close-button {
    display: none;
  }

  .Toastify__close-button.Toastify__close-button--success {
    color: var(--theme-color-ffffff);
  }

  .Toastify__close-button.Toastify__close-button--error {
    color: var(--theme-color-ffffff);
  }

  .Toastify__progress-bar {
    height: 2px;

    &.Toastify__progress-bar--success {
      background-color: var(--theme-color-ffffff) !important;
    }

    &.Toastify__progress-bar--error {
      background-color: var(--theme-color-e63c3c);
    }
  }
}

@media (max-width: 480px) {
  .main-layout-page-container {
    height: calc(100% - 80px);
    padding: 16px 15px;
  }

  .main-layout-page-container-with-warning {
    height: calc(100% - 80px);
    padding: 16px 16px;
    overflow: auto;
  }

  .main-layout-page-container-demo {
    height: calc(100% - 80px);
    padding: 16px 15px;
  }
}
