.jobs-list-wrapper {
  height: 350px;
  overflow-y: auto;
  .job-item {
    padding: 8px 16px;
    margin-bottom: 4px;
    border-radius: 3px;
    color: var(--theme-color-b2baca);
    display: flex;
    align-items: center;

    span {
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      letter-spacing: -0.01em;
    }

    svg {
      width: 15px;
      min-width: 15px;
      height: 15px;
      margin-right: 8px;
    }

    .job-duration {
      margin-left: auto;
      min-width: 60px;
      text-align: right;
    }

    &.selected {
      background-color: rgba(255, 255, 255, 0.06);
      color: var(--theme-color-ffffff);
    }

    &:hover {
      background-color: rgba(255, 255, 255, 0.06);
      cursor: pointer;
    }
  }
}

.workflow-job-steps-wrapper {
  width: 100%;
  background-color: var(--theme-color-070a0c);
  border-radius: 3px;

  .selected-job-title {
    padding: 18px 24px 15px 24px;
    border-bottom: 1px solid var(--theme-color-222b35);
    display: flex;
    flex-direction: column;

    .title {
      font-weight: 500;
      font-size: 16px;
      line-height: 20px;
      letter-spacing: -0.01em;
      color: var(--theme-color-dcdfe5);
    }

    .title-info {
      font-weight: 500;
      font-size: 12px;
      line-height: 16px;
      letter-spacing: -0.005em;
      color: var(--theme-color-b2baca);
    }
  }

  .job-steps-wrapper {
    padding: 6px 24px;

    .step-item {
      padding: 10px 0;
      height: 54px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .left-side {
        display: flex;
        .step-item-icon {
          margin-top: 3px;
        }
      }
      svg {
        font-size: 15px;
        margin-right: 8px;
      }

      span {
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: -0.01em;
        color: var(--theme-color-b2baca);
      }

      .progress-bar-wrapper {
        width: 120px;
        height: 7px;
        margin: auto 0;

        #progress-bar {
          width: 120px;
          height: 7px;
          background-color: var(--theme-color-1c222a);
          border-radius: 3px;
          margin: auto 0;

          .bar {
            height: 6px;
            background-color: var(--theme-color-b2baca);
            border-radius: 3px 0px 0px 3px;
            min-width: 0px;
          }
        }
      }
    }
  }
}

.workflow-jobs-widget-title {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  color: var(--theme-color-96a0b0);
  padding: 14px 5px;
}

@media (max-width: 480px) {
  .job-steps-wrapper {
    .step-item {
      .left-side {
        .step-name {
          width: 60px;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
        }
      }
    }
  }
}
