.cancel-plan-container {
  .cancel-plan-content {
    width: 800px;
    height: 64px;
    border: 1px solid #fb4932;
    border-radius: 6px;
    display: flex;
    align-items: center;
    gap: 15px;
    padding: 16px;
    justify-content: space-between;

    .cancel-plan-content-header-info {
      font-family: 'Inter';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: #c9d1d9;
    }
  }
}
.cancel-plan-modal-container {
  display: flex;
  flex-direction: column;
  .cancel-plan-modal-header {
    display: flex;
    gap: 10px;
    .icon-warning {
      font-size: 24px;
    }
    .cancel-plan-modal-header-title {
      font-family: 'Inter';
      font-style: normal;
      font-weight: 600;
      font-size: 20px;
      line-height: 24px;
      letter-spacing: -0.016em;
      color: #b2baca;
    }
  }
  .cancel-plan-modal-content {
    display: flex;
    flex-direction: column;
    margin: 14px 0;
    .cancel-plan-modal-content-header-info {
      font-family: 'Inter';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: #b2baca;
      margin-top: 5px;
    }
    .cancel-plan-modal-content-header-info-footer {
      font-family: 'Inter';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: #b2baca;
    }
    .cancel-plan-list {
      font-family: 'Inter';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: #b2baca;
    }
  }
  .cancel-plan-modal-footer {
    display: flex;
    justify-content: flex-end;
    gap: 5px;
  }
}
