.foresight-accordion-container {
  display: flex;
  flex-direction: column;
  cursor: pointer;
  &:hover {
    background-color: #1b232b;
  }

  &.opened {
    background-color: #1b232b;
  }

  .foresight-accordion-header-container {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0px;
    width: 100%;

    .foresight-accordion-header-left-container {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: 0px;
      width: calc(100% - 124px);
    }

    .foresight-accordion-header-right-container {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 0px 24px;
      width: 124px;
      height: 48px;
      margin: auto;
      i {
        font-size: 15px;
        font-weight: 700;
        color: #788496;
      }
    }
  }

  .foresight-accordion-content-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
  }
}

/* Accordion With Animation CSS */

.foresight-accordion-container-with-animation {
  display: flex;
  flex-direction: column;
  cursor: pointer;
  padding: 16px 0px 16px 16px;
  border-radius: 2px;
  gap: 0;
  transition: gap 0.2s linear;
  border-bottom: 1px solid #1b232b;

  &:hover {
    background-color: #1b232b;
  }

  &.opened {
    background-color: transparent;
    gap: 20px;
    transition: gap 0.2s linear;
  }

  .foresight-accordion-header-container-with-animation {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0px;
    width: 100%;

    .foresight-accordion-header-left-container {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: 0px;
      width: calc(100% - 124px);
    }

    .foresight-accordion-header-right-container {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 0px 24px;
      width: 124px;
      height: 48px;
      margin: auto;
      .icon-chevron-down {
        font-size: 15px;
        font-weight: 700;
        color: #788496;
        transform: rotateZ(0deg);
        transition: 0.3s transform ease-in-out;
      }
      .icon-chevron-down.translate {
        font-size: 15px;
        font-weight: 700;
        color: #788496;
        transform: rotateZ(180deg);
        transition: 0.3s transform ease-in;
      }
    }
  }

  .foresight-accordion-content-container-with-animation {
    display: block;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.2s ease-in-out;

    &.closed-content {
      display: flex;
      height: auto;
      max-height: 300px;
      overflow: auto;
      transition: max-height 0.4s ease-in-out;
    }
    &.closed-content-not-scroll {
      display: flex;
      height: auto;
      max-height: 1300px;
      overflow: auto;
      transition: max-height 0.4s ease-in-out;
    }
  }
}
