.workflow-card-container {
  width: calc(100% - 20px);
  height: 248px;
  background: #1b232b;
  border-radius: 6px;
  margin-right: 12px;
  margin-bottom: 24px;
  padding: 12px;

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .period {
      font-style: normal;
      font-weight: normal;
      font-size: 11px;
      line-height: 16px;
      /* identical to box height, or 145% */

      display: flex;
      align-items: center;
      text-align: center;
      letter-spacing: -0.01em;

      /* Gray/G100 */
      color: var(--theme-color-788496);
    }
  }
  .title {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    /* identical to box height, or 143% */

    display: flex;
    align-items: center;
    letter-spacing: -0.01em;

    /* Light/L50 */

    color: var(--theme-color-dcdfe5);
  }
}
.repository-title-container {
  & > div {
    color: var(--theme-color-dcdfe5);
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    letter-spacing: -0.04em;
    display: flex;
    align-items: center;
    height: 100%;
  }
}

.sub-header {
  font-style: normal;
  font-weight: normal;
  font-size: 11px;
  line-height: 16px;
  /* identical to box height, or 145% */

  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: -0.01em;

  color: var(--theme-color-788496);
  padding: 4px 0px;
}

.headings {
  position: absolute;
}
