.test-platform-dashboard-link-container {
  margin-top: 24px;

  .ui.primary.button {
    background-color: var(--theme-color-1d43dd);

    height: 24px;
    width: 170px;
    border-radius: 3px;

    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    /* identical to box height, or 145% */

    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--theme-color-ffffff);
    cursor: pointer;
    margin-right: 0px;

    i {
      margin-left: 5px;
      font-size: 12px;
    }

    &:hover {
      background-color: var(--theme-color-3459ef)
    }
  }
}



