.test-run-overview-card-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  
  &.test-case{
    border-bottom: 1px solid var(--theme-color-222b35);
  }

  .title {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: baseline;

    .name {
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      line-height: 24px;
      display: flex;
      align-items: center;
      color: var(--theme-color-ffffff);
      margin-bottom: 8px;
      letter-spacing: 0.02em;

      
      .successful {
        color: var(--theme-color-2dcd8a);
      }

      .failed {
        color: var(--theme-color-ff5630);
      }

      .skipped {
        color: var(--theme-color-ff5630);
      }

      .aborted {
        color: var(--theme-color-ff5630);
      }

      i {
        margin-right: 5px;
      }
    }

    .link-buttons {
      cursor: pointer;
      font-style: normal;
      font-weight: normal;
      font-size: 11px;
      line-height: 16px;
      /* identical to box height, or 145% */

      display: flex;
      align-items: center;
      text-align: right;
      color: var(--theme-color-788496);

      span {
        margin: 2px;

        &:hover {
          text-decoration-line: underline;
        }
      }
    }
  }

  .scroll-content {
    overflow-y: auto;
  }
}
