.TextButton.ui.button {
  background-color: transparent;
  color: var(--theme-color-96a0b0);
  font-weight: 500;
  padding: 6px 12px;
  font-size: 14px;

  &:hover {
    background-color: var(--theme-color-1b232b);
  }
}

.GrayBorderButton.ui.button {
  width: 78px;
  height: 24px;
  background: #1c222a;
  border: 1px solid #2f353d;
  border-radius: 3px;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  display: flex;
  align-items: center;
  text-align: right;
  letter-spacing: -0.01em;
  color: #96a0b0;

  &:hover {
    background: #2f353d;
  }
}

.BlueButton.ui.primary.button {
  background: var(--theme-color-1d43dd);
  color: var(--theme-color-ffffff);
  border-radius: 3px;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  align-items: center;
  text-align: center;
  padding: 6px 12px;

  &:hover {
    background-color: var(--theme-color-4367fd);
  }

  &:disabled {
    background-color: var(--theme-color-151a20);
    color: var(--theme-color-48576a);
  }
}

.ToggleButton.ui.button.toggle {
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: -0.01em;
  padding: 4px 6px;
  border-radius: 3px;
  background-color: transparent !important;
  color: var(--theme-color-b2baca) !important;

  &.active {
    color: var(--theme-color-ffffff) !important;
    background-color: var(--theme-color-163dd4) !important;
  }

  &:hover {
    background-color: rgba(255, 255, 255, 0.12) !important;
    color: var(--theme-color-ffffff) !important;
  }
}

.RedButton.ui.secondary.button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 6px 12px;
  gap: 8px;
  width: 102px;
  height: 32px;
  background: #1b232b;
  border: 1px solid #2f353d;
  border-radius: 3px;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #fb4932;
  transition: background 0.2 ease-out, color 0.2s ease-in-out;
  &:hover {
    background: #d73324;
    border: 1px solid #fb4932;
    color: #ffffff;
    cursor: pointer;
  }
}
