.avg-and-total-duration-container {
  display: flex;
  flex-direction: column;

  .avg-and-total-duration-header {
    padding: 6px 0px 6px 32px;
    margin-top: 12px;
    /* H5 Header */
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    /* identical to box height, or 143% */

    letter-spacing: -0.01em;

    /* Light/L50 */

    color: #dcdfe5;
  }

  .avg-and-total-duration-chart-cont {
    display: flex;
    align-items: center;
    margin-bottom: 16px;

    .avg-chart {
      width: 50%;
      padding: 16px 32px 16px 32px;
    }
  }
}

@media (max-width: 480px) {
  .avg-and-total-duration-container {
    .avg-and-total-duration-chart-cont {
      flex-direction: column;
      .avg-chart {
        width: 98%;
        padding: 0;
      }
    }
  }
}
