.success-rate-timeline-chart {
  .chart-title {
    margin-bottom: 20px;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: -0.012em;
    color: var(--theme-color-96a0b0);
    padding: 0px, 0px, 8px, 0px;
  }
}
