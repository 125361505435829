.test-view-duration-and-count-chart-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 16px 32px;
  gap: 12px;
  width: 100%;
  height: 398px;
  background: #1b232b;
  border-radius: 6px;

  .duration-count-chart-title-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 0px;
    gap: 4px;
    width: 100%;
    height: 32px;

    .duration-count-chart-title-left {
      display: flex;
      flex-direction: row;
      align-items: center;
      width: 260px;
      height: 100%;

      .duration-count-chart-title {
        // font-family: 'Inter';
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 20px;
        display: flex;
        align-items: center;
        color: #dcdfe5;
        height: 32px;
      }

      .duration-count-chart-reset {
        line-height: 12px;
        margin-left: 10px;
        border: 1px solid #a9b2cb;
        border-radius: 3px;
        color: #7883a2;
        font-family: 'Inter';
        font-size: 10px;
        font-weight: 500;

        &:hover {
          color: white;
          background-color: #7883a2;
        }
      }
    }

    .duration-count-chart-title-right {
      display: flex;
      gap: 4px;

      .test-view-duration-execution-chart-dropdown-container {
        .foresight-dropdown-container {
          i {
            padding: 3px 8px !important;
          }
        }
      }
    }
  }

  .recharts-wrapper {
    cursor: pointer !important;
    color: #555555;
    font-family: 'Inter';
    font-size: 9px;
    font-weight: 500;
    line-height: 11px;
    text-align: center;
    margin-left: -19px;

    .duration-count-chart-legend {
      width: 100%;
      height: 30px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      .legend-count-part {
        height: 100%;
        width: 50%;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        padding-right: 50px;
        gap: 20px;

        .failure {
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: flex-end;

          .pathFailure {
            height: 7px;
            width: 7px;
            border-radius: 1px;
            background-color: #f85149;
          }

          .titleFailure {
            margin: 0px 5px;
            color: #909090;
            font-size: 11px;
            font-weight: 500;
            line-height: 10px;
          }
        }

        .other {
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: flex-end;

          .pathOther {
            height: 7px;
            width: 7px;
            border-radius: 1px;
            background-color: #8e8d92;
          }

          .titleOther {
            margin: 0px 5px;
            color: #909090;
            font-size: 11px;
            font-weight: 500;
            line-height: 10px;
          }
        }

        .successful {
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: flex-end;

          .pathSuccessful {
            height: 7px;
            width: 7px;
            border-radius: 1px;
            background-color: #238636;
          }

          .titleSuccessful {
            margin: 0px 5px;
            color: #909090;
            font-size: 11px;
            font-weight: 500;
            line-height: 10px;
          }
        }
      }

      .legend-duration-part {
        height: 100%;
        width: 50%;
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        margin-left: 35px;
        gap: 20px;

        .p99 {
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: flex-end;

          .path99 {
            box-sizing: border-box;
            height: 1px;
            width: 6px;
            border: 1px solid #22aabb;
          }

          .title99 {
            margin: 0px 5px;
            color: #909090;
            font-size: 11px;
            font-weight: 500;
            line-height: 10px;
          }
        }

        .p95 {
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: flex-end;

          .path95 {
            box-sizing: border-box;
            height: 1px;
            width: 5.98px;
            border: 1px solid #ffaa22;
          }

          .title95 {
            margin: 0px 5px;
            color: #909090;
            font-size: 11px;
            font-weight: 500;
            line-height: 10px;
          }
        }

        .pAvg {
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: flex-end;

          .pathAvg {
            box-sizing: border-box;
            height: 1px;
            width: 10px;
            border: 1px solid #3459ef;
          }

          .titleAvg {
            margin: 0px 5px;
            color: #909090;
            font-size: 11px;
            font-weight: 500;
            line-height: 10px;
          }
        }
      }
    }
  }

  .recharts-label,
  recharts-text {
    line-height: 35px;
    font-size: 10px;
    color: #555555;
    font-family: 'Inter';
    font-weight: normal;
  }
}

.test-view-chart-tooltip-container {
  background: var(--theme-color-151a20);
  padding: 10px 5px;
  box-shadow: 1px 2px 8px var(--theme-color-rgba_10_13_16_032), 2px 4px 16px var(--theme-color-rgba_10_13_16_024),
    4px 8px 16px var(--theme-color-rgba_10_13_16_016);
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: max-content;
  min-width: 150px;

  .tooltip-header {
    padding: 8px 8px 0px 8px;
    display: flex;
    width: 100%;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    /* identical to box height, or 143% */

    letter-spacing: -0.01em;

    /* Light/L50 */

    color: #dcdfe5;
  }

  ul {
    padding: 8px;
    margin: 0;

    li {
      list-style-type: none;
      padding: 3px 0px;

      .li-item {
        min-height: 20px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-start;

        .legend-icon-duration {
          height: 2px;
          width: 12px;
          border-radius: 1px;
          margin-right: 8px;
        }

        .legend-icon-count {
          height: 12px;
          width: 12px;
          border-radius: 1px;
          margin-right: 8px;
        }

        .legend-name {
          font-style: normal;
          font-weight: 400;
          font-size: 11px;
          line-height: 16px;
          /* identical to box height, or 145% */

          display: flex;
          align-items: center;
          letter-spacing: -0.01em;

          /* Gray/G80 */

          color: var(--theme-color-96a0b0);
          max-width: 350px;
          overflow: hidden;
          white-space: break-spaces;
        }

        .legend-value {
          font-style: normal;
          font-weight: 700;
          font-size: 14px;
          line-height: 20px;
          /* identical to box height, or 143% */

          display: flex;
          align-items: center;
          text-align: center;
          letter-spacing: -0.01em;

          /* Light/L50 */

          color: var(--theme-color-dcdfe5);
        }

        .legend-value-container {
          display: flex;
          flex-direction: column;
          margin-bottom: 12px;

          div {
            font-style: normal;
            font-weight: normal;
            font-size: 12px;
            line-height: 16px;
            letter-spacing: -0.017em;
            color: var(--theme-color-dcdfe5);
          }
        }
      }
    }
  }
}
