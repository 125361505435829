.slack-integration-main-container {
  margin-top: 32px;
}

.label-for-input {
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  display: flex;
  align-items: center;
  letter-spacing: -0.01em;
  color: var(--theme-color-8792A3);
  &::after {
    content: '*';
    color: #cb4040;
  }
}

.notification-edit-link {
  height: 16px;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  display: flex;
  align-items: center;
  color: #1e90ff;
  &:hover {
    color: #73baff;
  }
}

.disabled.notification-edit-link {
  pointer-events: none;
  user-select: none;
  color: #48576a;
}

.integrations-title-text-summary {
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: var(--theme-color-dcdfe5);
}

.refresh-permission-text {
  margin-bottom: 12px;
}

.slack-input-container {
  display: flex;
  align-items: center;
}

.minus-slack-list-icon {
  width: 32px !important;
  height: 32px !important;
  align-items: center;
  display: grid !important;
  margin-left: 26px !important;
  &:hover {
    cursor: pointer;
    border-radius: 3px;
    background-color: rgba(120, 132, 150, 0.32);
  }
}
.slack-notification-button-container {
  margin-left: auto;
  margin-right: 24px;
  align-self: center;
  .ui.button {
    &.cancel-button {
      background-color: transparent;
      border: 0;
      color: var(--theme-color-b2baca);
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
      margin-right: 8px;

      &:hover {
        color: var(--theme-color-B2BACA);
        background-color: var(--theme-color-222b35);
      }
    }
  }
}

.max-limit-text {
  font-size: 12px;
  opacity: 0.5;
}
