.manage-committers-container {
  width: 576px;
  height: 400px;
  background: var(--theme-color-0e1216);
  border: 1px solid var(--theme-color-2f353d);
  box-shadow: 0px 8px 16px -2px rgba(9, 10, 12, 0.56), 0px 0px 1px rgba(9, 10, 12, 0.4);
  border-radius: 6px;
  padding: 24px;

  .manage-committer-list {
    padding: 24px 0px;
    width: 100%;
    overflow-y: auto;
    height: calc(100% - 70px);
    display: flex;
    flex-direction: column;

    .committer-container {
      display: flex;
      align-items: center;
      .committer-img-container {
        .avatar {
          width: 24px;
          height: 24px;
          border-radius: 50%;
          overflow: hidden;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-right: 8px;
          margin-bottom: 8px;
        }
      }

      .committer-username {
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 16px;
        display: flex;
        align-items: center;
        letter-spacing: -0.005em;
        color: var(--theme-color-dcdfe5);
      }
    }
  }
}
