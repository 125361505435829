.provider-box-container {
  &.dark-box {
    background: var(--theme-color-262b31);
    box-shadow: 0 0 1px var(--theme-color-rgba_7_10_12_024), 0 2px 8px -2px var(--theme-color-rgba_7_10_12_04);
  }

  &.ligth-box {
    background: var(--theme-color-0e1216);
    box-shadow: 0 0 1px var(--theme-color-rgba_220_223_229_032), 0 2px 8px -2px var(--theme-color-rgba_220_223_229_024);
  }

  display: flex;
  align-items: center;
  width: 880px;
  border-radius: 8px;

  i {
    font-size: 56px;
  }

  .icon-logo-container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20%;
    max-width: 132px;
    padding: 20px 0;
    border-radius: 8px 0 0 8px;

    &.dark-box {
      background: var(--theme-color-21262d);
    }

    .icon-logo {
      width: 32px;
      height: 32px;
      margin: 8px 0;
    }
  }

  .provider-title-container {
    width: 60%;
    padding-left: 48px;
    .provider-title {
      font-weight: 600;
      font-size: 20px;
      line-height: 24px;
      color: var(--theme-color-f4f5f7);
    }
    .provider-desc {
      font-size: 14px;
      line-height: 20px;
      color: var(--theme-color-dcdfe5);
      width: 536px;
    }
  }

  .connect-container {
    width: 20%;
    text-align: end;

    .primary {
      background-color: var(--theme-color-1d43dd);
      padding: 6px 12px;
      font-weight: 600;
      font-size: 14px;

      &:hover {
        background-color: var(--theme-color-4367fd);
      }
    }

    .coming-soon {
      background-color: var(--theme-color-1b232b);
      color: var(--theme-color-b2baca);
      padding: 6px 12px;
      font-weight: 500;
      font-size: 14px;
      cursor: default;
    }
  }
}
