.integrations-container {
  display: table;
  margin: 0 auto 0 auto;
  max-width: 480px;

  .integrations-list {
    .integration {
      width: 480px;
      height: 96px;
      background: var(--theme-color-1c222a);
      border-radius: 8px;
      display: flex;

      .ci-logo {
        margin: 24px 0 0 28px;
        height: 47px;
        width: 47px;
      }

      .ci-body {
        margin: 28px 0 0 28px;
        width: 50%;

        .ci-provider-title {
          font-style: normal;
          font-weight: 500;
          font-size: 20px;
          line-height: 24px;
          letter-spacing: -0.014em;
          color: var(--theme-color-dcdfe5);
        }

        .ci-link-wrapper {
          margin-top: 5px;
          .ci-config-link {
            font-style: normal;
            font-weight: 500;
            font-size: 12px;
            line-height: 16px;
            color: var(--theme-color-5174ff);
          }
          .ci-config-link-disabled {
            font-style: normal;
            font-weight: 500;
            font-size: 12px;
            line-height: 16px;
            color: var(--theme-color-48576a);
          }
        }
      }

      .ci-status {
        width: 97px;
        height: 32px;

        background: var(--theme-color-151a20);
        border-radius: 3px;
        color: var(--theme-color-b2baca);

        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: -0.01em;

        margin: 32px 0 0 98px;

        padding: 5px 0 0 12px;
      }

      .ci-status-button-container {
        margin-left: auto;
        margin-right: 24px;
        align-self: center;

        .ui.button {
          &.ci-disconnect-status-button {
            background-color: transparent;
            border: 0;
            color: var(--theme-color-b2baca);
            font-size: 14px;
            font-weight: 500;
            line-height: 20px;
            margin-right: 8px;

            &:hover {
              color: var(--theme-color-B2BACA);
              background-color: var(--theme-color-222b35);
            }
          }
        }
      }
    }
  }
}

@media (max-width: 480px) {
  .integrations-container {
    .integrations-list {
      .integration {
        width: calc(100% - 10px);
        height: 96px;
        background: var(--theme-color-1c222a);
        border-radius: 8px;
        display: flex;
      }
    }
  }
}
