.ten-most-costly-workflows {
  display: flex;
  flex-direction: column;
  width: 100%;

  .ten-most-costly-workflows-header {
    padding: 6px 0px 6px 32px;
    margin-top: 12px;
    /* H5 Header */
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    /* identical to box height, or 143% */

    letter-spacing: -0.01em;

    /* Light/L50 */

    color: #dcdfe5;
  }

  .ten-most-costly-workflows-charts-container {
    display: flex;
    align-items: center;
    height: 300px;

    .line-cost-chart {
      width: 50%;
    }

    .pie-cost-chart {
      width: 50%;
    }
  }

  .count-cost-list {
    display: flex;
  }
}

@media (max-width: 480px) {
  .ten-most-costly-workflows {
    .ten-most-costly-workflows-charts-container {
      display: flex;
      align-items: center;
      flex-direction: column;
      height: 100%;

      .line-cost-chart {
        width: 95%;
      }

      .pie-cost-chart {
        width: 100%;
      }
    }

    .count-cost-list {
      display: flex;
    }
  }
}
