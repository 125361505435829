.name-filter-container {

  width: 100%;
  margin-right: 6px;


  .ui.input > input {
    max-height: 24px;
    height: 24px;
    min-height: 24px;

    width: 256px;
    background-color: var(--theme-color-070a0c);
    color: var(--theme-color-ffffff);

    border: 1px solid var(--theme-color-2f353d) !important;

    font-style: normal;
    font-weight: normal;
    font-size: 11px;
    line-height: 16px;
    /* identical to box height, or 145% */

    display: flex;
    align-items: center;

    /* Neutral/N200 */

    &::placeholder {
      color: var(--theme-color-2a3b50);
    }

    &:focus {
      border: 1px solid var(--theme-color-254be2) !important;
    }

    &:hover {
      cursor: pointer;
      background-color: var(--theme-color-202328) !important;
      border: 1px solid var(--theme-color-2f353d) !important;

      &::placeholder {
        color: var(--theme-color-5c697c);
      }
      
      &:focus {
        border: 1px solid var(--theme-color-254be2) !important;
        background-color: var(--theme-color-070a0c) !important;
      }


    }


  }
}


