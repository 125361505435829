.organization-lists-wrapper {
  .foresight-table {
    .current-org {
      text-align: end;
      padding-right: 0;
      color: var(--theme-color-ffffff);
      .icon-home {
        font-size: 16px;
        padding: 10px 10px;
        transition: all 0.3s ease-in-out;
        &:hover {
          font-size: 16px;
          padding: 10px 10px;
          background: rgba(120, 132, 150, 0.16);
          border-radius: 5px;
          cursor: pointer;
        }
        &:hover::before {
          color: #2abf81;
        }
      }
      .active-organization-icon {
        font-size: 16px;
        padding: 10px 10px;
        transition: all 0.3s ease-in-out;
        &:hover {
          font-size: 16px;
          padding: 10px 10px;
          background: rgba(120, 132, 150, 0.16);
          border-radius: 5px;
        }

        &::before {
          color: #2abf81;
        }

        &:hover::before {
          color: #2abf81;
        }
      }
    }

    .pending-label {
      color: var(--theme-color-d9d9d9);
      background-color: var(--theme-color-rgba_120_132_150_024);
      padding: 2px 4px;
      border-radius: 3px;
      font-size: 12px;
    }

    .status-container {
      display: flex;
      align-items: center;

      .checked-img {
        height: 16px;
        width: 16px;
      }

      span {
        margin-left: 8px;
      }
    }

    th {
      font-weight: 600;
      font-size: 14px;
      line-height: 20px;
    }

    td {
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
    }

    .icons-wrapper {
      height: 32px;
      width: 32px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 3px;

      i {
        font-size: 18px;
      }

      .icon-more-vertical {
        display: inline;
      }

      .icon-more-vertical-white {
        display: none;
      }

      &:hover {
        .icon-more-vertical {
          display: none;
        }

        .icon-more-vertical-white {
          display: inline;
        }

        background-color: var(--theme-color-rgba_120_132_150_032);
        cursor: pointer;
      }
    }

    .organization-table-name-cell {
      display: flex;
      align-items: center;

      .text-cell {
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        color: var(--theme-color-dcdfe5);
        display: flex;
        align-items: center;

        .avatar-owner-img {
          height: 12px;
          width: 12px;
          margin-left: 8px;
        }
      }

      .user-letter-avatar {
        border-radius: 50%;
        background-color: var(--theme-color-b2baca);
        height: 24px;
        width: 24px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 20px;
        color: var(--theme-color-0e1216);
      }

      .avatar-icon {
        font-size: 24px;
        margin-right: 20px;
      }

      .user-invited-tag {
        background-color: var(--theme-color-rgba_120_132_150_024);
        color: var(--theme-color-d9d9d9);
        padding: 2px 4px;
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        margin-left: 16px;
      }
    }
  }
}

.ui.popup.owner-popup {
  background-color: var(--theme_color_lighten_151a20_085) !important;
  border: 1px solid var(--theme-color-2f353d);
  border-radius: 4px;

  .content {
    font-size: 12px;
    font-weight: 600;
  }

  &:before {
    background-color: var(--theme_color_lighten_151a20_085) !important;
  }

  &.right::before {
    box-shadow: -1px 1px 0px 0px var(--theme-color-2f353d) !important;
  }

  &.bottom::before {
    box-shadow: -1px -1px 0px 0px var(--theme-color-2f353d) !important;
  }
}

.organization-action-dropdown {
  .title {
    padding: 20px 0 0 16px;
    span {
      font-style: normal;
      font-weight: bold;
      font-size: 11px;
      line-height: 16px;
      color: var(--theme-color-6a7689);
    }
  }

  .item-delimiter {
    border-bottom: 2px solid var(--theme-color-30363d);
    margin: 6px 0;
  }

  .item {
    padding: 10px 8px 10px 16px;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: var(--theme-color-b2baca);

    &.item-last {
      margin-bottom: 6px;
    }

    &:hover {
      cursor: pointer;
      background: var(--theme-color-rgba_220_223_229_008);
    }
  }
}

#organization-dropdown-parent {
  align-content: center;
  background-color: var(--theme-color-151a20);
  width: 508px;
  padding: 0;
  border: 1px solid var(--theme-color-292f38);
  box-shadow: 1px 2px 8px var(--theme-color-rgba_10_13_16_32), 2px 4px 16px var(--theme-color-rgba_10_13_16_24),
    4px 8px 16px var(--theme-color-rgba_10_13_16_16);
  border-radius: 3px;
  .organization-action-dropdown {
    .title {
      padding: 20px 0 0 16px;
      span {
        font-style: normal;
        font-weight: bold;
        font-size: 11px;
        line-height: 16px;
        color: var(--theme-color-6a7689);
      }
    }

    .item-delimiter {
      border-bottom: 2px solid var(--theme-color-30363d);
      margin: 6px 0;
    }

    .item {
      padding: 10px 8px 10px 16px;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      letter-spacing: -0.01em;
      color: var(--theme-color-b2baca);

      &.item.disabled {
        cursor: not-allowed;
        color: var(--theme-color-48576a);

        &:hover {
          background-color: var(--theme-color-151a20);
        }
      }

      &.item-last {
        margin-bottom: 6px;
      }

      &.item-first {
        margin-top: 6px;
      }

      &:hover {
        cursor: pointer;
        background: var(--theme-color-rgba_220_223_229_008);
      }
    }
  }
}

.leave-organization-modal {
  box-shadow: 0px 4px 8px -2px var(--theme-color-rgba_137_147_164_24),
    0px 0px 1px var(--theme-color-rgba_137_147_164_32);
  border-radius: 3px;
  max-width: 600px;

  .title-section {
    display: flex;
    .icon-warning {
      font-size: 24px;
      margin-right: 8px;
    }
    .title {
      font-size: 20px;
      font-weight: 500;
      line-height: 20px;
      color: var(--theme-color-dcdfe5);
      margin-bottom: 16px;
    }
  }

  .description {
    font-size: 14px;
    font-weight: 400;
    color: var(--theme-color-b2baca);
    margin-bottom: 24px;
    line-height: 20px;
  }

  .delete-actions {
    text-align: end;
    background: unset;

    .ui.button {
      &.cancel-button {
        background: transparent;
        border: 0;
        color: var(--theme-color-b2baca);
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        margin-right: 8px;

        &:hover {
          color: var(--theme-color-96a0b0);
          background-color: var(--theme-color-1b232b);
        }
      }

      &.primary {
        background-color: var(--theme-color-1d43dd);
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;

        &:hover {
          background-color: var(--theme-color-3459ef);
        }
      }
    }
  }
}
