.invoices-main-container {
  .last-column-invoices {
    & > div {
      font-family: 'Inter';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
    }
    & > span {
      font-family: 'Inter';
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      color: #1e90ff;
      &:hover {
        color: #73baff;
        text-decoration: underline;
        cursor: pointer;
      }
    }
  }
}
