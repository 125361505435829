.thundra-keys-container {
  padding-left: 32px;

  .variable-form-label {
    margin-top: 12px;
    font-size: 12px;
    color: var(--theme-color-788496);
    font-weight: 600;
    line-height: 16px;
  }

  .variable-form-input {
    background-color: var(--theme-color-222b35);
    padding: 6px 4px 6px 8px;
    color: var(--theme-color-dcdfe5);
  }
}
