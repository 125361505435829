@import 'assets/styles/size.scss';

.profile-page-wrapper {
  display: flex;
  flex-direction: column;
  overflow-y: overlay;
  height: 100%;
  width: 100%;
  max-width: 480px;

  .profile-page-form {
    text-align: left;
    width: 100%;

    .fields {
      margin: 0 0 2 * $spacing 0;
    }

    .ui.divider {
      margin: 2 * $spacing 0;
    }

    .field > label {
      font-weight: 500;
      color: var(--theme-color-827c88);
    }

    .error.field > label {
      font-weight: 500;
      color: red;
    }

    .ui.message {
      margin-left: 6px;
    }

    .email-name-form-group {
      flex-direction: column;
      max-width: 100%;

      .field {
        margin-bottom: 6px;
      }
    }
  }
}

.ui.form {
  .fields {
    .field {
      padding-left: 0;

      &.disabled {
        label {
          color: var(--theme-color-rgba_255_255_255_07);
        }
      }
    }
  }
}

.message-box-row {
  margin-top: 32px;

  .message-box-wrapper {
    border: 1px solid var(--theme-color-2a84f);
    padding: 8px 14px !important;
    background-color: var(--theme-color-363636);
    color: var(--theme-color-rgba_255_255_255_07);

    i {
      margin-right: 14px !important;
      color: var(--theme-color-d4d4d4);
    }

    .message-content {
      margin-top: 4px;
      padding-left: 26px;
    }
  }
}
