.provider-container {
  padding: 16px 0 0 16px;
  width: 192px;
  height: 144px;
  background: #181b1f;
  border: 2px solid #21262d;
  box-sizing: border-box;
  box-shadow: 0px 1px 1px rgba(7, 10, 12, 0.24), 0px 0px 1px rgba(7, 10, 12, 0.4);
  border-radius: 4px;
  margin: 16px 32px 16px 0;
  cursor: pointer;

  .provider-text {
    margin-top: 16px;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    letter-spacing: -0.01em;
    color: #f4f5f7;
  }

  .logo-container {
    padding: 8px;
    .provider-logo {
      width: 32px;
      height: 32px;
      background: #21262d;
    }
    width: 48px;
    height: 48px;
    left: 16px;
    top: 16px;
    background: #21262d;
    border-radius: 4px;
  }
}
