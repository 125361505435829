.steps-widget-container {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

#step-overflow {
  height: 115px;
  overflow-y: auto;
}

.steps-list-table {
  width: 100%;
  margin-bottom: 16px;

  th {
    position: sticky;
    top: 0; /* Don't forget this, required for the stickiness */
    background-color: var(--theme-color-1b232b);
  }

  .header-steps-id {
    width: 48px;
  }

  .header-steps-name {
    width: 556px;
  }

  .header-steps-steps {
    width: 120px;
  }

  .header-steps-name,
  .header-steps-steps {
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    /* identical to box height, or 133% */

    text-align: right;
    letter-spacing: -0.005em;

    /* Blue/B40 */

    color: var(--theme-color-5599ff);
  }

  .steps-id {
    display: flex;
    align-items: center;
    justify-content: space-evenly;

    .steps-id-color {
      display: flex;
      border-radius: 3px;
      width: 12px;
      height: 12px;
      justify-content: center;
      align-items: center;
      margin-left: 8px;
    }
  }

  .steps-name {
    display: flex;
    align-items: center;
  }

  .steps-stat {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  .steps-list-cell-text {
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: -0.005em;
    color: var(--theme-color-96a0b0);
  }
}

.steps-list-widget-row:hover {
  cursor: pointer;
  padding: 0px 16px 0px 8px;
  gap: 8px;
  background: var(--theme-color-rgba_255_255_255_012);
}

.workflow-steps-widget-title {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  color: var(--theme-color-96a0b0);
  padding: 14px 5px;
}
