.line-chart-legend-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  min-height: 30px;
  flex-wrap: wrap;
  margin: 20px;

  .legend {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 3px;

    .legend-icon {
      height: 12px;
      width: 12px;
      border-radius: 1px;
    }
    .legend-title {
      margin: 0 5px;
      color: var(--theme-color-788496);
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      line-height: 16px;
    }
  }
}

.line-chart-tooltip-container {
  background: var(--theme-color-151a20);
  box-shadow: 1px 2px 8px var(--theme-color-rgba_10_13_16_032), 2px 4px 16px var(--theme-color-rgba_10_13_16_024),
    4px 8px 16px var(--theme-color-rgba_10_13_16_016);
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;

  ul {
    padding: 8px;
    margin: 0;

    li {
      list-style-type: none;

      .li-item {
        min-height: 20px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-start;

        .legend-icon {
          height: 2px;
          width: 12px;
          border-radius: 1px;
          margin-right: 8px;
        }

        .legend-value {
          font-style: normal;
          font-weight: 600;
          font-size: 12px;
          line-height: 16px;
          letter-spacing: -0.01em;
          color: var(--theme-color-dcdfe5);
          margin-bottom: 12px;
        }

        .legend-value-container {
          display: flex;
          flex-direction: column;
          margin-bottom: 12px;

          div {
            font-style: normal;
            font-weight: normal;
            font-size: 12px;
            line-height: 16px;
            letter-spacing: -0.017em;
            color: var(--theme-color-dcdfe5);
          }
        }
      }
    }
  }
}
