.workflow-settings-container {
  display: flex;
  flex-direction: column;
  height: calc(100vh - 285px);
  justify-content: space-between;

  .workflow-settings-body {
    display: flex;
    height: calc(100vh - 470px);
    overflow-y: auto;

    .workflow-settings-body-left {
      display: flex;
      flex-direction: column;
      width: 600px;

      .workflow-setting-header {
        border-bottom: 2px solid var(--theme-color-1b232b);
        display: flex;
        justify-content: space-between;

        .workflow-settings-title-container {
          display: flex;
          padding: 8px 14px;
          gap: 4px;
          width: 368px;
          height: 40px;

          .workflow-setting-title {
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            line-height: 20px;
            display: flex;
            align-items: center;
            letter-spacing: -0.01em;
            color: var(--theme-color-788496);
          }
        }

        .workflow-setting-label-container {
          display: flex;
          gap: 8px;

          .icon-label-title-container {
            display: flex;

            .icon-label-title {
              font-family: 'Inter' !important;
              font-style: normal;
              font-weight: 600;
              font-size: 14px;
              line-height: 20px;
              display: flex;
              align-items: center;
              letter-spacing: -0.01em;
              color: var(--theme-color-788496);
            }
          }

          .setting-label-icon-container {
            display: flex;
            width: 32px;
            height: 32px;
            margin-top: 1.3px;
            cursor: pointer;

            .scale {
              padding: 5.8px;
              font-size: 20px;
              background: var(--theme-color-1c222a);
              border-radius: 3px;

              &:hover {
                background: var(--theme-color-2f353d);
              }
            }
          }
        }
      }

      .workflow-settings-accordion {
        height: 100%;
        display: block;
        overflow-y: auto;

        .workflow-settings-accordion-title {
          font-style: normal;
          font-weight: 500;
          font-size: 12px;
          line-height: 16px;
          color: var(--theme-color-dcdfe5);
          font-family: 'Inter';
          cursor: pointer;
        }

        .workflow-settings-accordion-icon {
          font-style: normal;
          font-weight: 500;
          font-size: 12px;
          line-height: 16px;
          color: var(--theme-color-dcdfe5);
        }

        .workflow-settings-accordion-context {
          display: flex;
          flex-direction: row;
          align-items: center;
          padding: 0px 0px 0px 19px;
        }
      }
    }
  }

  .workflow-settings-bottom-bar {
    padding-top: 16px;
    display: flex;
    height: 50px;
  }
}

#apply-wfs-modal {
  width: 600px;
}

#apply-wfl-create-modal {
  width: 400px;
}

#apply-wfl-edit-modal {
  width: 568px;
}

.apply-wfs-modal-container {
  display: flex;
  flex-direction: column;

  .apply-wfs-modal-cancel-button {
    background-color: transparent;
    color: var(--theme-color-96a0b0);

    &:hover {
      color: var(--theme-color-ffffff);
      background-color: transparent;
    }
  }

  .title {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    color: var(--theme-color-dcdfe5);
    margin: 12px 0px;
  }

  .description {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: var(--theme-color-b2baca);
    padding: 0px 24px;
  }

  .change-wf-list {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: var(--theme-color-b2baca);
    padding: 0px 24px;
    height: 100px;
    max-height: 100px;
    overflow-y: auto;
  }

  .description-detail {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    display: flex;
    align-items: center;
    color: var(--theme-color-b2baca);

    ul {
      padding: 0px 24px;
    }
  }

  .delete-actions {
    display: flex;
    justify-content: flex-end;
    padding-top: 16px;
  }

  .apply-wfs-modal-header {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;

    .icon-warning {
      font-size: 24px;
    }
  }
}

.workflow-settings-checks {
  width: 600px;
}

.add-label-extra-comp {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 4px 12px 4px 8px;
  gap: 8px;
  width: 90px;
  height: 24px;
  border-radius: 3px;
  background: var(--theme-color-222b35);

  &.selected {
    background: var(--theme-color-1c222a);
  }

  &:hover {
    background: var(--theme-color-1c222a);
  }

  i {
    font-size: 12px;
  }

  span {
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    display: flex;
    align-items: center;
    text-align: right;
    letter-spacing: -0.005em;
    color: var(--theme-color-dcdfe5);
  }
}

.extra-check-list-item-comps-container {
  display: flex;
  gap: 3px;

  .workflow-label {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 2px 8px;

    height: 20px;
    background: var(--theme-color-1c222a);
    border: 1px solid var(--theme-color-2f353d);
    border-radius: 12px;
    cursor: pointer;

    .workflow-label-text {
      max-width: 160px;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      font-style: normal;
      font-weight: 600;
      font-size: 11px;
      line-height: 16px;
      color: #b2baca;
    }
  }
}

#add-label-dropdown-parent {
  align-content: center;
  background-color: var(--theme-color-151a20);
  font-family: 'Inter';

  padding: 0;
  border: 1px solid var(--theme-color-292f38);
  box-shadow: 1px 2px 8px var(--theme-color-rgba_10_13_16_32), 2px 4px 16px var(--theme-color-rgba_10_13_16_24),
    4px 8px 16px var(--theme-color-rgba_10_13_16_16);
  border-radius: 3px;
  .add-label-action-dropdown {
    .title {
      padding: 20px 0 0 16px;
      span {
        font-style: normal;
        font-family: 'Inter';
        font-weight: bold;
        font-size: 11px;
        line-height: 16px;
        color: var(--theme-color-6a7689);
      }
    }

    .item-delimiter {
      border-bottom: 2px solid var(--theme-color-30363d);
      margin: 6px 0;
    }

    .item {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 10px 8px 10px 16px;
      gap: 4px;

      width: 246px;
      height: 32px;

      .item-text {
        max-width: 160px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;

        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: -0.01em;
        color: var(--theme-color-b2baca);
      }

      &.item.disabled {
        cursor: not-allowed;
        color: var(--theme-color-48576a);

        &:hover {
          background-color: var(--theme-color-151a20);
        }
      }

      &.item-last {
        margin-bottom: 6px;
      }

      &.item-first {
        margin-top: 6px;
      }

      &:hover {
        cursor: pointer;
        background: var(--theme-color-rgba_220_223_229_008);
      }
    }
  }
}
