@font-face {
  font-family: 'foresightfonts';
  src:  url('fonts/foresightfonts.eot?x1c283');
  src:  url('fonts/foresightfonts.eot?x1c283#iefix') format('embedded-opentype'),
    url('fonts/foresightfonts.ttf?x1c283') format('truetype'),
    url('fonts/foresightfonts.woff?x1c283') format('woff'),
    url('fonts/foresightfonts.svg?x1c283#foresightfonts') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'foresightfonts' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-add-circle:before {
  content: "\e94a";
  color: #788496;
}
.icon-bookmark:before {
  content: "\e900";
  color: #5c697c;
}
.icon-branches:before {
  content: "\e901";
  color: #788496;
}
.icon-chevron-down:before {
  content: "\e902";
  color: #8792a3;
}
.icon-chevron-left:before {
  content: "\e903";
  color: #fff;
}
.icon-chevron-right:before {
  content: "\e904";
  color: #788496;
}
.icon-chevron-up:before {
  content: "\e905";
  color: #b2baca;
}
.icon-circle-info:before {
  content: "\e906";
  color: #8792a3;
}
.icon-circle-x:before {
  content: "\e907";
}
.icon-commit:before {
  content: "\e908";
  color: #788496;
}
.icon-copy-code:before {
  content: "\e909";
  color: #788496;
}
.icon-credit-card:before {
  content: "\e90a";
  color: #dcdfe5;
}
.icon-cross:before {
  content: "\e90b";
  color: #788496;
}
.icon-down-side-arrow-icon:before {
  content: "\e90c";
}
.icon-down:before {
  content: "\e90d";
  color: #788496;
}
.icon-edit:before {
  content: "\e90e";
  color: #788496;
}
.icon-elapsed-time:before {
  content: "\e90f";
  color: #788496;
}
.icon-email:before {
  content: "\e910";
  color: #788496;
}
.icon-exclamation-red:before {
  content: "\e911";
  color: #ff5630;
}
.icon-execution-date:before {
  content: "\e912";
  color: #788496;
}
.icon-external-redirect:before {
  content: "\e913";
  color: #fff;
}
.icon-filter-icon:before {
  content: "\e914";
  color: #788496;
}
.icon-github-logo:before {
  content: "\e915";
  color: #fff;
}
.icon-home:before {
  content: "\e916";
  color: #788496;
}
.icon-icons-action-job:before {
  content: "\e917";
  color: #788496;
}
.icon-icons-calendar:before {
  content: "\e918";
  color: #788496;
}
.icon-icons-check:before {
  content: "\e919";
  color: #788496;
}
.icon-icons-skipped:before {
  content: "\e91a";
  color: #5c697c;
}
.icon-info-circle-project-integration:before {
  content: "\e91b";
  color: #5174ff;
}
.icon-more-dot3:before {
  content: "\e91c";
  color: #8792a3;
}
.icon-more-vertical-white:before {
  content: "\e91d";
  color: #fff;
}
.icon-more-vertical:before {
  content: "\e91e";
  color: #788496;
}
.icon-other-wf-run:before {
  content: "\e91f";
  color: #788496;
}
.icon-passed-and-arrow-in-circle:before {
  content: "\e920";
  color: #5c697c;
}
.icon-person:before {
  content: "\e921";
  color: #788496;
}
.icon-play:before {
  content: "\e922";
  color: #5c697c;
}
.icon-pull-request-status-closed:before {
  content: "\e923";
  color: #f85149;
}
.icon-pull-request-status-draft:before {
  content: "\e924";
  color: #8792a3;
}
.icon-pull-request-status-merged:before {
  content: "\e925";
  color: #8777d9;
}
.icon-pull-request-status-open:before {
  content: "\e926";
  color: #2dcd8a;
}
.icon-pullrequest:before {
  content: "\e927";
  color: #5c697c;
}
.icon-refresh:before {
  content: "\e928";
}
.icon-run-aborted:before {
  content: "\e929";
  color: #788496;
}
.icon-run-failed:before {
  content: "\e92a";
  color: #ff5630;
}
.icon-run-passed:before {
  content: "\e92b";
}
.icon-run-running:before {
  content: "\e92c";
  color: #1d76e1;
}
.icon-run-successful:before {
  content: "\e92d";
  color: #2dcd8a;
}
.icon-settings-light:before {
  content: "\e92e";
  color: #b2baca;
}
.icon-slack-logo:before {
  content: "\e92f";
  color: #fff;
}
.icon-slackmini:before {
  content: "\e930";
  color: #fff;
}
.icon-src-env-github:before {
  content: "\e931";
  color: #788496;
}
.icon-state-inactive:before {
  content: "\e932";
  color: #788496;
}
.icon-status-action-required:before {
  content: "\e933";
  color: #d29922;
}
.icon-status-cancelled:before {
  content: "\e934";
  color: #8b949e;
}
.icon-status-inProgress .path1:before {
  content: "\e935";
  color: rgb(219, 171, 10);
}
.icon-status-inProgress .path2:before {
  content: "\e936";
  margin-left: -1em;
  color: rgb(219, 171, 10);
  opacity: 0.5;
}
.icon-status-inProgress .path3:before {
  content: "\e937";
  margin-left: -1em;
  color: rgb(219, 171, 10);
}
.icon-status-neutral:before {
  content: "\e938";
  color: #8b949e;
}
.icon-status-queued:before {
  content: "\e939";
  color: #9e6a03;
}
.icon-status-skipped:before {
  content: "\e93a";
  color: #8b949e;
}
.icon-status-stale:before {
  content: "\e93b";
  color: #8b949e;
}
.icon-status-success:before {
  content: "\e93c";
  color: #238636;
}
.icon-status-timed-out:before {
  content: "\e93d";
  color: #f85149;
}
.icon-status-unknown:before {
  content: "\e93e";
  color: #8b949e;
}
.icon-tag-label:before {
  content: "\e93f";
  color: #788496;
}
.icon-test-status-aborted:before {
  content: "\e940";
  color: #788496;
}
.icon-test-status-failed:before {
  content: "\e941";
  color: #ff5630;
}
.icon-test-status-passed:before {
  content: "\e942";
  color: #2dcd8a;
}
.icon-test-status-skipped:before {
  content: "\e943";
  color: #788496;
}
.icon-trash:before {
  content: "\e944";
  color: #788496;
}
.icon-up-side-arrow-icon:before {
  content: "\e945";
}
.icon-up:before {
  content: "\e946";
  color: #788496;
}
.icon-user-avatar:before {
  content: "\e947";
  color: #788496;
}
.icon-warning:before {
  content: "\e948";
  color: #ffab00;
}
.icon-workflows:before {
  content: "\e949";
  color: #788496;
}
