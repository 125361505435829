.costly-widget-container {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.costly-list-table {
  width: 100%;
  padding: 12px 27px;
  margin-bottom: 16px;

  .header-workflow-id {
    width: 48px;
  }

  .header-workflow-name {
    width: 556px;
  }

  .header-workflow-costly {
    width: 120px;
  }

  .header-workflow-name,
  .header-workflow-costly {
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    /* identical to box height, or 133% */

    text-align: right;
    letter-spacing: -0.005em;

    /* Blue/B40 */

    color: var(--theme-color-5599ff);
  }

  .workflow-id {
    display: flex;
    align-items: center;
    justify-content: space-evenly;

    .workflow-id-color {
      display: flex;
      border-radius: 3px;
      width: 12px;
      height: 12px;
      justify-content: center;
      align-items: center;
      margin-left: 8px;
    }
  }

  .workflow-name {
    display: flex;
    align-items: center;
  }

  .workflow-stat {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  .workflow-list-cell-text {
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: -0.005em;
  }
}

.costly-list-widget-row:hover {
  cursor: pointer;
  padding: 0px 16px 0px 8px;
  gap: 8px;
  background: var(--theme-color-rgba_255_255_255_012);
  color: var(--theme-color-ffffff);
  transition: color 0.2s ease-in-out;
}
.costly-list-widget-row {
  color: var(--theme-color-96a0b0);
}

.active-line {
  background-color: var(--theme-color-rgba_255_255_255_012);
  transition: all 0.2s ease-in-out;
}
