.repositories-page {
  .repositories-container {
    --margin-top-var: 28px;
    max-width: 1200px;
    min-width: 1200px;
    margin: 0 auto;

    .repositories-body {
      .project {
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        display: flex;
        flex-direction: row;

        .settings-container {
          &:hover {
            cursor: pointer;
          }
        }

        .project-body {
          width: 100%;
          padding: 0;

          .repo-card-list-container {
            .repo-card-list {
              padding-left: 0px;
              row-gap: 24px;
              display: grid;
              grid-template-columns: repeat(3, 384px);
              justify-content: space-between;
              align-content: flex-start;
              list-style-type: none;
              margin-bottom: 32px;
              width: 100%;
            }
          }
        }
      }

      .body-container {
        text-align: center;
        padding-top: 200px;
        padding-bottom: 200px;
        margin: 0 auto;
        align-content: center;

        .empty-image {
          margin: 0 auto;
          align-content: center;
        }

        .body-text-title {
          margin-top: 8px;
          font-weight: 600;
          font-size: 20px;
          line-height: 24px;
          width: 100%;
          white-space: nowrap;
          overflow: hidden;
          color: var(--theme-color-dcdfe5);
        }

        .body-text-container {
          margin-top: 16px;
          .body-text {
            font-weight: normal;
            width: 450px;
            font-size: 14px;
            line-height: 20px;
            color: var(--theme-color-dcdfe5);
          }
        }

        .initial-and-project-connect-repositories {
          margin: var(--margin-top-var) 0 0 0;
        }
      }

      .empty-state {
        padding-top: 0px;
        padding-bottom: 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
      }
    }
  }
}
.ui.primary.button {
  &:hover {
    background-color: var(--theme-color-4367fd);
  }
}

.tundra-topbar-button-container {
  display: flex;
  font-style: normal;
  font-family: Inter;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.01em;
  margin-top: -3px;
  .icon {
    margin-right: 4px;
    max-width: 16px;
    height: 16px;
  }
}

#settings-id {
  border-radius: 3px;
  font-size: 24px;
  cursor: pointer;
  background-color: transparent;
  &:hover {
    background-color: var(--theme-color-222b35);
  }
  padding-top: 1px;
  height: 100%;
  width: 100%;
}

.settings-tooltip {
  color: var(--theme-color-ffffff);
  cursor: pointer;
  border-radius: 3px;
}

#project-deletion {
  .title-container {
    display: flex;
    align-items: center;
    margin-bottom: 24px;
  }

  .description-list {
    padding-left: 24px;
    margin-bottom: 16px;

    li {
      font-size: 14px;
      line-height: 20px;
      color: var(--theme-color-b2baca);
    }
  }

  .warning-icon {
    font-size: 24px;
  }

  .title {
    margin-left: 8px;
    font-size: 20px;
    font-weight: 500;
    color: var(--theme-color-dcdfe5);
  }

  .description {
    font-size: 14px;
    font-weight: 500;
    color: var(--theme-color-b2baca);
    margin-bottom: 8px;
  }

  .delete-actions {
    text-align: end;
    background: unset;
    display: flex;
    justify-content: right;

    .ui.button {
      &.cancel-button {
        background: transparent;
        border: 0;
        color: var(--theme-color-b2baca);

        &:hover {
          color: var(--theme-color-96a0b0);
          background: var(--theme-color-1b232b);
        }
      }

      &.primary {
        background-color: var(--theme-color-1d43dd);

        &:hover {
          background-color: var(--theme-color-3459ef);
        }
      }
    }
  }
}

#project-keys-modal,
#project-preferences {
  .title {
    margin-bottom: 24px;

    span {
      font-style: normal;
      font-weight: 500;
      font-size: 20px;
      line-height: 24px;
      letter-spacing: -0.014em;
      color: var(--theme-color-dcdfe5);
    }
  }

  .subtitle {
    span {
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      line-height: 16px;
      letter-spacing: -0.01em;
      color: var(--theme-color-788496);
    }
    margin-top: 24px;
    margin-bottom: 8px;
  }
}

#project-preferences {
  .error-message {
    color: var(--theme-color-ff5630);
    font-size: 10px;
    font-weight: 500;
    line-height: 16px;

    &.hidden {
      visibility: hidden;
    }

    &.visible {
      visibility: visible;
    }
  }

  .action-buttons {
    text-align: end;
    margin-top: 24px;

    .cancel-button {
      background: transparent;
      color: var(--theme-color-96a0b0);

      &:hover {
        color: var(--theme-color-96a0b0);
        background: var(--theme-color-1b232b);
      }
    }
  }
}

#project-keys-modal {
  margin: 24px 0px 24px 0px;

  .auth-token-section {
    background: var(--theme-color-1c222a);
    padding: 6px 8px;
    border-radius: 3px;

    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: -0.01em;
    color: var(--theme-color-b2baca);
    cursor: pointer;
    justify-content: space-between;
    display: flex;

    .copy {
      font-size: 8px;
    }
  }

  &:active {
    background-color: none;
  }

  &::selection {
    background-color: none;
  }

  &:focus {
    background-color: none;
  }
}

#project-settings-dropdown-parent {
  align-content: center;
  background-color: var(--theme-color-151a20);
  color: var(--theme-color-b2baca);
  width: 508px;
  padding: 0;
  border: 1px solid var(--theme-color-292f38);
  box-shadow: 1px 2px 8px var(--theme-color-rgba_10_13_16_32), 2px 4px 16px var(--theme-color-rgba_10_13_16_24),
    4px 8px 16px var(--theme-color-rgba_10_13_16_16);
  border-radius: 3px;
  .project-settings-dropdown {
    .title {
      padding: 20px 0 0 16px;
      span {
        font-style: normal;
        font-weight: bold;
        font-size: 11px;
        line-height: 16px;
        color: var(--theme-color-6a7689);
      }
    }

    .item {
      padding: 10px 8px 10px 16px;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      letter-spacing: -0.01em;
      color: var(--theme-color-b2baca);

      &:hover {
        cursor: pointer;
        background: var(--theme-color-rgba_220_223_229_008);
      }
    }

    .display-test-integration {
      border-bottom: 2px solid var(--theme-color-rgba_120_132_150_02);
      background-color: var(--theme-color-151a20);
      width: 100%;
      text-align: left;
    }

    .display-preferences {
      margin-top: 4px;
    }
    .display-delete-project {
      margin: 4px 0 8px 0;
    }
  }
}

#copy-popup-id {
  --superior-z-index-to-auth-key-modal: 2001;
  z-index: var(--superior-z-index-to-auth-key-modal);
}

.ok-button {
  margin-top: 24px;
  display: flex;
  justify-content: right;
  &:active {
    background-color: none;
  }

  &::selection {
    background-color: none;
  }

  &:focus {
    background-color: none;
  }
}

.new-updates-bubble.ui.button {
  position: absolute;
  left: 50%;
  top: 110px;
  background: var(--theme-color-dcdfe5);
  color: var(--theme-color-09152f);
  border-radius: 15px;
  z-index: 3;

  .refresh-icon {
    margin-right: 4px;
  }

  &:hover {
    background: var(--theme-color-ebecf0);
  }
}

@media (max-width: 480px) {
  .repositories-page {
    .repositories-container {
      --margin-top-var: 28px;
      max-width: 93vw;
      min-width: 93vw;
      margin: 0 auto;

      .title-button-sections {
        margin: 12px 0 32px 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;

        .left-section {
          display: flex;
          align-items: center;

          .repositories {
            font-weight: 600;
            font-size: 24px;
            line-height: 32px;
            color: var(--theme-color-dcdfe5);
          }
        }

        .right-section {
          display: flex;
          align-items: center;
          justify-content: center;
          margin-top: 12px;

          .new-connect-repositories {
            margin-right: 8px;
            height: 32px;
          }

          .integrations-repositories {
            background-color: var(--theme-color-1b232b);
            color: var(--theme-color-b2baca);

            height: 32px;

            .button-text {
              font-family: Inter;
              font-style: normal;
              font-weight: 500;
              font-size: 14px;
              line-height: 20px;
              letter-spacing: -0.01em;
              margin-top: -2px;
            }

            &:hover {
              background-color: var(--theme-color-222b35);
            }
          }
        }
      }

      .repositories-body {
        .project {
          width: 100%;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          display: flex;
          flex-direction: row;

          .pointer-cursor {
            cursor: pointer;
          }

          .project-title {
            margin: 4px 0;
            font-style: normal;
            font-weight: 600;
            font-size: 20px;
            line-height: 24px;
            color: var(--theme-color-dcdfe5);
            display: flex;
            align-items: center;
            padding-bottom: 16px;

            width: 100%;
          }
          .project-body {
            width: 100%;
            padding: 0;

            .repo-card-list-container {
              .repo-card-list {
                padding-left: 0px;
                row-gap: 24px;
                display: grid;
                grid-template-columns: repeat(1, 376px);
                justify-content: center;
                align-content: flex-start;
                list-style-type: none;
                margin-bottom: 32px;
                margin-top: 14px;
                width: 100%;
              }
            }
          }
        }

        .body-container {
          text-align: center;
          padding-top: 200px;
          padding-bottom: 200px;
          margin: 0 auto;
          align-content: center;

          .empty-image {
            margin: 0 auto;
            align-content: center;
          }

          .body-text-title {
            margin-top: 8px;
            font-weight: 600;
            font-size: 20px;
            line-height: 24px;
            width: 100%;
            white-space: nowrap;
            overflow: hidden;
            color: var(--theme-color-dcdfe5);
          }

          .body-text-container {
            margin-top: 16px;
            .body-text {
              font-weight: normal;
              width: 450px;
              font-size: 14px;
              line-height: 20px;
              color: var(--theme-color-dcdfe5);
            }
          }

          .initial-and-project-connect-repositories {
            margin: var(--margin-top-var) 0 0 0;
          }
        }

        .empty-state {
          padding-top: 0px;
          padding-bottom: 0;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
        }
      }
    }
  }
  .ui.primary.button {
    &:hover {
      background-color: var(--theme-color-4367fd);
    }
  }

  .tundra-topbar-button-container {
    display: flex;
    font-style: normal;
    font-family: Inter;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: -0.01em;
    margin-top: -3px;
    .icon {
      margin-right: 4px;
      max-width: 16px;
      height: 16px;
    }
  }
}
