.react-lazylog-searchbar{
  background-color: var(--theme-color-070a0c) !important;


  input {
    outline: none;
    max-height: 24px;
    height: 24px;
    min-height: 24px;
    border-radius: 5px;

    width: 256px;
    background-color: var(--theme-color-070a0c);
    color: var(--theme-color-ffffff);

    border: 1px solid var(--theme-color-2f353d) !important;

    font-style: normal;
    font-weight: normal;
    font-size: 11px;
    line-height: 16px;
    /* identical to box height, or 145% */

    display: flex;
    align-items: center;

    /* Neutral/N200 */

    &::placeholder {
      color: var(--theme-color-2a3b50);
    }

    &:focus {
      border: 1px solid var(--theme-color-254be2) !important;
    }

    &:hover {
      cursor: pointer;
      background-color: var(--theme-color-202328) !important;
      border: 1px solid var(--theme-color-2f353d) !important;

      &::placeholder {
        color: var(--theme-color-5c697c);
      }

      &:focus {
        border: 1px solid var(--theme-color-254be2) !important;
        background-color: var(--theme-color-070a0c) !important;
      }


    }


  }
  
  
}

.react-lazylog{
  background-color: var(--theme-color-070a0c) !important;
}

.log-line {
  background-color: var(--theme-color-070a0c);
  
  span{
    font-family: IBM Plex Mono;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    color: var(--theme-color-b2baca);
  }

  a{
    margin: 0px;
    font-style: normal;
    font-weight: normal;
    font-size: 11px;
    line-height: 16px;
    
  }
  
  &:hover{
    background-color: var(--theme-color-1c222a);
  }

}

.log-line-highlighted{
  color:darkorange;
}