.setup-help {
  width: 240px;
  display: flex;
  align-items: center;
  justify-content: flex-end;

  .setup-help-card {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: var(--theme-color-1c222a);
    height: 78px;
    margin-left: 8px;
    padding: 20px 18px;

    .first-row {
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      align-items: center;
      letter-spacing: -0.01em;
      color: var(--theme-color-b2baca);
    }

    .second-row {
      display: flex;
      align-items: center;
      color: var(--theme-color-1e90ff);
      cursor: pointer;
      .link {
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        color: var(--theme-color-1e90ff);
      }

      .icon-external-redirect2:before {
        color: var(--theme-color-1e90ff);
      }

      i {
        margin-left: 6px;
        font-size: 12px;
      }
      &:hover {
        .link {
          text-decoration-line: underline;
          color: var(--theme-color-73baff);
        }
        .icon-external-redirect2:before {
          color: var(--theme-color-73baff);
        }
      }
    }
  }
}

.integrate-your-test-by-test-report-uploader {
  height: 100vh;
  width: 100vw;
  background-color: var(--theme-color-181b1f);

  .integrate-your-test-by-test-report-uploader {
    display: flex;
    flex-direction: column;
    height: 100vh;
    width: 992px;
    margin: auto;

    .top-part {
      overflow-y: auto;
      height: fit-content;
      width: 100%;
      display: flex;
      flex-direction: column;
      padding-top: 80px;

      .header-and-info {
        display: flex;
        flex-direction: column;

        .header-part {
          padding: 12px 0px 24px 0px;
          font-style: normal;
          font-weight: 600;
          font-size: 20px;
          line-height: 24px;
          letter-spacing: -0.02em;
          color: #f4f5f7;
        }

        .info-part {
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          align-items: flex-start;

          .info-text-list {
            text-align: left;
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 16px;
            letter-spacing: -0.01em;
            color: #b2baca;
            padding-left: 20px;
            margin: 0 0 8px 0;

            li {
              padding-bottom: 8px;
            }
          }

          .info-text {
            &:first-child {
              padding-bottom: 16px;
            }

            padding-bottom: 8px;
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 16px;
            display: flex;
            align-items: center;
            letter-spacing: -0.01em;
            color: #b2baca;
          }
        }
      }

      .setup-section {
        display: flex;
        flex-direction: row;
        width: 100%;
        margin: 40px 0;

        .setup-info {
          width: 70%;
          display: flex;
          flex-direction: column;

          .select-test-framework {
            display: flex;
            margin: 0 0 36px 36px;
          }

          .radio-button {
            flex: 1 1 auto;
          }

          .header {
            display: flex;
            flex-direction: row;
            height: 20px;
            margin-bottom: 16px;

            .header-no-bg {
              margin-right: 12px;
              width: 20px;
              height: 20px;
              left: 0px;
              top: 0px;
              background: #f4f5f7;
              border-radius: 16px;

              .header-no {
                font-style: normal;
                font-weight: 600;
                font-size: 16px;
                line-height: 20px;
                text-align: center;
                letter-spacing: -0.01em;
                color: #09152f;
              }
            }

            .header-title {
              font-style: normal;
              font-weight: 600;
              font-size: 16px;
              line-height: 20px;
              letter-spacing: -0.01em;
              color: #f4f5f7;
            }
          }

          .sub-header {
            font-style: normal;
            font-weight: 600;
            font-size: 12px;
            line-height: 16px;
            display: flex;
            align-items: center;
            letter-spacing: -0.01em;
            color: #788496;
          }

          .info-part {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
            margin-left: 32px;

            .box-desc-container {
              display: flex;
              background-color: var(--theme-color-0e1216);
              width: 100%;
              border-radius: 3px;
              padding: 10px 8px;
              margin-bottom: 12px;

              span {
                color: var(--theme-color-b2baca);
                font-size: 14px;
                line-height: 20px;
              }

              i {
                font-size: 16px;
                padding-right: 8px;
              }
            }
          }

          .configuration-code {
            background-color: red;
            display: flex;
            justify-content: space-between;
            width: 100%;
            background: var(--theme-color-1c222a) !important;

            .left-part {
              display: flex;
              justify-content: flex-start;

              .configuration-code-sample {
                display: flex;
                justify-content: flex-start;
                background: var(--theme-color-1c222a) !important;
                color: var(--theme-color-96a0b0);
                border-radius: 3px;
                padding: 4px 8px;
                user-select: text;
                cursor: text;
                border-radius: 3px;
              }
            }

            .copy-icon {
              justify-self: flex-end;
              text-align: end;
              margin-right: 5px;
              margin-top: 5px;
              padding-top: 5px;

              i {
                color: var(--theme-color-5c697c);
              }
            }
          }
        }
      }
    }

    .footer-wrapper {
      border-top: 2px solid var(--theme-color-2f353d);
      height: 136px;

      margin-bottom: 32px;
      .footer-info {
        margin: 32px 0 0 32px;

        .check-for-approve {
          margin-bottom: 16px;

          label {
            color: var(--theme-color-dcdfe5);
            font-size: 14px;
          }

          input {
            font-size: 14px;
          }
        }

        .action-wrapper {
          display: flex;
          justify-content: space-between;

          .continue-button {
            font-family: 'Inter';
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 20px;
            align-items: center;
            text-align: center;
            letter-spacing: -0.01em;
            color: var(--theme-color-ffffff);
          }

          .continue-button.disabled {
            background-color: transparent;
            color: var(--theme-color-48576a);
            font-family: 'Inter';
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 20px;
            align-items: center;
            text-align: center;
            letter-spacing: -0.01em;
          }

          .skip-button {
            background-color: transparent;
            color: var(--theme-color-96a0b0);
            font-family: 'Inter';
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 20px;
            align-items: center;
            text-align: right;
            letter-spacing: -0.01em;
            border-radius: 3px;

            &:hover {
              background-color: var(--theme-color-1b232b);
            }
          }
        }
      }
    }
  }
}

// Start css for the new page

.integrate-your-test-page-main-container {
  width: 100vw;
  background-color: var(--theme-color-181b1f);
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: auto;
  height: 100%;
  overflow: auto;

  .main-content-container {
    margin-top: 50px;
    height: fit-content;

    .main-content-header-container {
      display: flex;
      justify-content: space-between;
    }

    span.number-sign {
      padding: 4px 8px;
      background-color: var(--theme-color-f4f5f7);
      border-radius: 50%;
      color: var(--theme-color-09152f);
      font-size: 16px;
    }

    .main-content-section-one-header {
      color: #f4f5f7;
      font-weight: 600;
      font-size: 20px;
      line-height: 24px;
      margin-bottom: 16px;
      display: flex;
      gap: 16px;
    }
    .main-content-section-one-input {
      width: 100%;
      height: 40px;
      font-size: 14px;
      opacity: 1;
      background-color: var(--theme-color-151a20);
      border: 2px solid var(--theme-color-2f353d);
      border-radius: 3px;
      z-index: 9;

      &:hover {
        cursor: pointer;
        filter: brightness(1.5);
      }
      & > input:disabled {
        opacity: 1;
      }
    }
    .main-content-section-one-content {
      margin-left: 40px;

      .main-content-input-one-label {
        font-size: 12px;
        line-height: 16px;
        color: var(--theme-color-8792a3);
        margin-bottom: 4px;
      }

      .configuration-code {
        .copy-icon-with-input {
          position: absolute;
          right: 8px;
          top: 5px;
          z-index: 99;

          i {
            color: var(--theme-color-b2baca);
          }
        }
      }
    }

    .main-content-section-two {
      .main-content-section-two-content-sub-cont {
        margin-left: 40px;
        display: flex;
        width: 992px;

        .test-framework-configurations-container {
          display: flex;

          .dropdown-label-container {
            display: flex;
            flex-direction: column;
            margin-right: 10px;

            .dropdown-label {
              font-weight: 600;
              font-size: 12px;
              line-height: 16px;
              color: var(--theme-color-8792a3);
              margin-bottom: 4px;
            }

            .ui.selection.dropdown {
              background: transparent;
              color: var(--theme-color-b2baca);
              display: flex;
              justify-content: space-between;
              padding-right: 6px;
              border-color: var(--theme-color-2f353d) !important;

              &.active {
                border-color: var(--theme-color-254be2) !important;
              }

              .text {
                font-size: 14px;
                line-height: 20px;
                color: var(--theme-color-b2baca);
              }

              .visible.menu.transition {
                background: var(--theme-color-0e1216);
                border-color: var(--theme-color-254be2) !important;

                .active.selected.item {
                  background: var(--theme-color-1d43dd);
                  font-weight: 400;

                  .text {
                    color: var(--theme-color-ffffff);
                  }
                }

                .item:hover {
                  background: var(--theme-color-1c222a);
                }
              }

              .icon {
                font-size: 16px;
                align-self: center;
              }
            }
          }
        }

        .main-content-section-two-content-table {
          width: 400px;
        }
        .main-content-section-two-content-table-second {
          width: 200px;
          margin: 0 40px;
        }
      }
      .main-content-section-two-header {
        color: #f4f5f7;
        font-weight: 600;
        font-size: 20px;
        line-height: 24px;
        margin-top: 30px;
        margin-bottom: 16px;
        display: flex;
        gap: 16px;
      }
      .main-content-section-two-content-text {
        align-items: center;
        color: #b2baca;
        display: flex;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        letter-spacing: -0.01em;
        line-height: 16px;
        width: 716px;
        margin-bottom: 22px;
      }
    }
    .main-content-section-three {
      .kit-title {
        font-size: 14px;
        font-weight: 700;
        line-height: 20px;
        text-align: left;
        margin-bottom: 8px;
        margin-left: 40px;
      }

      .main-content-section-three-header {
        margin-top: 30px;
        margin-bottom: 24px;
        color: #f4f5f7;
        font-weight: 600;
        font-size: 20px;
        line-height: 24px;
        display: flex;
        gap: 16px;
      }
      .info-part {
        margin-left: 40px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;

        .box-desc-container {
          display: flex;
          background-color: var(--theme-color-1c222a);
          width: 100%;
          border-radius: 3px;
          padding: 16px;
          margin-bottom: 12px;
          align-items: center;

          span {
            color: var(--theme-color-dcdfe5);
            font-size: 14px;
            line-height: 20px;
          }

          i {
            font-size: 16px;
            padding-right: 8px;
          }
        }
      }

      .configuration-code {
        display: flex;
        justify-content: space-between;
        width: 100%;
        background: var(--theme-color-1c222a) !important;

        .left-part {
          display: flex;
          justify-content: flex-start;

          .configuration-code-sample {
            display: flex;
            justify-content: flex-start;
            background: var(--theme-color-1c222a) !important;
            color: var(--theme-color-96a0b0);
            border-radius: 3px;
            padding: 4px 8px;
            user-select: text;
            cursor: text;
            border-radius: 3px;
          }
        }

        .copy-icon {
          justify-self: flex-end;
          text-align: end;
          margin-right: 10px;
          margin-top: 5px;
          padding-top: 5px;

          i {
            color: var(--theme-color-5c697c);
          }
        }
      }
    }
  }
}

.test-integration-table {
  .table-row {
    .status-line {
      height: 16px;
      width: 6px;
      margin-right: 4px;
      margin-left: -4px;

      &.failed {
        background-color: var(--theme-color-f85149);
      }

      &.successed {
        background-color: var(--theme-color-238636);
      }
    }

    td {
      padding: 0.78571429em 0.78571429em;
    }

    .date-row-container {
      display: flex;
    }

    &:hover {
      background-color: var(--theme-color-161b22);
      filter: brightness(1.2);
      cursor: pointer;
    }
  }
}

.footer-wrapper {
  border-top: 2px solid var(--theme-color-2f353d);
  height: 136px;
  width: 998px;

  margin-bottom: 32px;
  margin-top: 12px;
  .footer-info {
    margin: 32px 0 0 0;

    .check-for-approve {
      margin-bottom: 16px;

      label {
        color: var(--theme-color-dcdfe5);
        font-size: 14px;
      }

      input {
        font-size: 14px;
      }
    }

    .action-wrapper {
      display: flex;
      justify-content: space-between;

      .continue-button {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        align-items: center;
        text-align: center;
        letter-spacing: -0.01em;
        color: var(--theme-color-ffffff);
      }

      .continue-button.disabled {
        background-color: transparent;
        color: var(--theme-color-48576a);
        font-family: 'Inter';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        align-items: center;
        text-align: center;
        letter-spacing: -0.01em;
      }

      .skip-button {
        background-color: transparent;
        color: var(--theme-color-96a0b0);
        font-family: 'Inter';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        align-items: center;
        text-align: right;
        letter-spacing: -0.01em;
        border-radius: 3px;

        &:hover {
          background-color: var(--theme-color-1b232b);
        }
      }
    }
  }
}
