.test-run-testcase-ss-container {
  display: flex;
  flex-direction: column;
  background-color: var(--theme-color-0e1216);
  width: 100%;
  height: calc(100vh - 200px);

  .header {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    width: 100%;
  }

  .content {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 100%;

    .content-left {
      min-width: 440px;
      max-width: 600px;
      width: 440px;
      height: 100%;
      padding-right: 32px;
    }

    .content-right {
      width: calc(100% - 440px);
      height: 100%;

      .line {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin: 0px 0px;
      }
    }
  }
}
