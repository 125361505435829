.project-repo-selection-page-container {
  background-color: var(--theme-color-181b1f);
  display: block;
  overflow: auto;
  height: 100vh;

  .page-footer {
    height: 66px;
  }

  .page-body-container {
    width: 960px;
    margin: auto;
    height: calc(100% - 100px);
    display: flex;
    align-items: center;

    .pos-block {
      display: block;
    }

    .page-body {
      display: flex;
      justify-content: center;

      .body-part {
        width: 440px;

        &.right {
          margin: 0 0 0 40px;

          .top-container {
            background-color: var(--theme-color-0e1216);
            padding: 16px 20px;
            border-radius: 16px 16px 0 0;
            border-bottom: 2px solid var(--theme-color-21262d);
          }

          .bottom-container {
            background-color: var(--theme-color-0e1216);
            padding: 16px 20px;
            border-radius: 0 0 16px 16px;
            height: 264px;
            overflow: auto;
          }
        }

        &.left {
          margin: 0 40px 0 0;
          padding-top: 16px;
        }

        .first-project-input {
          width: 100%;
          height: 36px;
          border-radius: 3px;
          margin-bottom: 4px;

          input {
            background-color: var(--theme-color-151a20);
            font-size: 14px;
            color: var(--theme-color-dcdfe5);
            border: 2px solid var(--theme-color-2f353d);

            &::placeholder {
              color: var(--theme-color-3e4d61);
              font-size: 14px;
            }

            &:focus {
              border: 2px solid var(--theme-color-1d43dd);
            }

            &:hover {
              background: var(--theme-color-21262d);
            }
          }
        }

        .error-input-message {
          color: var(--theme-color-ff5630);

          i {
            margin-right: 2px;
          }
        }

        .first-project-input-label {
          color: var(--theme-color-8792a3);
          font-weight: 600;
          font-size: 12px;
          line-height: 16px;
          margin-bottom: 4px;
        }

        .title {
          font-size: 24px;
          font-weight: 600;
          line-height: 32px;
          text-align: left;
          color: var(--theme-color-f4f5f7);
          margin-bottom: 8px;
        }

        .desc {
          font-size: 12px;
          font-weight: 500;
          line-height: 16px;
          text-align: left;
          color: var(--theme-color-b2baca);
          margin-bottom: 16px;

          a {
            color: var(--theme-color-3385ff);
          }
        }
      }
    }
  }

  .section-divider {
    border: 2px solid var(--theme-color-2f353d);
    margin: 24px 0;
  }

  .footer-actions {
    text-align: end;

    .cancel-button {
      background: var(--theme-color-1b232b);
      color: var(--theme-color-b2baca);
      border-radius: 3px;

      &:hover {
        background: var(--theme-color-222b35);
      }
    }

    .submit-button {
      margin-left: 8px;
      background: var(--theme-color-1d43dd);
      color: var(--theme-color-ffffff);
      border-radius: 3px;

      &:hover {
        background: var(--theme-color-4367fd);
      }
    }
  }
}

@media (max-width: 480px) {
  .project-repo-selection-page-container {
    .page-body-container {
      width: 95%;
      margin: auto;
      height: calc(100% - 100px);
      display: flex;
      align-items: flex-start;

      .page-body {
        display: flex;
        justify-content: center;
        flex-direction: column;

        .body-part {
          width: 95%;

          &.right {
            margin: 20px 0 5px 0;
          }

          &.left {
            margin: 0 40px 0 0;
            padding-top: 16px;
          }
        }
      }
      .section-divider {
        border: none;
        margin: 0;
      }
      .footer-actions {
        text-align: right;
      }
    }
  }
}
