.page-header {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: flex-start;
  padding: 24px 0px 32px 0px;

  .page-header-title-container {
    margin: 4px 0px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }

  .page-header-title-container-section {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .page-header-backbutton-title-container {
    display: flex;
    align-items: center;
  }

  .page-header-back-button {
    font-size: 24px;
    margin: 0px 24px 0px -48px;
    &:hover {
      cursor: pointer;
    }
  }

  .page-header-title {
    white-space: nowrap;
    max-width: 700px;
    overflow: hidden;
    text-overflow: ellipsis;
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
    letter-spacing: -0.02em;
    color: var(--theme-color-dcdfe5);
  }

  .page-header-text-container {
    margin: 12px 0px 0px 0px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
  }

  .page-header-text {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
  }
}

@media (max-width: 480px) {
  .page-header {
    .page-header-title-container {
      margin: 4px 0px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      gap: 10px;
    }
  }
}
