.change-impact-analysis-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px 32px;
  justify-content: center;
  width: 428px;
  height: 124px;
  background: #1b232b;
  border-radius: 6px;
  flex: none;
  order: 0;
  flex-grow: 1;

  margin-right: 24px;

  &.extended {
    height: 200px;
  }

  .first-row {
    display: flex;
    width: 100%;

    .title {
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 20px;
      display: flex;
      align-items: center;
      letter-spacing: -0.01em;
      color: #dcdfe5;
    }
  }

  .second-row {
    display: flex;
    width: 100%;
    justify-content: space-between;

    .summary {
      .no-tested-lines {
        font-style: normal;
        font-weight: 600;
        font-size: 32px;
        line-height: 40px;
        letter-spacing: -0.02em;
        color: #dcdfe5;
      }

      .changed-lines {
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: -0.01em;
        color: #6a7689;
      }
    }

    .empty-info-container {
      .empty-info {
        margin-top: 6px;
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 16px;
        letter-spacing: -0.005em;
        color: #788496;
      }

      .integrate-now-btn {
        margin: 10px 0px;
      }
    }

    .svg-img-container {
      display: flex;
      align-items: center;
      justify-content: center;
      margin: auto 35px;
    }

    .doc-info-container {
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 16px;
      letter-spacing: -0.005em;
      color: #6a7689;
      a {
        margin-left: 3px;
        color: #1e90ff;

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }

  .third-row {
    display: flex;
    width: 100%;

    .info {
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 16px;
      /* identical to box height, or 133% */

      letter-spacing: -0.005em;

      /* Gray/G200 */

      color: #6a7689;
    }
  }
}

@media (max-width: 480px) {
  .change-impact-analysis-container {
    display: block;
    padding: 20px 32px;
    width: auto;
    height: 124px;
    background: #1b232b;
    border-radius: 6px;
    margin-right: 0;

    &.extended {
      height: 260px;
    }
  }
}
