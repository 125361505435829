.overall-count-of-stats-container {
  display: flex;
  width: 1200px;
  height: 172px;
  margin-bottom: 24px;
  background: #1b232b;
  border-radius: 6px;
  flex-direction: column;

  .header {
    display: flex;
    padding: 6px 0px 6px 32px;
    margin-top: 12px;

    .title {
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 20px;
      letter-spacing: -0.01em;
      color: #dcdfe5;
    }
  }

  .cards-container {
    display: flex;

    .highlights-count-of-stats-card {
      margin: 8px 0px 24px 0px;
      padding: 8px 32px 8px 32px;
      width: 400px;
      display: flex;
      border-right: 1px solid rgba(120, 132, 150, 0.24);

      &:last-child {
        border-right: 1px solid transparent;
      }

      .info-part {
        width: calc(100% - 120px);
        display: flex;
        flex-direction: column;
        justify-content: center;

        .sub-title {
          margin-bottom: 4px;
          font-style: normal;
          font-weight: 700;
          font-size: 14px;
          line-height: 20px;
          /* identical to box height, or 143% */

          display: flex;
          align-items: center;
          text-align: center;
          letter-spacing: -0.01em;

          /* Gray/G80 */

          color: var(--theme-color-96a0b0);
        }

        .sub-value {
          font-style: normal;
          font-weight: 500;
          font-size: 40px;
          line-height: 48px;
          /* identical to box height, or 120% */

          display: flex;
          align-items: center;
          text-align: center;
          letter-spacing: -0.01em;

          /* Light/L50 */

          color: #dcdfe5;
        }
      }

      .chart-part {
        width: 120px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
}

.info-status-code {
  display: flex;
  flex-direction: row;
  width: 400px;
  flex-wrap: wrap;
  align-items: center;
  color: var(--theme-color-ffffff);
  font-size: 12px;
  code {
    color: #020101;
    box-shadow: 1px 0 0 2px #faeef0, -1px 0 0 2px #faeef0;
    background-color: #f7f7f7;
    margin-right: 10px;
    margin-top: 5px;
    margin-bottom: 5px;
    border-radius: 3px;
    line-height: 15px;
    font-size: 10px;
    font-family: monospace;
  }
}

@media (max-width: 480px) {
  .overall-count-of-stats-container {
    width: 100%;
    height: 100%;
    .cards-container {
      flex-direction: column;
      margin: 0 -10px;
      .highlights-count-of-stats-card {
        width: 100%;
        border-right: none;

        &:last-child {
          border-right: none;
        }
      }
    }
  }
}
